<template>
  <vue-bottom-sheet
    ref="el"
    max-width="100%"
    is-full-screen
    :effect="effect"
    :click-to-close="swipeable"
    :swipeable="swipeable"
    :background-scrollable="false"
    @opened="handleOpened"
    @closed="handleClosed"
  >
    <div
      class="ui-bottom-sheet"
      :class="{
        'ui-bottom-sheet--no-padding': noPadding,
        'ui-bottom-sheet--no-overflow': noOverflow,
        'ui-bottom-sheet--auto-overflow': autoOverflow,
      }"
      :id="bottomSheetId"
    >
      <div class="ui-bottom-sheet__body">
        <slot
          v-bind="{
            open,
            close,
          }"
        />
      </div>
    </div>
  </vue-bottom-sheet>
</template>

<script setup>
import {
  ref,
  watch,
  toRefs,
  onUnmounted,
} from 'vue';
import { isEmpty, uniqueId } from 'lodash';
import { VueBottomSheet } from '@webzlodimir/vue-bottom-sheet';
import '@webzlodimir/vue-bottom-sheet/dist/style.css';

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  title: String,
  classList: Object,
  swipeable: {
    type: Boolean,
    default: true,
  },
  mode: {
    type: String,
    default: 'stackable',
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  noOverflow: {
    type: Boolean,
    default: false,
  },
  autoOverflow: {
    type: Boolean,
    default: false,
  },
  effect: {
    type: String,
    default: 'fx-fadein-scale',
  },
});

const emit = defineEmits(['input', 'opened', 'closed']);
const el = ref();
const { value, title, classList } = toRefs(props);

const bottomSheetId = uniqueId('vbs-bottom-sheet-');

const findElementByClassName = (elementClass) => {
  const elements = document.querySelectorAll(elementClass);

  return Array.from(elements).find((element) => {
    const neededElement = element.querySelector(`#${bottomSheetId}`);

    if (neededElement) {
      return neededElement;
    }

    return null;
  });
};

const resetBottomSheetClasses = (bottomSheet) => {
  const defaultClasses = 'bottom-sheet__card fx-fadein-scale';
  bottomSheet.classList.value = defaultClasses;
};

const setClassList = () => {
  const bottomSheet = findElementByClassName('.bottom-sheet__card');

  if (!isEmpty(classList.value) && bottomSheet) {
    resetBottomSheetClasses(bottomSheet);

    const addClasses = Object
      .entries(classList.value)
      .map(([key, val]) => {
        if (val) {
          return key;
        }
        return null;
      })
      .filter((val) => val);

    bottomSheet.classList.add(...addClasses);
  }
};

const setTitle = () => {
  if (!title.value) {
    return;
  }

  const bottomSheetPan = findElementByClassName('.bottom-sheet').querySelector('.bottom-sheet__pan');

  if (!bottomSheetPan) {
    return;
  }

  const customEl = document.createElement('div');
  customEl.classList.add('bottom-sheet__title');
  customEl.innerHTML = title.value;
  bottomSheetPan.appendChild(customEl);
};

const resetTitle = () => {
  if (!title.value) {
    const text = findElementByClassName('.bottom-sheet__title');
    if (text) {
      text.remove();
    }
  }
};

const open = () => {
  if (el.value) {
    el.value.open();
  }
};

const close = () => {
  if (el.value) {
    el.value.close();
  }
};

watch(classList, setClassList, { immediate: true, deep: true });

watch(title, (val) => {
  if (!val) {
    resetTitle();
  }
}, { immediate: true });

watch(value, (val) => {
  if (val) {
    open();
    setTitle();
  } else {
    close();
  }
});

const handleOpened = () => {
  emit('opened');
};

const handleClosed = () => {
  emit('input', false);
  emit('closed');
};

onUnmounted(() => {
  emit('input', false);
  emit('closed');
  close();
});
</script>

<style lang="scss">
.ui-bottom-sheet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  &--no-padding &__body {
    padding: 0;
  }

  &--no-overflow {
    overflow: visible;
  }

  &--auto-overflow {
    overflow: auto;
  }

  &__body {
    max-width: 430px;
    max-height: 83vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
  }
}
</style>
