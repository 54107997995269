<template>
  <div class="multiple-actions">
    <ui-button class="multiple-actions__btn multiple-actions__btn_cancel" ghost @click="$emit('cancel')" transparent>
      {{ $t('search.cancel') }}
    </ui-button>
    <ui-button class="multiple-actions__btn multiple-actions__btn_continue" @click="$emit('continue')">
      {{ $t('search.continue') }}
    </ui-button>
  </div>
</template>

<script>
import UiButton from '@/components/UiButton.vue';

export default {
  name: 'MultipleActions',
  components: {
    UiButton,
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

.multiple-actions {
  width: 100%;
  max-width: $max-width + 32px;
  box-sizing: border-box;
  padding: 12px 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > .multiple-actions__btn + .multiple-actions__btn {
    margin-left: 16px;
  }

  &__btn {
    height: 48px;

    &_cancel {
      font-size: 16px;
      flex: 1;
      margin: 0 2px;
    }
  }
}
</style>
