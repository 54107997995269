<template>
  <div class="modal-fee">
    <ui-icon-button
      name="question"
      size="8px"
      class="modal-fee__activator"
      aria-label="Platform fee info button"
      :disabled="disabled"
      @click="handleOpen"
    />

    <ui-bottom-sheet
      v-model="showBottomSheet"
      #default="{ close }"
    >
      <div class="modal-fee__body">
        <div class="modal-fee__title">
          {{ $t('modal.fee.title') }}
        </div>

        <div class="modal-fee__description">
          <p class="modal-fee__paragraph">{{ $t('modal.fee.text[0]') }}</p>
          <p class="modal-fee__paragraph">{{ $t('modal.fee.text[1]') }}</p>
          <p class="modal-fee__paragraph">{{ $t('modal.fee.text[2]') }}</p>
        </div>

        <ui-button
          class="modal-fee__button"
          @click="close"
        >
          {{ $t('modal.fee.button') }}
        </ui-button>
      </div>
    </ui-bottom-sheet>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UiBottomSheet from './UiBottomSheet.vue';
import UiIconButton from './UiIconButton.vue';
import UiButton from './UiButton.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['opened']);

const showBottomSheet = ref(false);

const handleOpen = () => {
  emit('opened');
  showBottomSheet.value = true;
};
</script>

<style lang="scss">
.modal-fee {
  &__activator {
    width: 14px;
    height: 14px;
    background: #c4c4c4;
    border-radius: 50%;
    color: #FFFFFF;
    margin-left: 4px;
    opacity: 0.8;
  }

  &__title {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  &__description {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
    margin-bottom: 30px;
  }

  &__paragraph {
    margin-bottom: 20px;
  }

  &__button {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
