<template>
  <div class="ui-input-money" @click="setFocus">
    <div
      v-if="showCurrencyBefore"
      class="ui-input-money__currency"
      :class="{
        'ui-input-money__currency_long': money.currencyLong,
      }"
    >
      {{ money.currencyLabel }}
    </div>
    <input
      ref="input"
      class="ui-input-money__control"
      v-model="inputValue"
      v-bind="$attrs"
      v-on="listeners"
      :class="inputClass"
      :style="style"
    />
    <div
      v-if="showCurrencyAfter"
      class="ui-input-money__currency"
      :class="{
        'ui-input-money__currency_long': money.currencyLong,
      }"
    >
      {{ money.currencyLabel }}
    </div>
  </div>
</template>

<script>
import Money from '@/class/Money';
import { mapState } from 'vuex';

export default {
  name: 'UiInputMoney',

  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    inputClass: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    inputValue: '',
  }),

  computed: {
    ...mapState('focus', [
      'moneyInputTrigger',
    ]),
    money() {
      return new Money(this.inputValue, this.currency);
    },
    listeners() {
      const {
        input,
        ...listeners
      } = this.$listeners;

      return listeners;
    },
    style() {
      if (this.showCurrencyAfter) {
        const charsSize = this.inputValue.split('').reduce((carry, char) => {
          if (char === '.') {
            return carry + 0.3;
          }

          return carry + 0.55;
        }, 0);

        return {
          width: `${charsSize + 0.2}em`,
        };
      }

      return {
        width: '100%',
      };
    },
    showCurrency() {
      return this.money.amount > 0;
    },
    showCurrencyBefore() {
      return this.showCurrency && !this.showCurrencyAfter;
    },
    showCurrencyAfter() {
      return this.showCurrency && this.money.currencyRtl;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.inputValue = value.toString();
      },
    },
    inputValue: {
      immediate: true,
      handler(inputValue) {
        inputValue = inputValue.replace(',', '.');
        inputValue = inputValue.replace(/[^\d.]/g, '');
        inputValue = inputValue.replace(/^(\d+)(\.\d{0,2})?.*/g, '$1$2');

        let inputAmount = Number(inputValue);

        if (!Number.isNaN(inputAmount)) {
          inputAmount = +inputAmount.toFixed(2);
        } else {
          inputAmount = 0;
        }

        if (inputAmount === 0) {
          inputValue = '';
        }

        this.inputValue = inputValue;

        if (this.value !== inputAmount) {
          this.$emit('input', inputAmount);
        }
      },
    },
    moneyInputTrigger() {
      this.setFocus();
    },
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
.ui-input-money {
  display: flex;
  align-items: baseline;
  width: auto !important; /* bad, bad, bad */
  overflow: hidden;

  &__currency {
    &_long {
      font-size: 0.6em;
    }
  }

  &__control {
    padding: 0;
  }
}
.home_multipay {
  .ui-input-money {
    &__currency {
      font-size: 24px;
      &_long {
        font-size: 0.4em;
      }
    }
  }
}
</style>
