<template>
  <div
    class="user-reviews"
    :key="counter"
    :class="{
      'user-reviews_icon': isGoodRate,
    }"
  >
    <div class="user-reviews__title">
      {{ title }}
    </div>
    <div class="user-reviews__body">

      <swiper
          :slides-per-view="1"
          :space-between="10"
          @slideChange="onSlideChange"
      >
        <swiper-slide
          class="user-reviews__swipe-page"
          :class="{'user-reviews__swipe-page-less': chunks.length < 4}"
          v-for="chunks in visibleReviewsChunked"
        >
          <ui-switcher-review
              class="user-reviews__review"
              v-for="item in chunks"
              :key="item.id"
              :icon="item.icon"
              :label="$tc(`review.${item.name}`, countryIso)"
              @change="changeReviewValue(item, $event)"
          />
        </swiper-slide>
      </swiper>
      <ui-swipe-pagination
        class="user-reviews__paginator"
        v-if="visibleReviewsChunked.length > 1"
        :pages="visibleReviewsChunked.length"
        :page="page"
      />
    </div>
  </div>
</template>

<script>
import reviewsConfig from '@/config/reviews';
import shuffle from '@/utils/shuffle';
import UiIconButton from './UiIconButton.vue';
import UiSwitcherReview from '@/components/UiSwitcherReview.vue';
import UiSwipePagination from '@/components/UiSwipePagination.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { kebabCase } from 'lodash';

import 'swiper/css';

export default {

  components: {
    UiIconButton,
    UiSwitcherReview,
    Swiper,
    SwiperSlide,
    UiSwipePagination,
  },

  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    rate: {
      type: Number,
      required: true,
    },
    reviews: {
      type: Array,
      required: true,
    },
    locationType: String,
    countryIso: String,
  },

  data() {
    return {
      counter: 0,
      selected: this.value,
      page: 1,
    };
  },

  computed: {
    isGoodRate() {
      return this.rate > 3;
    },
    title() {
      return this.isGoodRate
        ? this.$t('reviews.titleGood')
        : this.$t('reviews.titleBad');
    },
    visibleReviews() {
      const reviews = this.reviews
        .map((item) => {
          return {
            ...item,
            // eslint-disable-next-line global-require, import/no-dynamic-require
            icon: require(`@/assets/reviews/${this.getImage(item.name)}`),
          };
        });

      return reviews;
    },

    visibleReviewsChunked() {
      const amountPerPage = 4;

      return this.visibleReviews.reduce((acc, curr, index) => {
        const chunkIndex = Math.floor(index / amountPerPage);

        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }

        acc[chunkIndex].push(curr);

        return acc;
      }, []);
    },
  },

  watch: {
    visibleReviews() {
      this.counter += 1;
    },

    rate() {
      this.page = 1;
    },
  },

  methods: {
    getImage(name) {
      if (reviewsConfig.new.includes(name)) {
        return `${kebabCase(name)}.svg`;
      }

      return 'noimage.png';
    },
    isSelected(item) {
      return this.selected.findIndex(({ id }) => id === item.id) > -1;
    },
    toggle(item) {
      const index = this.selected.findIndex(({ id }) => id === item.id);

      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item);
      }

      this.$emit('input', this.selected);
    },
    onSlideChange(payload) {
      this.page = payload.activeIndex + 1;
    },
    transformName(name) {
      name = name.toLowerCase();

      return name[0].toUpperCase() + name.slice(1);
    },
    changeReviewValue(item, value) {
      let array = [...this.value];

      if (value === null) {
        array = array.filter((valueItem) => valueItem.id !== item.id);
      }

      if (value !== null) {
        const itemMap = {
          id: item.id,
          name: item.name,
          state: value === 1 ? 'LIKE' : 'DISLIKE',
        };

        const index = array.findIndex((arrayItem) => arrayItem.id === item.id);

        if (index !== -1) {
          array[index] = itemMap;
        } else {
          array.push(itemMap);
        }
      }

      this.$emit('input', array);
    },
  },
};
</script>

<style lang="scss">
.user-reviews {
  &__body {
    width: 100%;
    max-width: 100%;
    //overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  &__swipe-page {
    display: flex;
    justify-content: space-between;

    &-less {
      justify-content: inherit;

      & .user-reviews__review {
        margin-right: 13px;
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    padding: 5px;

    &_fit {
      justify-content: space-between;
    }
  }

  &__title {
    line-height: 1.3;
    @include block-caption;
  }

  &__review .user-reviews {
    margin-left: 13px;

    &:first-child {
      margin-left: 0;
    }
  }

  .user-review {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 5px 5px;
    margin: 2px 0;
    min-width: 70px;
    box-sizing: border-box;
    position: relative;
    color: black;

    &__icon {
      width: 50px;
      margin: 15px 0 13px;
    }

    &__title {
      text-align: center;
      font-size: 14px;
      line-height: 1.15;
      padding-bottom: 5px;
    }

    & + & {
      margin-left: 6px;
    }
  }

  &_icon &__inner {
    width: 100%;
    //animation-duration: 1s;
    //animation-timing-function: ease-out;
    //animation-iteration-count: 1;
    //animation-direction: normal;
    //animation-name: beautify-icon;
  }
  &_icon .user-review__title {
    border-bottom: 2px solid transparent;
  }
  &_icon .user-review_active .user-review__title {
    border-color: #d70a12;
  }

  &_text &__body {
    max-height: 150px;
  }
  &_text &__inner {
    width: 100%;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-name: beautify-text;
  }
  &_text .user-review {
    align-items: start;
    padding-left: 0;
    padding-right: 30px;
    width: 100%;
  }
  &_text .user-review__title {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }
  &_text .user-review_active .user-review__title {
    font-weight: 500;
  }
  &_text .user-review_active:after {
    content: '✓';
    position: absolute;
    right: 15px;
  }

  &__paginator {
    margin: 0 auto;
  }
}

@keyframes beautify-icon {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes beautify-text {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.fr .user-reviews {
  &_text .user-reviews__body {
    max-height: 142px;
  }

  &_text .user-review__title {
    font-size: 13px;
  }
}
</style>
