import Vue from 'vue';
import * as Sentry from '@sentry/vue';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      /^Redirected when going from.*/,
      /Network error/i,
    ],
    tracePropagationTargets: ['localhost', /^\//],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
      'https://pay.google.com',
      'https://play.google.com',
    ],
  });
}
