import {
  onMounted,
  onUnmounted,
  onUpdated,
  watch,
} from 'vue';

/**
 * Copy styles betweeb HTMLElements.
 *
 * @param {HTMLElement} from
 * @param {HTMLElement} to
 */
function copyStyles(from, to) {
  const styles = window.getComputedStyle(from);

  Object.assign(to.style, {
    position: 'absolute',
    top: '0',
    left: '0',
    visibility: 'hidden',
    height: '0',
    overflow: 'hidden',
    whiteSpace: 'pre',
    fontSize: styles.fontSize,
    fontFamily: styles.fontFamily,
    fontWeight: styles.fontWeight,
    fontStyle: styles.fontStyle,
    letterSpacing: styles.letterSpacing,
    textTransform: styles.textTransform,
    paddingRight: styles.paddingRight,
    paddingLeft: styles.paddingLeft,
  });
}

// @see {@url https://github.com/syropian/vue-input-autowidth/blob/v2/lib/directive.ts}
export const useAutoWidth = (el, state) => {
  let elWidth = '';
  let mirrorEl = null;

  /**
   * Calculate and update width.
   */
  function calculate() {
    if (!el.value) {
      return;
    }

    if (!mirrorEl) {
      return createMirror();
    }

    const { value, placeholder } = el.value;
    const content = value || placeholder || '';

    while (mirrorEl.childNodes.length) {
      mirrorEl.removeChild(mirrorEl.childNodes[0]);
    }

    mirrorEl.appendChild(document.createTextNode(content));

    const newWidth = mirrorEl.scrollWidth + 2;

    if (newWidth !== el.value.scrollWidth) {
      el.value.style.width = `${newWidth}px`;
    }
  }

  /**
   * Create mirror HTMLElement and calculate immediately.
   */
  function createMirror() {
    if (!el.value || mirrorEl) {
      return;
    }

    elWidth = el.value.style.width;
    mirrorEl = document.createElement('div');
    copyStyles(el.value, mirrorEl);
    mirrorEl.setAttribute('aria-hidden', 'true');
    document.body.appendChild(mirrorEl);

    copyStyles(el.value, mirrorEl);
    calculate();

    el.value.addEventListener('input', calculate);
  }

  /**
   * Remove mirror HTMLElement and listeners.
   */
  function removeMirror() {
    if (el.value && mirrorEl) {
      document.body.removeChild(mirrorEl);
      el.value.style.width = elWidth;
      el.value.removeEventListener('input', calculate);
      mirrorEl = null;
    }
  }

  // Create mirror HTMLElement and add listeners if enabled.
  onMounted(() => {
    if (state.value) {
      createMirror();
    }
  });

  // Calculate auto width if enabled.
  onUpdated(() => {
    if (mirrorEl) {
      calculate();
    }
  });

  // Remove elements and listeners after destroy component.
  onUnmounted(() => {
    removeMirror();
  });

  // Trigger on update state.
  watch(state, (value) => {
    if (value) {
      createMirror();
    } else {
      removeMirror();
    }
  });

  return { };
};
