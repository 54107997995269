<template>
  <div class="ui-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiCard',
};
</script>

<style lang="scss">
.ui-card {
  background: #fff;
  box-shadow: 0px 0px 20px rgba(143, 122, 122, 0.08);
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  &:empty {
    display: none;
  }
}
</style>
