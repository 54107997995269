<template>
  <main-layout
    :company-logo="showCompanyLogo"
    :partner-id="partnerId"
    :payoutId="locationPayoutId"
    no-social-icons
  >
  <div
    v-if="!userLoading"
    class="home"
    :class="{
      'home_prestep': isPrestep,
      'home_multipay': isMultiPayPage,
    }"
  >
    <template v-if="!status">
      <div class="home__body">
        <div v-if="isQrBillPayment" class="home__bill">
          <div class="home__bill-location-name">
            {{ locationName }}
          </div>

          <flow-layout
            :step-info="billFlowSteps"
            :current-step="billCurrentStepOrder"
          >
            <template v-if="billCurrentStepInfo.key === billSteps.requestCustomerInfo.key">
              <bill-customer-info-form
                @completed="handleBillCustomerInfo"
              />
            </template>

            <template v-if="billCurrentStepInfo.key === billSteps.billPayment.key">
              <bill-info
                class="home__bill-info"
                :data="billInfo"
                :currency="currency"
              />

              <bill-staff-list
                v-if="!this.isCommon"
                class="home__bill-staff-list"
                :data="qrBillData"
                :staff-list="location.staffs"
                @form-amount="updateQrBillAmount"
                @select="selectTipFromSelector"
                @fee-amount="form.feeAmountSum = $event"
                @fee-checked="(value) => onFeeCheckedChangedHandler(value)"
                @fee-opened="onFeeOpened"
                @fee-loading="onFeeLoadingChanged"
                @typing="onTypingChanged"
                @error="onAmountError"
              />

              <ui-card
                v-if="isCommon"
                class="home__bill-tips"
              >
                <div class="home__bill-tips-title">
                  <template v-if="selectedTip.id">
                    I’d like to leave a tip
                  </template>
                  <template v-else>
                    Would you like to leave a tip?
                  </template>
                </div>

                <ui-tips
                  v-if="isPayoutKeyLoaded"
                  class="home__tips"
                  :ab-variant="abVariant"
                  :tips="tips"
                  :currency="currency"
                  :selected-tip-id="selectedTip.id"
                  @select="selectTipFromSelector"
                />
                <user-amount
                  :value="form.amount[localPayoutId[0]]"
                  :feeChecked.sync="form.feeChecked"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :min-tips-amount="minTipsAmount"
                  hide-fees
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="onFeeCheckedChanged"
                  @update:error="onAmountError"
                  @update:value="(amount) => updateAmount(amount, localPayoutId[0])"
                  @update:typing="onTypingChanged"
                />
                <user-amount
                  v-if="form.amount[localPayoutId[0]]"
                  :value="form.amountSum"
                  :feeChecked="form.feeChecked"
                  :feeAmount.sync="form.feeAmountSum"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :api-version="apiVersion"
                  :min-tips-amount="minTipsAmount"
                  hide-input
                  class="home__bill-tips-fee"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="(value) => onFeeCheckedChangedHandler(value)"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />
              </ui-card>

              <ui-card class="home__bill-feedback-payment">
                <div
                  v-if="showBillFeedbackBlock"
                  class="home__bill-feedback"
                >
                  <user-rates
                    v-model="form.rate"
                    class="home__user-rates"
                    @input="onRateChanged"
                  />
                  <user-reviews
                    v-if="form.rate"
                    v-model="form.reviews"
                    :rate="form.rate"
                    :reviews="location.reviews"
                    :location-type="location.type"
                    :country-iso="countryIso"
                    class="home__user-reviews"
                    @input="onReviewsChanged"
                  />
                  <ui-input
                    v-if="showFeedbackMessage"
                    tag="textarea"
                    v-model="form.comment"
                    :placeholder="$t('form.commentPlaceholder')"
                    class="home__user-comment"
                    maxlength="250"
                    rows="1"
                    clearable
                    @input="handleChange"
                  />
                </div>

                <div class="home__bill-total-price">
                  {{ $t('form.total') }}: <ui-money :amount="billAmount" :currency="currency" />
                </div>

                <div class="home__user-buttons">
                  <payment-buttons
                    :data="paymentButtonsData"
                    no-offset
                    @submit="handleSubmit"
                  />
                </div>
              </ui-card>

              <UserAgreeCheckbox
                v-if="showSubmit"
                v-model="form.agree"
                :payout-id="localPayoutId"
                :is-staff="isStaff"
                @input="onAgreeChanged"
              />
            </template>
          </flow-layout>
        </div>

        <template v-else>
          <!-- Prestep sub location selection -->
          <template v-if="isMasterLocation">
            <div
              class="home__search-title"
              v-html="groupTitle"
            />

            <router-link
              v-for="item in filteredLocations"
              :key="item.id"
              :to="item.link"
              class="home__search-sub-locations"
              @click.native="onSubLocationOpened(item)"
            >
              <sub-location
                :name="item.name"
                :location-type="item.subLocationType"
              />
            </router-link>
          </template>

          <!-- Prestep group selection -->
          <template v-else-if="isGroupList">
            <template v-if="$can('subscription:tips-groups-view')">
              <div
                class="home__search-title"
                v-html="groupTitle"
              />

              <router-link
                v-for="item in filteredGroups"
                :key="item.id"
                :to="item.link"
                class="home__search-groups"
                @click.native="onGroupOpened(item)"
              >
                <location-group
                  :group="item"
                  :location-type="location.type"
                />
              </router-link>
            </template>

            <template v-else>
              <div
                class="home__search-title"
                v-html="$t('search.staffsTitle')"
              />

              <ui-search
                v-model="filter"
                :placeholder="$t('search.inputPlaceholder')"
                class="home__search-filter"
              />

              <router-link
                v-for="item in filteredStaffs"
                :key="item.payoutId"
                :to="item.link"
                class="home__search-staff"
                @click.native="onStaffOpened(item)"
              >
                <user-staff
                  :key="item.payoutId"
                  :staff="item"
                />
              </router-link>
            </template>
          </template>

          <!-- Prestep staff selection -->
          <template v-else-if="isStaffList && !isCommon">
            <div
              class="home__search-title"
              v-html="$t('search.staffsTitle')"
            />

            <ui-switcher
              v-if="showMultiTipsSwitcher"
              v-model="selectMultipleSpecialists"
              :label="$t('search.multipleSpecialistLabel')"
              class="home__multiple-switcher"
            />

            <ui-search
              v-model="filter"
              :placeholder="$t('search.inputPlaceholder')"
              class="home__search-filter"
            />

            <template v-if="selectMultipleSpecialists">
              <user-staff
                v-for="item in filteredStaffs"
                :key="item.payoutId"
                :selected="isUserSelectedById(item.payoutId)"
                class="home__search-staff"
                :staff="item"
                selectable
                @click.native="selectSpecialist({ staff: item })"
              />
            </template>
            <template v-else>
              <router-link
                v-for="item in filteredStaffs"
                :key="item.payoutId"
                :to="item.link"
                class="home__search-staff"
                @click.native="onStaffOpened(item)"
              >
                <user-staff
                  :staff="item"
                />
              </router-link>
            </template>
          </template>

          <!-- Staff or location tips form -->
          <template v-else>
            <template v-if="isMultiPayPage">
              <h1 v-if="staffNames" class="home__user-name">
                {{ staffNames }}
              </h1>

              <p v-if="location.name" class="home__user-location">
                {{ location.name }}
              </p>

              <ui-card>
                <div class="home__tip-selector-card__title">{{ $t('search.chooseOrTipManually') }}</div>

                <ui-tips
                  :ab-variant="abVariant"
                  :tips="tips"
                  :currency="currency"
                  :selected-tip-id="selectedTip.id"
                  @select="selectTipFromSelector"
                />

                <user-staff
                  v-for="staffId in staffPayoutId" :key="staffId"
                  class="user-staff_inner user-staff_list"
                  :staff="getStaffById(staffId)"
                  multipay
                >
                  <!--  Multipay   -->
                  <user-amount
                    :value="form.amount[staffId]"
                    :feeChecked.sync="form.feeChecked"
                    :currency="currency"
                    :payout-id="locationPayoutId"
                    hide-fees
                    :api-version="apiVersion"
                    :min-tips-amount="minTipsAmount"
                    show-error-anyway
                    class="home__user-amount"
                    @fee-opened="onFeeOpened"
                    @update:feeChecked="onFeeCheckedChanged"
                    @update:error="onAmountError"
                    @update:value="(amount) => updateAmount(amount, staffId)"
                    @update:typing="onTypingChanged"
                  />
                </user-staff>
              </ui-card>
              <ui-card>
                <template v-if="showFeedbackBlock">
                  <user-rates
                    v-model="form.rate"
                    class="home__user-rates"
                    @input="onRateChanged"
                  />
                  <user-reviews
                    v-if="form.rate"
                    v-model="form.reviews"
                    :rate="form.rate"
                    :reviews="location.reviews"
                    :location-type="location.type"
                    :country-iso="countryIso"
                    class="home__user-reviews"
                    @input="onReviewsChanged"
                  />
                  <ui-input
                    v-if="showFeedbackMessage"
                    tag="textarea"
                    v-model="form.comment"
                    :placeholder="$t('form.commentPlaceholder')"
                    class="home__user-comment"
                    maxlength="250"
                    rows="1"
                    small
                    @input="handleChange"
                  />
                </template>

                <user-amount
                  :value="form.amountSum"
                  :feeChecked="form.feeChecked"
                  :feeAmount.sync="form.feeAmountSum"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  hide-input
                  :api-version="apiVersion"
                  :min-tips-amount="minTipsAmount"
                  :class="{ 'home__user-amount': showAmountFees }"
                  fee-mt
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="(value) => onFeeCheckedChangedHandler(value)"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />
                <div v-if="showAmountFees" class="home__total-price">
                  {{ $t('form.total') }}: <ui-money :amount="totalSum" :currency="currency" />
                </div>

                <div v-if="showFeedbackButtons" class="home__user-buttons">
                  <ui-button
                    :is-loading="feedbackLoading"
                    :disabled="isDisabled || feedbackSent"
                    @click.prevent="handleSubmit('submitBeforeFeedback')"
                  >
                    {{ $t('form.button.onlyFeedback') }}
                  </ui-button>
                </div>
                <div v-show="showPaymentButtons" class="home__user-buttons">
                  <payment-buttons
                    v-if="!hidePaymentButtons"
                    :data="paymentButtonsData"
                    no-offset
                    @submit="handleSubmit"
                  />
                </div>
              </ui-card>
            </template>
            <template v-else>
              <location-card
                class="home__location_card"
                v-if="isCommon"
                :payoutId="locationPayoutId"
                :name="locationName"
              />

              <staff-card
                class="home__staff_card"
                v-else-if="isStaff"
                :nick-name="nickName"
                :wish="wish"
                :staff="staff"
              />

              <h1 v-if="bill" class="home__total-amount">
                {{ isRestaurant
                  ? $t('form.billLabel')
                  : $t('form.amountLabel')
                }}: <ui-money :amount="bill" :currency="currency" />
              </h1>

              <ui-card>
                <!--     Solopay       -->

                <ui-tips
                  v-if="isPayoutKeyLoaded"
                  class="home__tips"
                  :ab-variant="abVariant"
                  :tips="tips"
                  :currency="currency"
                  :selected-tip-id="selectedTip.id"
                  @select="selectTipFromSelector"
                />
                <user-amount
                  :value="form.amount[localPayoutId[0]]"
                  :feeChecked.sync="form.feeChecked"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :min-tips-amount="minTipsAmount"
                  hide-fees
                  class="home__user-amount"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="onFeeCheckedChanged"
                  @update:error="onAmountError"
                  @update:value="(amount) => updateAmount(amount, localPayoutId[0])"
                  @update:typing="onTypingChanged"
                />

                <user-amount
                  :value="form.amountSum"
                  :feeChecked="form.feeChecked"
                  :feeAmount.sync="form.feeAmountSum"
                  :currency="currency"
                  :payout-id="locationPayoutId"
                  :api-version="apiVersion"
                  :min-tips-amount="minTipsAmount"
                  hide-input
                  class="home__user-amount-fees"
                  @fee-opened="onFeeOpened"
                  @update:feeChecked="(value) => onFeeCheckedChangedHandler(value)"
                  @update:error="onAmountError"
                  @update:feeLoading="onFeeLoadingChanged"
                />

                <template v-if="showFeedbackBlock">
                  <user-rates
                    v-model="form.rate"
                    class="home__user-rates"
                    @input="onRateChanged"
                  />
                  <user-reviews
                    v-if="form.rate"
                    v-model="form.reviews"
                    :rate="form.rate"
                    :reviews="location.reviews"
                    :location-type="location.type"
                    :country-iso="countryIso"
                    class="home__user-reviews"
                    @input="onReviewsChanged"
                  />
                  <ui-input
                    v-if="showFeedbackMessage"
                    tag="textarea"
                    v-model="form.comment"
                    :placeholder="$t('form.commentPlaceholder')"
                    class="home__user-comment"
                    maxlength="250"
                    rows="1"
                    clearable
                    @input="handleChange"
                  />
                </template>

                <div v-if="showFeedbackButtons" class="home__user-buttons">
                  <ui-button
                    :is-loading="feedbackLoading"
                    :disabled="isDisabled || feedbackSent"
                    @click.prevent="handleSubmit('submitBeforeFeedback')"
                  >
                    {{ $t('form.button.onlyFeedback') }}
                  </ui-button>
                </div>
                <div v-show="showPaymentButtons" class="home__user-buttons">
                  <payment-buttons
                    v-if="!hidePaymentButtons"
                    :data="paymentButtonsData"
                    no-offset
                    @submit="handleSubmit"
                  />
                </div>
              </ui-card>
            </template>

            <UserAgreeCheckbox
              v-if="showSubmit"
              v-model="form.agree"
              :payout-id="localPayoutId"
              :is-staff="isStaff"
              @input="onAgreeChanged"
            />
          </template>
        </template>
      </div>
      <AbsoluteFooter v-if="showMultipleSpecialistsActions">
        <MultipleActions
          @cancel="clearSelectedSpecialists"
          @continue="continueWithSelectedSpecialists"
        />
      </AbsoluteFooter>
    </template>

    <template v-if="!isQrBillPayment">
      <modal-submit-pay
        v-model="showSubmitPayModal"
        :total="submitMoney"
        @submit="submitPayInModal"
      />

      <modal-tips
        v-model="modalTips"
        :payout-id="locationPayoutId"
        :staff="staff"
        :tips="tips"
        :rate="form.rate"
        :currency="currency"
        :ab-variant="abVariant"
        :multi-fee-amount="form.feeAmountSum"
        :staff-payout-id="staffPayoutId"
        :min-tips-amount="minTipsAmount"
        :payment-buttons-data="paymentButtonsData"
        :is-common="isCommon"
        :is-individual="isStaff"
        :is-multi="isMultiPayPage"
        @fee-amount="form.feeAmountSum = $event"
        @fee-checked="form.feeChecked = $event"
        @fee-opened="onFeeOpened"
        @fee-value="(amount) => updateAmount(amount, localPayoutId[0])"
        @fee-loading="onFeeLoadingChanged"
        @amount-error="onAmountError"
        @multi-fee-checked="(value) => onFeeCheckedChangedHandler(value)"
        @single-fee-checked="onFeeCheckedChangedHandler"
        @select="handleModalTipsSelect"
        @submit="handleSubmit"
        @skip="handleModalTipsFeedback"
        @closed="hidePaymentButtons = false"
        @opened="hidePaymentButtons = true"
      />
    </template>

    <modal-status
      v-if="modalStatus"
      :status="modalStatus"
      layout
      :rate="form.rate"
      :card-pay="cardPay"
      :feedback-sent="feedbackSent"
      :is-loading="receiptLoading"
      :feedback-links="feedbackLinks"
      :data="modalStatusData"
      :customer-email="customerEmail"
      @closed="submitStatus"
      @toggle-receipt="toggleReceipt"
      @send-receipt="sendReceipt"
      @clicked-link="clickedLink"
    />

    <modal-sorry
      v-if="modalSorry"
      v-model="form.phoneNumber"
      :is-loading="sorryLoading"
      @receipt="receipted = true"
      @closed="submitSorry"
      @clicked-link="clickedLink"
    />

    <modal-receipt
      v-if="modalReceipt"
      @closed="submitStatus"
      @clicked-link="clickedLink"
    ></modal-receipt>

    <modal-max-tip-amount
      v-model="showMaxTipAmountModal"
      :amount="location.maxTipsAmount"
      :currency="currency"
    />
  </div>
  </main-layout>
</template>

<script>
import { useField } from 'vee-validate';
import { ABILITY_TOKEN } from '@casl/vue';
import { mapState, mapGetters, mapActions, useStore } from 'vuex';
import { loadStripe } from '@stripe/stripe-js/pure';
import { merge, isEmpty } from 'lodash';
import getFio from '@/utils/getFio';
import isFirstPageVisit from '@/utils/isFirstPageVisit';
import Money from '@/class/Money';
import api from '@/api';
import storage from '@/plugins/storage';
import { useEventTrackerMixin } from '@/mixins/event-tracker-mixin';
import UiInput from '@/components/UiInput.vue';
import UiButton from '@/components/UiButton.vue';
import UiSearch from '@/components/UiSearch.vue';
import UiCard from '@/components/UiCard.vue';
import PaymentButtons from '@/components/PaymentButtons.vue';
import UserAmount from '@/components/UserAmount.vue';
import UserRates from '@/components/UserRates.vue';
import UserReviews from '@/components/UserReviews.vue';
import UserStaff from '@/components/UserStaff.vue';
import LocationGroup from '@/components/LocationGroup.vue';
import SubLocation from '@/components/SubLocation.vue';
import ModalStatus from '@/components/ModalStatus.vue';
import ModalSorry from '@/components/ModalSorry.vue';
import ModalReceipt from '@/components/ModalReceipt.vue';
import ModalTips from '@/components/ModalTips.vue';
import UiSwitcher from '@/components/UiSwitcher.vue';
import resetMixin from '@/mixins/reset-mixin';
import { useOvertippingMixin } from '@/mixins/overtipping-mixin';
import UiTips from '@/components/UiTips.vue';
import MultipleActions from '@/components/MultipleActions.vue';
import AbsoluteFooter from '@/components/AbsoluteFooter.vue';
import UserAgreeCheckbox from '@/components/UserAgreeCheckbox.vue';
import UiMoney from '@/components/UiMoney.vue';
import ModalSubmitPay from '@/components/ModalSubmitPay.vue';
import ModalMaxTipAmount from '@/components/ModalMaxTipAmount.vue';
import { checkTransactionCollision } from '@/utils/transactionCollisions';
import { useMeta } from 'vue-meta';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MainLayout from '@/layouts/MainLayout.vue';
import StaffCard from '@/components/StaffCard.vue';
import LocationCard from '@/components/LocationCard.vue';
import multiTipsModes from '@/config/multiTipsModes';
import { updateAbilities } from '@/plugins/ability';
import BillInfo from '@/components/bill/BillInfo.vue';
import BillStaffList from '@/components/bill/BillStaffList.vue';
import BillCustomerInfoForm from '@/components/bill/BillCustomerInfoForm.vue';
import FlowLayout from '@/layouts/FlowLayout.vue';
import billSteps from '@/config/billSteps';
import { PAY_LINK } from '@/config/links';

export default {
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const canonicalUrlParams = {
      name: 'home',
      params: {
        locationPayoutId: props.locationPayoutId,
      },
    };

    if (props.staffPayoutId.length === 1 /* single staff */) {
      canonicalUrlParams.query = {
        staffId: props.staffPayoutId,
      };
    }

    const canonicalUrl = window.location.origin + router.resolve(canonicalUrlParams)?.href;

    const meta = computed(() => {
      return {
        title: `Tips for ${store.getters['user/name']}`,
        meta: [
          {
            name: 'description',
            content: props.staffPayoutId.length && store.state.user.location.name
              ? `${store.getters['user/name']} works in ${store.state.user.location.name} and receives tips with EasyTip.`
              : `Tips for ${store.getters['user/name']}. Cashless tips for employees by QR code.`,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: canonicalUrl,
          },
        ],
      };
    });

    useMeta(meta);

    const min = (value) => {
      const length = 15;

      if (value !== undefined && value.length < length) {
        return t('validationErrors.min', { length });
      }

      return true;
    };

    const { handleChange } = useField('comment', min);
    const { eventTrackerNamePrefix } = useEventTrackerMixin();
    const { overtippingConfirmHandler } = useOvertippingMixin();

    return {
      overtippingConfirmHandler,
      eventTrackerNamePrefix,
      handleChange,
    };
  },

  mixins: [
    resetMixin(function initialState() {
      return {
        paymentIntentId: '',
        feedbackLoading: false,
        feedbackSent: false,
        cardLoading: false,
        payLoading: false,
        giroPayLoading: false,
        sorryLoading: false,
        receiptLoading: false,
        feeLoading: false,
        tipsFeeLoading: false,
        typing: false,
        modalStatus: null,
        modalReceipt: null,
        modalSorry: null,
        modalTips: false,
        filter: '',
        form: {
          amount: {},
          feeAmount: {},
          feeChecked: true,
          amountSum: 0,
          feeAmountSum: 0,
          rate: this.rate,
          reviews: [],
          comment: '',
          agree: true,
          phoneNumber: '',
        },
        feeRequest: null,
        stripe: null,
        stripeLoading: false,
        stripePaymentRequest: null,
        applePay: false,
        googlePay: false,
        receipted: false,
        cardPay: false,
        amountError: '',
        selectMultipleSpecialists: false,
        selectedSpecialists: [],
        selectedTip: {},
        feedbackId: null,
        showSubmitPayModal: false,
        abortedWithSubmitMethod: null,
        isPayoutKeyLoaded: false,
        showMaxTipAmountModal: false,
        hidePaymentButtons: false,
        billCurrentStepOrder: 2,
        billCustomerInfo: {},
      };
    }),
  ],

  components: {
    MainLayout,
    FlowLayout,
    ModalSubmitPay,
    UserAgreeCheckbox,
    UiTips,
    AbsoluteFooter,
    MultipleActions,
    UiSwitcher,
    UiInput,
    UiButton,
    UiSearch,
    UiCard,
    PaymentButtons,
    UserAmount,
    UserRates,
    UserReviews,
    UserStaff,
    LocationGroup,
    SubLocation,
    ModalStatus,
    ModalSorry,
    ModalReceipt,
    ModalTips,
    UiMoney,
    StaffCard,
    LocationCard,
    ModalMaxTipAmount,
    BillInfo,
    BillStaffList,
    BillCustomerInfoForm,
  },

  props: {
    locationPayoutId: {
      type: String,
      required: true,
    },
    staffPayoutId: {
      type: Array,
      default: () => [],
    },
    groupPayoutId: String,
    bill: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    feeAmount: {
      type: Number,
      default: 0,
    },
    feeChecked: {
      type: Boolean,
      default: true,
    },
    rate: {
      type: Number,
      default: 0,
    },
    status: String,
    sessionId: String,
    paymentIntent: String,
    paymentIntentClientSecret: String,
    checkoutPaymentId: String,
    checkoutPayment: Boolean,
    paymentId: String,
    isApplePay: Boolean,
    isGooglePay: Boolean,
    isApplyCheckoutPayment: Boolean,
    isGoogleCheckoutPayment: Boolean,
    isCardCheckoutPayment: Boolean,
    payPalCkoSessionId: String,
    payPalPaymentId: String,
    payPalPayment: Boolean,
    payermaxPaymentId: String,
    isPayermaxApplePayPayment: Boolean,
    isPayermaxCardPayment: Boolean,
    customerEmail: String,
    qrId: String,
  },

  computed: {
    ...mapState('user', {
      userLoading: 'isLoading',
    }),
    ...mapState('user', [
      'publicKey',
      'location',
      'group',
      'staff',
      'billInfo',
    ]),
    ...mapGetters('user', [
      'isStaff',
      'isLocation',
      'isSalon',
      'isGroup',
      'isGroupList',
      'isMasterLocation',
      'isStaffList',
      'isIndividual',
      'isCommonSimple',
      'isCommonAdvanced',
      'hasKYC',
      'hasGroups',
      'hasList',
      'wish',
      'name',
      'nickName',
      'currency',
      'countryIso',
      'minTipsAmount',
      'isUk',
    ]),
    isQrBillPayment() {
      return this.qrId;
    },
    billId() {
      return this.billInfo?.id;
    },
    billSteps() {
      return billSteps;
    },
    billFlowSteps() {
      const steps = [];

      if (this.location.requestCustomerInfo) {
        steps.push(this.billSteps.requestCustomerInfo);
      }

      steps.push(this.billSteps.billPayment);

      return steps;
    },
    billCurrentStepInfo() {
      return this.billFlowSteps
        .find(({ order }) => order === this.billCurrentStepOrder);
    },
    showBillFeedbackBlock() {
      const showBlock = this.showFeedbackBlock && this.totalSum > 0;

      return showBlock
        || (showBlock && this.isCommon);
    },
    billAmount() {
      const billTotal = this.billInfo?.total?.amount || 0;
      const tipsTotal = Number(this.totalSum || 0);
      const total = billTotal + tipsTotal;

      return Number(total.toFixed(2));
    },
    qrBillData() {
      return {
        abVariant: this.abVariant,
        tips: this.tips,
        currency: this.currency,
        selectedTip: this.selectedTip,
        amountSum: this.form.amountSum,
        feeChecked: this.form.feeChecked,
        feeAmountSum: this.form.feeAmountSum,
        locationPayoutId: this.locationPayoutId,
        apiVersion: this.apiVersion,
        minTipsAmount: this.minTipsAmount,
        showAmountFees: this.showAmountFees,
      };
    },
    paymentButtonBillData() {
      const { feeAmount } = this.checkoutPayData;

      let data = {
        billId: this.billId,
        billCustomerInfo: this.billCustomerInfo,
      };

      if (+this.totalSum) {
        data = {
          ...data,
          isTips: true,
          amount: this.form.amountSum,
        };
      } else {
        data = {
          ...data,
          isTips: false,
          amount: 0,
          feeChecked: Boolean(feeAmount),
        };
      }

      return data;
    },
    paymentButtonsData() {
      let baseData = {
        name: this.name,
        isCheckoutPayment: this.isCheckoutPayment,
        isPayermaxPayment: this.isPayermaxPayment,
        isRyftPayment: this.isRyftPayment,
        canPay: this.canPay,
        canGiroPay: this.canGiroPay,
        canPayPal: this.canPayPal,
        canPayByCard: this.canPayByCard,
        isStaff: this.isStaff,
        currency: this.checkoutPayData.currency,
        amount: this.checkoutPayData.amount,
        feeChecked: this.checkoutPayData.feeChecked,
        feeAmount: this.checkoutPayData.feeAmount,
        staffPayoutId: this.staffPayoutId[0],
        workplacePayoutId: this.checkoutPayData.workplacePayoutId,
        groupId: this.checkoutPayData.groupId,
        listPayment: this.checkoutPayData.listPayment,
        payPalPaymentData: this.getPayPalPaymentData,
        amplitudePaymentData: this.amplitudePaymentData,
        backUrl: this.checkoutPayData.backUrl,
        disabled: this.isDisabled || this.exceedAmount,
        payLoading: this.payLoading,
        giroPayLoading: this.giroPayLoading,
        cardLoading: this.cardLoading,
        applePay: this.applePay,
        googlePay: this.googlePay,
        isMultiPayPage: this.isMultiPayPage,
        successUrl: this.successUrl,
        errorUrl: this.errorUrl,
        totalSum: this.totalSum,
        minTipsAmount: this.minTipsAmount,
      };

      if (this.isQrBillPayment) {
        baseData = {
          ...baseData,
          backUrl: {
            ...baseData.backUrl,
            customerEmail: this.billCustomerInfo?.email,
          },
          ...this.paymentButtonBillData,
        };
      }

      return baseData;
    },
    apiVersion() {
      if (this.isRyftPayment) {
        return 8;
      }

      if (this.isPayermaxPayment) {
        return 7;
      }

      if (this.isUaeAndStripe) {
        return 6;
      }

      if (this.isCheckoutPayment) {
        return 5;
      }

      return 4;
    },
    modalStatusData() {
      const {
        payoutId,
        workplacePayoutId,
      } = this.getApiPaymentData();

      return {
        payoutId,
        workplacePayoutId,
        country: this.countryIso,
      };
    },
    partnerId() {
      return this.location?.partner?.id;
    },
    isUAE() {
      return this.location.country === 'ae';
    },
    isStripe() {
      return this.location.paymentSystem === 'STRIPE';
    },
    isCommon() {
      return this.isCommonSimple
        || this.isCommonAdvanced;
    },
    showCompanyLogo() {
      if (this.isQrBillPayment) {
        return true;
      }

      return (this.isPrestep
        || this.isLocation
        || this.hasGroups
        || this.isSalon
        || this.isMultiPayPage)
          && !this.isCommon
          && !this.userLoading;
    },
    locationName() {
      return this.location.name;
    },
    isRestaurant() {
      return this.location.type === 'RESTAURANT';
    },
    locationPayButtons() {
      return this.location.payButtons?.amountList;
    },
    localPayoutId() {
      return this.staffPayoutId.length
        ? this.staffPayoutId
        : [this.locationPayoutId];
    },
    isMultiPayPage() {
      return this.staffPayoutId.length > 1 || this.isQrBillPayment;
    },
    multiplePayEventsInfo() {
      return {
        multipleTips: this.isMultiPayPage || this.selectMultipleSpecialists,
      };
    },
    showMultipleSpecialistsActions() {
      return this.selectMultipleSpecialists && this.selectedSpecialists.length && this.isStaffList;
    },
    staffNames() {
      const staffNames = [];

      if (this.staffPayoutId) {
        this.staffPayoutId.forEach((staffId) => {
          const staff = this.getStaffById(staffId);
          if (staff && (staff.firstName || staff.nickName)) {
            staffNames.push(staff.nickName || staff.firstName);
          }
        });
      }

      return staffNames.join(', ');
    },
    totalSum() {
      return (this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2);
    },
    tips() {
      // mapGetters in methods dont works.
      return this.$store.getters['user/tips'](
        this.isQrBillPayment
          ? this.billInfo?.total?.amount
          : this.bill,
      );
    },
    isDebug() {
      return this.$route.query?.debug || false;
    },
    isPrestep() {
      return this.isGroupList || this.isStaffList || this.isMasterLocation;
    },
    incorrectAmount() {
      const amounts = Object.values(this.form.amount) || [];
      const eachAmountCorrect = amounts.every((amount) => !amount || amount >= this.minTipsAmount);

      return !eachAmountCorrect || this.amountError;
    },
    exceedAmount() {
      const amounts = Object.values(this.form.amount) || [];
      const eachAmountCorrect = amounts.every((amount) => amount <= this.location.maxTipsAmount);

      return !eachAmountCorrect;
    },
    showAmountFees() {
      return !this.incorrectAmount && this.form.amountSum > 0;
    },
    showSubmit() {
      if (this.isQrBillPayment) {
        return true;
      }

      if (this.incorrectAmount) {
        return false;
      }

      if (this.form.rate > 0) {
        return true;
      }

      return this.form.amountSum > 0;
    },
    onlyFeedback() {
      return this.form.amountSum <= 0 && this.form.rate > 0;
    },
    showFeedbackButtons() {
      return this.showSubmit && this.onlyFeedback;
    },
    showPaymentButtons() {
      return this.showSubmit && !this.onlyFeedback;
    },
    someLoading() {
      return this.stripeLoading
        || this.feedbackLoading
        || this.cardLoading
        || this.payLoading
        || this.receiptLoading
        || this.giroPayLoading
        || this.feeLoading
        || this.tipsFeeLoading
        || this.typing;
    },
    goodRates() {
      return this.location.reviews.filter(({ status, type }) => {
        return status === 'ACTIVE' && type === 'COMPLIMENT';
      });
    },
    isDisabled() {
      return (!this.stripe && !this.isCheckoutPayment && !this.isPayermaxPayment && !this.isRyftPayment)
        || this.someLoading
        || !this.form.agree;
    },
    canGooglePay() {
      return this.googlePay && this.location.paymentMethodsAllowed.includes('GOOGLE_PAY');
    },
    canApplePay() {
      return this.applePay && this.location.paymentMethodsAllowed.includes('APPLE_PAY');
    },
    canPay() {
      return this.canApplePay || this.canGooglePay;
    },
    canGiroPay() {
      if (this.isIndividual) {
        return this.staff.paymentMethodsAllowed.includes('GIRO_PAY');
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return this.location.paymentMethodsAllowed.includes('GIRO_PAY');
      }

      return false;
    },
    canPayByCard() {
      return this.location.paymentMethodsAllowed.includes('BY_CARD');
    },
    canPayPal() {
      if (this.isIndividual) {
        return this.staff.paymentMethodsAllowed.includes('PAY_PAL');
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return this.location.paymentMethodsAllowed.includes('PAY_PAL');
      }

      return false;
    },
    stripePaymentData() {
      const totalAmount = +(this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2);

      return {
        country: 'US', // this.countryIso.toUpperCase(),
        currency: this.currency.iso,
        total: {
          label: `Tip for ${this.name}`,

          // The amount in the currency's subunit (e.g. cents, yen, etc.)
          // @see {@link https://stripe.com/docs/js/appendix/payment_item_object#payment_item_object-amount}
          amount: Math.round(totalAmount * 100),
        },
      };
    },
    amplitudePaymentData() {
      const data = {
        ...(this.getApiPaymentData()),
        payoutId: this.localPayoutId.join(', '),
        country: this.countryIso,
        ...this.multiplePayEventsInfo,
      };

      if (!this.location.showReviewsModule) {
        return data;
      }

      const {
        stars,
        reviews,
        ...other
      } = data;

      return {
        ...other,
        rate: stars,
        reviews: this.reviewsMap,
      };
    },
    successUrl() {
      return this.getStatusUrl('success');
    },
    errorUrl() {
      return this.getStatusUrl('error');
    },
    giroPayUrl() {
      return this.getStatusUrl('giroPay');
    },
    reviewsMap() {
      return this.form.reviews.reduce((carry, { id, name }) => {
        return {
          ...carry,
          [id]: name,
        };
      }, {});
    },
    reviewsNames() {
      return this.form.reviews.map((item) => ({
        ...item,
        name: item.name.toUpperCase(),
      }));
    },
    showModalSorry() {
      return this.form.rate > 0 && this.form.rate < 4;
    },
    payoutKey() {
      return [
        this.locationPayoutId,
        ...this.staffPayoutId,
        this.groupPayoutId,
      ].filter((v) => v).join('-');
    },
    filteredStaffs() {
      const staffList = this.isGroup && this.$can('subscription:tips-groups-view')
        ? this.group.staffs
        : this.location.staffs;

      const query = this.isGroup
        ? { groupId: this.groupPayoutId }
        : null;

      return staffList
        .filter((item) => {
          return !this.selectMultipleSpecialists || this.location.paymentSystem === item.paymentSystem;
        })
        .filter((item) => {
          return `${item.nickName || getFio(item)}`.toLowerCase().indexOf(
            this.filter.toLowerCase(),
          ) > -1 && item.payoutId;
        })
        .map((item) => ({
          ...item,
          link: {
            params: {
              locationPayoutId: this.locationPayoutId,
            },
            query: {
              ...this.$route.query,
              ...query,
              staffId: item.payoutId,
            },
          },
        }));
    },
    filteredLocations() {
      return this.location.subLocations
        .filter((item) => item.payoutId)
        .map((item) => ({
          ...item,
          link: {
            params: {
              locationPayoutId: item.payoutId,
            },
          },
        }));
    },
    filteredGroups() {
      return this.location.groups
        .filter((item) => item.staffs.length && item.payoutId)
        .map(({ url, ...item }) => ({
          ...item,
          link: url ? {
            path: url,
            query: {
              ...this.$route.query,
            },
          } : {
            params: {
              locationPayoutId: this.locationPayoutId,
            },
            query: {
              ...this.$route.query,
              groupId: item.payoutId,
            },
          },
        }));
    },
    hasAggregateGroup() {
      // If path exists then group is fake reference to another restaurant (EASYTIP-3089).
      return this.filteredGroups.some(({ link }) => Boolean(link.path));
    },
    groupTitle() {
      if (this.hasAggregateGroup) {
        return this.$t('search.aggregateTitle');
      }

      return this.$t('search.groupTitle');
    },
    abVariant() {
      return this.location.abVariant;
    },
    typeOfReview() {
      return this.location.typeOfReview;
    },
    showFeedbackLinks() {
      const typesOfReviewForShowLinks = [
        'PUBLIC_REVIEWS_ONLY',
        'STANDARD_AND_PUBLIC_REVIEWS_ONLY',
      ];
      return typesOfReviewForShowLinks.includes(this.typeOfReview);
    },
    showFeedbackBlock() {
      return this.typeOfReview !== 'DISABLE_REVIEWS' && this.typeOfReview !== 'PUBLIC_REVIEWS_ONLY';
    },
    showFeedbackMessage() {
      if (this.typeOfReview === 'STANDARD_AND_PUBLIC_REVIEWS_ONLY' && this.form.rate > 3) {
        return false;
      }

      return this.form.rate;
    },
    feedbackLinks() {
      if (!this.showFeedbackLinks) {
        return {};
      }

      const attributes = [
        'googleMapsLink',
        'tripAdvisorLink',
        'trustPilotLink',
      ];

      return attributes.reduce((carry, attribute) => {
        const link = this.location[attribute];

        if (link) {
          carry = {
            ...carry,
            [attribute]: link,
          };
        }

        return carry;
      }, {});
    },
    isCheckoutPayment() {
      const checkoutTypes = ['CHECKOUT_UAE', 'CHECKOUT', 'CHECKOUT_UK_DASHBOARD'];

      if (this.isIndividual) {
        return checkoutTypes.includes(this.staff.paymentSystem);
      }

      if (this.isCommonSimple || this.isCommonAdvanced) {
        return checkoutTypes.includes(this.location.paymentSystem);
      }

      return false;
    },
    isPayermaxPayment() {
      if (this.isCommon || this.isQrBillPayment) {
        return this.location.paymentSystem === 'PAYER_MAX';
      }

      if (this.isIndividual) {
        return this.staff.paymentSystem === 'PAYER_MAX';
      }

      return false;
    },
    isRyftPayment() {
      if (this.isCommon) {
        return this.location.paymentSystem === 'RYFT';
      }

      if (this.isIndividual) {
        return this.staff.paymentSystem === 'RYFT';
      }

      return false;
    },
    checkoutPayData() {
      const {
        feeChecked,
        currency,
        amount,
        feeAmount,
        groupId,
        workplacePayoutId,
        listPayment = [],
        ...data
      } = this.getApiPaymentData();

      let tempListPayment = listPayment;

      if (tempListPayment.length === 0) {
        tempListPayment = [{
          payoutId: data.payoutId,
          comment: data.comment,
          stars: data.stars,
          reviews: data.reviews,
          amount,
        }];
      }

      return {
        listPayment: tempListPayment,
        feeChecked,
        currency,
        amount,
        feeAmount,
        groupId,
        workplacePayoutId,
        backUrl: {
          rate: this.form.rate,
          locationId: this.$route.params.locationPayoutId,
          query: this.$route.query,
        },
      };
    },
    getPayPalPaymentData() {
      return {
        ...this.getApiPaymentData(),
        rate: this.form.rate,
        locationId: this.locationPayoutId,
        payPalPayment: this.canPayPal,
      };
    },
    billMoney() {
      return new Money(this.bill || 0, this.currency).toString();
    },
    submitMoney() {
      return new Money(this.form.amountSum, this.currency).toString();
    },
    showMultiTipsSwitcher() {
      return this.location.multiTipsMode === multiTipsModes.ON || this.location.multiTipsMode === multiTipsModes.DEFAULT_ON;
    },
    isUaeAndStripe() {
      return this.isUAE && this.isStripe;
    },
  },

  watch: {
    payoutKey: {
      immediate: true,
      async handler() {
        const {
          locationPayoutId,
          staffPayoutId,
          groupPayoutId,
          localPayoutId,
          bill,
          paymentId,
          isApplePay,
          isGooglePay,
          checkoutPaymentId,
          payPalPaymentId,
          payPalCkoSessionId,
          payermaxPaymentId,
          isPayermaxApplePayPayment,
          isPayermaxCardPayment,
          qrId,
        } = this;

        const amplitudeParams = {
          bill,
          workplacePayoutId: locationPayoutId,
          pathname: window.location.pathname,
          ...this.multiplePayEventsInfo,
        };

        try {
          await this.loadUser({
            qrId: !this.status ? qrId : '',
            locationPayoutId,
            staffPayoutIdList: staffPayoutId,
            staffPayoutId: staffPayoutId[0],
            groupPayoutId,
          });

          updateAbilities(this.location.features, this.$ability);

          const firstVisitChecked = sessionStorage.getItem('firstVisitChecked');
          const menuIsAvailable = this.$route.query.preview ? this.location.isOnlineMenuPreviewExists : this.location.isOnlineMenuPublished;

          if (menuIsAvailable && isFirstPageVisit() && !firstVisitChecked) {
            await this.$router.push({ name: 'location' });
          }

          amplitudeParams.country = this.countryIso;

          this.$amplitude.event('PAGE_VIEW', {
            ...amplitudeParams,
            hostname: window.location.hostname,
            url: window.location.href,
          });

          if (!this.status) {
            if (this.isGroupList) {
              this.$amplitude.event('PAGE_OPEN_GROUP_LIST', {
                ...amplitudeParams,
                groupPayoutId,
              });
            } else if (this.isStaffList) {
              this.$amplitude.event('PAGE_OPEN_STAFF_LIST', amplitudeParams);
            } else if (this.isLocation) {
              this.$amplitude.event('PAGE_OPEN_LOCATION', amplitudeParams);
            } else if (this.isStaff) {
              this.$amplitude.event('PAGE_OPEN_STAFF', {
                ...amplitudeParams,
                payoutId: localPayoutId.join(', '),
              });
            }
          }
        } catch (e) {
          this.$amplitude.event('PAGE_WRONG', {
            pathname: window.location.pathname,
          });

          this.$router.push({ name: 'fallback' });

          return;
        }

        let checkout;
        if (this?.checkoutPayment) {
          checkout = this.checkoutPayment;
        } else {
          checkout = this.isCheckoutPayment;
        }

        const amplitudePaymentData = {
          payoutId: localPayoutId.join(', '),
          workplacePayoutId: locationPayoutId,
          amount: this.amount,
          feeAmount: this.feeAmount,
          feeChecked: this.feeChecked,
          sessionId: this.sessionId,
          country: this.countryIso,
        };

        if (checkout) {
          if (this.status) {
            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendPhone({ paymentId: checkoutPaymentId });
              };
            } else {
              if (this.status === 'error') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAGE_OPEN_ERROR`,
                  amplitudePaymentData,
                  checkoutPaymentId,
                );

                if (this.isApplyCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_APPLE_PAY_ERROR`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isGoogleCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_GOOGLE_PAY_ERROR`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isCardCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_PAY_BY_CARD_FAILED`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                this.modalStatus = 'error';
              }

              if (this.status === 'success') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAGE_OPEN_SUCCESS`,
                  amplitudePaymentData,
                  checkoutPaymentId,
                );

                if (this.isApplyCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_APPLE_PAY_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isGoogleCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_GOOGLE_PAY_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                if (this.isCardCheckoutPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_CHECKOUT_PAY_BY_CARD_SUCCESS`,
                    amplitudePaymentData,
                    checkoutPaymentId,
                  );
                }

                this.modalStatus = 'success';
              }
            }
          }
        } else if (this.isRyftPayment) {
          if (this.status) {
            const statusValue = this.status.toUpperCase();
            const OPERATOR = 'RYFT';

            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendPhone({ paymentId });
              };
            } else {
              this.$amplitude.eventOnce(
                `${this.amplitudeEventNamePrefix}_PAGE_OPEN_${statusValue}`,
                amplitudePaymentData,
                paymentId,
              );

              if (isApplePay) {
                this.$amplitude.eventOnce(
                  `${this.amplitudeEventNamePrefix}_${OPERATOR}_APPLE_PAY_${statusValue}`,
                  amplitudePaymentData,
                  paymentId,
                );
              } else if (isGooglePay) {
                this.$amplitude.eventOnce(
                  `${this.amplitudeEventNamePrefix}_${OPERATOR}_GOOGLE_PAY_${statusValue}`,
                  amplitudePaymentData,
                  paymentId,
                );
              } else {
                this.$amplitude.eventOnce(
                  `${this.amplitudeEventNamePrefix}_${OPERATOR}_PAY_BY_CARD_${statusValue}`,
                  amplitudePaymentData,
                  paymentId,
                );
              }

              this.modalStatus = statusValue.toLowerCase();
            }
          }
        } else if (this.isPayermaxPayment) {
          if (this.status) {
            const statusValue = this.status.toLowerCase();

            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendPhone({ paymentId: payermaxPaymentId });
              };
            } else {
              if (statusValue === 'error') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAGE_OPEN_ERROR`,
                  amplitudePaymentData,
                  payermaxPaymentId,
                );

                if (isPayermaxApplePayPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_PAYERMAX_APPLE_PAY_ERROR`,
                    amplitudePaymentData,
                    payermaxPaymentId,
                  );
                }

                if (isPayermaxCardPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_PAYERMAX_PAY_BY_CARD_FAILED`,
                    amplitudePaymentData,
                    payermaxPaymentId,
                  );
                }

                this.modalStatus = 'error';
              }

              if (statusValue === 'success') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAGE_OPEN_SUCCESS`,
                  amplitudePaymentData,
                  payermaxPaymentId,
                );

                if (isPayermaxApplePayPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_PAYERMAX_APPLE_PAY_SUCCESS`,
                    amplitudePaymentData,
                    payermaxPaymentId,
                  );
                }

                if (isPayermaxCardPayment) {
                  this.$amplitude.eventOnce(
                    `${this.eventTrackerNamePrefix}_PAYERMAX_PAY_BY_CARD_SUCCESS`,
                    amplitudePaymentData,
                    payermaxPaymentId,
                  );
                }

                this.modalStatus = 'success';
              }
            }
          }
        } else if (this.payPalPayment) {
          if (this.status) {
            await this.checkPayPalPayment({
              status: this.status,
              paymentId: payPalPaymentId,
              ckoSessionId: payPalCkoSessionId,
            });

            if (this.showModalSorry) {
              this.modalSorry = () => {
                return this.sendPhone({
                  paymentId: payPalPaymentId,
                });
              };
            } else {
              if (this.status === 'error') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAYPAL_PAY_ERROR`,
                  amplitudePaymentData,
                  payPalCkoSessionId,
                );

                this.modalStatus = 'error';
              }

              if (this.status === 'success') {
                this.$amplitude.eventOnce(
                  `${this.eventTrackerNamePrefix}_PAYPAL_PAY_SUCCESS`,
                  amplitudePaymentData,
                  payPalCkoSessionId,
                );

                this.modalStatus = 'success';
              }
            }
          }
        } else {
          this.loadStripe();

          if (this.status) {
            if (this.status === 'error') {
              this.modalStatus = 'error';
            }

            if (this.status === 'success') {
              this.$amplitude.eventOnce(
                `${this.eventTrackerNamePrefix}_PAGE_OPEN_SUCCESS`,
                amplitudePaymentData,
                this.sessionId,
              );

              if (!this.showModalSorry) {
                this.modalStatus = this.status;
              }

              this.cardPay = true;

              try {
                const {
                  data: {
                    paymentIntentId,
                  },
                } = await api.getIntent(
                  {
                    sessionId: this.sessionId,
                    workplacePayoutId: this.locationPayoutId,
                    apiVersion: this.isUaeAndStripe ? 6 : 4,
                  },
                );

                if (paymentIntentId) {
                  this.$amplitude.eventOnce(`${this.eventTrackerNamePrefix}_PAY_BY_CARD_SUCCESS`, {
                    paymentIntentId,
                    ...amplitudePaymentData,
                  }, paymentIntentId);

                  this.sendConfirm({ paymentIntentId });

                  if (this.showModalSorry) {
                    this.modalSorry = () => {
                      return this.sendPhone({ paymentId: paymentIntentId });
                    };
                  }
                }
              } catch (e) {
                this.warn(e);
              }
            } else if (this.status === 'error') {
              this.$amplitude.eventOnce(
                `${this.eventTrackerNamePrefix}_PAGE_OPEN_ERROR`,
                amplitudePaymentData,
                this.sessionId,
              );

              this.$amplitude.eventOnce(
                `${this.eventTrackerNamePrefix}_PAY_BY_CARD_FAILED`,
                amplitudePaymentData,
                this.sessionId,
              );
            }

            if (this.status === 'giroPay') {
              this.handleGiroPayStatus(amplitudePaymentData);
            }
          }
        }

        this.form.amount = {};
        this.form.feeAmount = {};

        this.localPayoutId.forEach((payoutId) => {
          this.form.amount = {
            ...this.form.amount,
            [payoutId]: 0,
          };
          this.form.feeAmount = {
            ...this.form.feeAmount,
            [payoutId]: 0,
          };
        });
        if (this.selectedTip.value) {
          this.selectTipFromSelector(this.selectedTip);
        }

        this.isPayoutKeyLoaded = true;
      },
    },
    'form.rate': {
      handler(value) {
        this.form.reviews.splice(0);
      },
    },
    $route: {
      handler(newRoute) {
        if (!newRoute.query.staffId) {
          this.reset();
        }
      },
    },
    selectMultipleSpecialists: {
      handler(switcherStatus) {
        this.$amplitude.event('MULTIPLE_PAY_SWITCHER_CHANGED', switcherStatus);
      },
    },
    'form.feeAmountSum': {
      handler(feeAmount) {
        if (feeAmount) {
          this.$amplitude.event(`${this.eventTrackerNamePrefix}_FEE_AMOUNT_CHANGE`, {
            payoutId: this.localPayoutId.join(', '),
            feeAmount,
            ...this.multiplePayEventsInfo,
          });
        }
      },
    },
    modalSorry: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_SORRY_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    modalReceipt: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_RECEIPT_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    modalStatus: {
      handler(value) {
        if (value) {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_STATUS_OPEN`,
            this.amplitudePaymentData,
          );
        }
      },
    },
    location: {
      immediate: true,
      handler(location) {
        if (location.multiTipsMode === multiTipsModes.DEFAULT_ON) {
          this.selectMultipleSpecialists = true;
        }
      },
    },
    userLoading: {
      immediate: true,
      handler(value) {
        if (!value
          && (this.isCommon
            || this.isStaff
            || this.isMultiPayPage
            || this.isQrBillPayment)
        ) {
          this.loadTipsFee();
        }
      },
    },
    exceedAmount: {
      immediate: true,
      handler(value) {
        if (value) {
          this.showMaxTipAmountModal = true;
        }
      },
    },
    billFlowSteps: {
      handler() {
        const billInfoStorage = storage.get(this.billId);

        if (!this.location.requestCustomerInfo) {
          if (billInfoStorage) {
            this.billCustomerInfo = {};
            storage.set(this.billId, null);
          }

          return;
        }

        if (billInfoStorage) {
          this.billCustomerInfo = billInfoStorage.billCustomerInfo;
          this.billNextStep();
        } else {
          this.billCurrentStepOrder = 1;
        }
      },
    },
  },

  methods: {
    ...mapActions({
      loadUser: 'user/loadUser',
      loadLocationStaffs: 'user/loadLocationStaffs',
      calculateFee: 'fee/calculateFee',
      showConfirm: 'alert/showConfirm',
      focusMoneyInput: 'focus/focusMoneyInput',
    }),
    handleBillCustomerInfo(data) {
      storage.set(this.billId, { billCustomerInfo: data });
      this.billCustomerInfo = data;
      this.billNextStep();
    },
    billNextStep() {
      const nextOrder = this.billCurrentStepOrder + 1;

      if (nextOrder > this.billFlowSteps.length) {
        return;
      }

      this.billCurrentStepOrder = nextOrder;
    },
    async loadTipsFee() {
      this.tipsFeeLoading = true;

      let apiVersion = 4;
      if (this.isCheckoutPayment) {
        apiVersion = 5;
      } else if (this.isUaeAndStripe) {
        apiVersion = 6;
      } else if (this.isPayermaxPayment) {
        apiVersion = 7;
      } else if (this.isRyftPayment) {
        apiVersion = 8;
      }

      try {
        await Promise.allSettled(
          this.tips.map(({ value }) => {
            return this.calculateFee({
              payoutId: this.locationPayoutId,
              minTipsAmount: this.minTipsAmount,
              totalAmount: this.staffPayoutId.length
                ? value * this.staffPayoutId.length
                : value,
              apiVersion,
            });
          }),
        );
      } finally {
        this.tipsFeeLoading = false;
      }
    },
    getGiroPayPaymentData() {
      const {
        amount,
        currency,
        feeAmount,
        feeChecked,
        groupId,
        workplacePayoutId,
        listPayment,
      } = this.getApiPaymentData(true);

      return {
        amount,
        currency,
        feeAmount,
        feeChecked,
        groupId,
        workplacePayoutId,
        listPayment,
        paymentMethod: 'GIROPAY',
      };
    },
    getApiPaymentData(isMultiStaffData = false) {
      let data = {
        workplacePayoutId: this.locationPayoutId,
        groupId: this.groupPayoutId || undefined,
        currency: this.currency.iso,
        amount: this.form.amountSum,
        totalAmount: +(this.form.amountSum + (this.form.feeChecked ? this.form.feeAmountSum : 0)).toFixed(2),
        feeAmount: this.form.feeAmountSum,
        feeChecked: this.form.feeChecked,
        phoneNumber: this.form.phoneNumber,
        kycProcessed: this.hasKYC,
      };

      if (this.isMultiPayPage || isMultiStaffData) {
        let staffList = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const elem in this.form.amount) {
          if (Object.prototype.hasOwnProperty.call(this.form.amount, elem)) {
            staffList.push({
              amount: this.form.amount[elem],
              payoutId: elem,
            });
          }
        }

        if (this.location.showReviewsModule) {
          staffList = staffList.map((elem) => ({
            comment: this.form.comment,
            stars: this.form.rate,
            reviews: this.reviewsNames,
            ...elem,
          }));
        }

        // TODO: change structure when listFeedBack and listPayment will be different
        data = {
          ...data,
          listPayment: staffList.filter((staff) => staff.amount),
          listFeedback: staffList,
        };

        return data;
      }

      data = {
        ...data,
        payoutId: this.localPayoutId[0],
      };

      if (this.location.showReviewsModule) {
        data = {
          comment: this.form.comment,
          stars: this.form.rate,
          reviews: this.reviewsNames,
          ...data,
        };
      }

      return data;
    },
    updateQrBillAmount({ payload, resetTip }) {
      if (resetTip) {
        this.selectedTip = {};
      }

      this.form.amount = payload;
      this.setActualAmount();
    },
    selectTipFromSelector(tip, { setTip = true, ...options } = {}) {
      // eslint-disable-next-line no-restricted-syntax
      for (const elem in this.form.amount) {
        if (Object.prototype.hasOwnProperty.call(this.form.amount, elem)) {
          this.form.amount[elem] = tip.value || 0;
        }
      }

      if (this.selectedTip.id !== tip.id) {
        if (setTip) {
          this.selectedTip = tip;
        }

        this.setActualAmount();
        this.onTipChanged(tip);
      }
    },
    updateAmount(amount, id) {
      this.selectedTip = {};
      this.form.amount[id] = amount;
      this.setActualAmount();
    },
    setActualAmount() {
      const amountSum = Object.values(this.form.amount).reduce((a, b) => a + b, 0);

      if (this.form.amountSum !== amountSum) {
        this.form.amountSum = amountSum;
        this.form.feeAmountSum = 0;
      }
    },
    onFeeCheckedChangedHandler(value) {
      this.form.feeChecked = value;
      this.onFeeCheckedChanged(value);
    },
    continueWithSelectedSpecialists() {
      this.$router.push({
        params: {
          locationPayoutId: this.locationPayoutId,
        },
        query: {
          staffId: this.selectedSpecialists.map((specialist) => specialist.payoutId),
        },
      });
      document.querySelector('body').scrollTop = 0;
    },
    clearSelectedSpecialists() {
      this.selectedSpecialists = [];
    },
    getStaffById(id) {
      return this.location?.staffs?.find((staff) => staff.payoutId === id) || null;
    },
    onFeeOpened() {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_FEE_OPEN`, {
        payoutId: this.localPayoutId.join(', '),
        ...this.multiplePayEventsInfo,
      });
    },
    onLocaleChange({ from, to }) {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_LOCALE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        fromLocale: from,
        toLocale: to,
        ...this.multiplePayEventsInfo,
      });
    },
    onFeeCheckedChanged(feeChecked) {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_FEE_CHECKED_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        feeChecked,
        ...this.multiplePayEventsInfo,
      });
    },
    onTipChanged(tip) {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_TIP_PRESS`, {
        payoutId: this.localPayoutId.join(', '),
        tip,
        ...this.multiplePayEventsInfo,
      });
    },
    onAmountError(error) {
      if (error) {
        this.$amplitude.event(`${this.eventTrackerNamePrefix}_AMOUNT_ERROR_INPUT`, {
          payoutId: this.localPayoutId.join(', '),
          error,
          ...this.multiplePayEventsInfo,
        });
      }

      this.amountError = error;
    },
    onRateChanged(rate) {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_RATE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        rate,
        ...this.multiplePayEventsInfo,
      });
    },
    onReviewsChanged() {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_REVIEWS_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        reviews: this.reviewsMap,
        ...this.multiplePayEventsInfo,
      });
    },
    onAgreeChanged(agree) {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_AGREE_CHANGE`, {
        payoutId: this.localPayoutId.join(', '),
        agree,
        ...this.multiplePayEventsInfo,
      });
    },
    debug(value) {
      if (this.isDebug) {
        // eslint-disable-next-line no-alert
        alert(value);
      } else {
        console.log(value);
      }
    },
    warn(value) {
      if (this.isDebug) {
        // eslint-disable-next-line no-alert
        alert(value);
      }
    },
    async loadStripe() {
      this.stripeLoading = true;

      // @see {@link https://github.com/stripe/react-stripe-elements/issues/379}
      if (window.PaymentRequest) {
        const details = {
          total: {
            label: 'x',
            amount: {
              currency: 'GBP',
              value: '9.99',
            },
          },
        };
        // eslint-disable-next-line no-new
        new window.PaymentRequest([{ supportedMethods: ['basic-card'] }], details);
      }

      try {
        loadStripe.setLoadParameters({ advancedFraudSignals: false });
      } catch (e) {
        // You cannot change load parameters after calling loadStripe
      }

      try {
        this.stripe = await loadStripe(this.publicKey, {
          apiVersion: '2020-03-02',
        });
        this.stripePaymentRequest = this.stripe.paymentRequest(this.stripePaymentData);

        const result = await this.stripePaymentRequest.canMakePayment();

        if (!result) {
          return;
        }

        this.applePay = Boolean(result.applePay);
        this.googlePay = Boolean(result.googlePay);

        this.debug(JSON.stringify(result));
      } catch (e) {
        this.warn(e);
      } finally {
        this.stripeLoading = false;

        this.applePayButtonAmplitudeEvent(this.applePay, 'EXISTS', 'DO_NOT_EXIST');
        this.googlePayButtonAmplitudeEvent(this.googlePay, 'EXISTS', 'DO_NOT_EXIST');
        this.payByCardButtonAmplitudeEvent(this.stripe, 'EXISTS', 'DO_NOT_EXIST');
      }
    },
    getStatusUrl(status) {
      const {
        totalAmount,
        feeAmount,
        feeChecked,
        stars,
      } = this.getApiPaymentData();

      return window.location.origin + this.$router.resolve(
        this.getNestedRoute({
          params: {
            status,
          },
          query: {
            amount: totalAmount,
            feeAmount,
            feeChecked,
            rate: stars,
            backUrl: window.location.href,
          },
        }),
      )?.href;
    },
    getNestedRoute(nested) {
      const { name, params, query } = this.$route;

      return merge({}, {
        name,
        params,
        query,
      }, nested);
    },
    async sendConfirm(data) {
      try {
        await api.confirmPayment({
          apiVersion: this.isUaeAndStripe ? 6 : 4,
          success: true,
          ...data,
        });
      } catch (e) {
        this.warn(e);
      }
    },
    async sendGiroPayConfirm(data) {
      try {
        await api.confirmGiroPayPayment({
          success: true,
          ...data,
        });
      } catch (e) {
        this.warn(e);
      }
    },
    toggleReceipt() {
      this.$amplitude.event(
        `${this.eventTrackerNamePrefix}_TOGGLE_RECEIPT`,
        this.multiplePayEventsInfo,
      );
    },
    async sendReceipt(email) {
      let config = {
        apiVersion: this.apiVersion,
      };

      if (this.isCheckoutPayment) {
        config = {
          ...config,
          paymentId: this.checkoutPaymentId,
          name: '_CHECKOUT',
        };
      } else if (this.isUaeAndStripe) {
        config = {
          ...config,
          paymentId: this.paymentIntentId,
          name: '',
        };
      } else if (this.payPalPayment) {
        config = {
          ...config,
          paymentId: this.payPalPaymentId,
          name: '_PAYPAL',
        };
      } else if (this.isPayermaxPayment) {
        config = {
          ...config,
          paymentId: this.payermaxPaymentId,
          name: '_PAYERMAX',
        };
      } else if (this.isRyftPayment) {
        config = {
          ...config,
          paymentId: this.paymentId,
          name: '_RYFT',
        };
      } else {
        config = {
          apiVersion: null,
          paymentId: this.paymentIntentId,
          name: '',
        };
      }

      if (!config.paymentId) {
        return;
      }

      this.receiptLoading = true;

      try {
        await api.sendReceiptEmail({
          email,
          paymentId: config.paymentId,
          apiVersion: config.apiVersion,
        });

        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}${config.name}_SEND_RECEIPT`,
          this.multiplePayEventsInfo,
        );

        this.modalReceipt = true;
        this.modalStatus = false;
        this.modalSorry = false;
      } catch (e) {
        this.warn(e);
      } finally {
        this.receiptLoading = false;
      }
    },
    async sendPhone({ paymentId } = {}) {
      if (!this.form.phoneNumber) {
        return;
      }

      this.sorryLoading = true;

      let version = this.apiVersion;
      if (!(this.isCheckoutPayment
        || this.isUaeAndStripe
        || this.payPalPayment
        || this.isPayermaxPayment
        || this.isRyftPayment
      )) {
        version = null;
      }

      try {
        if (this.receipted) {
          await this.sendReceipt(this.form.phoneNumber);
        }

        if (paymentId) {
          await api.sendPaymentPhone({
            phoneNumber: this.form.phoneNumber,
            paymentId,
            apiVersion: version,
          });
        } else {
          await api.sendFeedbackPhone({
            phoneNumber: this.form.phoneNumber,
            feedbackId: this.feedbackId,
            apiVersion: version,
          });
        }
      } catch (e) {
        this.warn(e);
      } finally {
        this.sorryLoading = false;
      }
    },
    clickedLink(linkName) {
      this.$amplitude.event(
        `${this.eventTrackerNamePrefix}_${linkName}_OPEN`,
        this.multiplePayEventsInfo,
      );
    },
    transactionHasCollision() {
      const {
        totalAmount,
        payoutId,
        workplacePayoutId,
      } = this.getApiPaymentData();

      return checkTransactionCollision({
        locationPayoutId: workplacePayoutId,
        amount: totalAmount,
        staffId: payoutId,
      });
    },
    onFeeLoadingChanged(value) {
      this.feeLoading = value;
    },
    onTypingChanged(value) {
      this.typing = value;
    },
    async handleSubmit(method, skipCollisionCheck = false) {
      if (this.someLoading) {
        return;
      }

      if (!await this.overtippingConfirmHandler({
        amountSum: this.form.amountSum,
        submitMoney: this.submitMoney,
      })) {
        return;
      }

      if (!skipCollisionCheck && this.transactionHasCollision() && this.form.amountSum) {
        this.abortedWithSubmitMethod = method;
        this.showSubmitPayModal = true;
        return;
      }

      if (typeof method === 'function') {
        method();

        return;
      }

      if (method === 'submitBeforeFeedback') {
        if (this.isCheckoutPayment) {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_CHECKOUT_FEEDBACK_PRESS`,
            this.amplitudePaymentData,
          );
        } else {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_FEEDBACK_PRESS`,
            this.amplitudePaymentData,
          );

          if (this.showModalSorry) {
            this.modalSorry = this.sendPhone;
          }
        }
      }

      if (method === 'submitPay') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitPayByCard') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_PAY_BY_CARD_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitCheckoutPayByCard') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_CHECKOUT_PAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitPayermaxPayByCard') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_PAYERMAX_PAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      if (method === 'submitGiroPay') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_GIROPAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      this[method]();
    },
    handleModalTipsSelect(tip) {
      if (isEmpty(tip)) {
        this.form.amountSum = 0;
        this.form.feeAmountSum = 0;
      }

      this.selectTipFromSelector(tip, { setTip: false });
    },
    submitPayInModal() {
      this.showSubmitPayModal = false;
      this.handleSubmit(this.abortedWithSubmitMethod, true);
      this.abortedWithSubmitMethod = null;
    },
    redirectToLocation() {
      this.$router.push({
        name: 'home',
        params: {
          locationPayoutId: this.locationPayoutId,
        },
      });
    },
    submitStatus() {
      if (this.modalStatus) {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_STATUS_OK`,
          this.amplitudePaymentData,
        );
      }

      if (this.modalReceipt) {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_RECEIPT_OK`,
          this.amplitudePaymentData,
        );
      }
      if (this.isQrBillPayment) {
        window.location.href = this.location.customRedirectUrl
          || PAY_LINK;
        return;
      }

      if ((!this.hasGroups && !this.hasList)) {
        window.location.href = PAY_LINK;
        return;
      }

      this.redirectToLocation();
      this.reset();
    },
    async submitSorry() {
      this.$amplitude.event(
        `${this.eventTrackerNamePrefix}_SORRY_${this.form.phoneNumber ? 'OK' : 'NOT_NOW'}`,
        this.amplitudePaymentData,
      );

      await this.modalSorry.call(this);

      if (this.modalStatus) {
        return;
      }

      if (this.feedbackSent) {
        this.submitStatus();
      } else {
        this.modalStatus = 'success';
        this.$nextTick(() => {
          this.modalSorry = null;
        });
      }
    },
    submitBeforeFeedback() {
      if (
        isEmpty(this.selectedTip)
        && !this.showModalSorry
        && this.form.rate > 3
      ) {
        this.$amplitude.event(
          `${this.isIndividual ? 'STAFF' : 'RESTAURANT'}_REVIEW_TIPS_POP_UP_SHOWN`,
          this.amplitudePaymentData,
        );
        this.modalTips = true;
        return;
      }

      this.handleModalTipsFeedback();
    },
    async handleModalTipsFeedback() {
      this.feedbackLoading = true;

      let config = { apiVersion: this.apiVersion };

      if (this.isCheckoutPayment) {
        config = { ...config, name: '_CHECKOUT' };
      } else if (this.isUaeAndStripe) {
        config = { ...config, name: '' };
      } else if (this.payPalPayment) {
        config = { ...config, name: '_PAYPAL' };
      } else if (this.isPayermaxPayment) {
        config = { ...config, name: '_PAYERMAX' };
      } else if (this.isRyftPayment) {
        config = { ...config, name: '_RYFT' };
      } else {
        config = { apiVersion: null, name: '' };
      }

      const {
        workplacePayoutId,
        groupId,
        listFeedback,
      } = this.getApiPaymentData(true);

      try {
        const { data } = await api.sendFeedback({
          workplacePayoutId,
          groupId,
          listFeedback,
          apiVersion: config.apiVersion,
        });

        if (data.id) {
          this.feedbackId = data.id;
        }

        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}${config.name}_FEEDBACK_SUCCESS`,
          this.amplitudePaymentData,
        );

        this.feedbackSent = true;

        if (this.showModalSorry) {
          this.modalSorry = () => {
            return this.sendPhone();
          };
        } else {
          this.modalStatus = 'success';
        }

        return;
      } catch (e) {
        this.debug(e);
      } finally {
        this.feedbackLoading = false;
      }

      this.$amplitude.event(
        `${this.eventTrackerNamePrefix}${config.name}_FEEDBACK_FAILED`,
        this.amplitudePaymentData,
      );

      this.modalStatus = 'error';
    },
    submitPay() {
      this.payLoading = true;

      try {
        // @see {@link https://stripe.com/docs/stripe-js/elements/payment-request-button}
        this.stripePaymentRequest.update({ total: this.stripePaymentData.total });
        this.stripePaymentRequest.show();
        this.stripePaymentRequest.on('paymentmethod', async (ev) => {
          try {
            const {
              feeChecked,
              currency,
              amount,
              feeAmount,
              groupId,
              workplacePayoutId,
              ...data
            } = this.getApiPaymentData();

            let tempListPayment = [];

            if (this.isMultiPayPage) {
              tempListPayment = data.listPayment;
            } else {
              tempListPayment = [{
                payoutId: data.payoutId,
                comment: data.comment,
                stars: data.stars,
                reviews: data.reviews,
                amount,
              }];
            }

            const {
              data: {
                secret,
                paymentIntentId,
              },
            } = await api.createMobilePayment({
              listPayment: tempListPayment,
              amount,
              currency,
              feeAmount,
              feeChecked,
              groupId,
              workplacePayoutId,
              paymentMethod: 'MOBILE',
              successUrl: this.successUrl,
              errorUrl: this.errorUrl,
              isApplePay: this.applePay,
              apiVersion: this.isUaeAndStripe ? 6 : 4,
            });

            this.$amplitude.event(
              `${this.eventTrackerNamePrefix}_`
              + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_CONFIRM`,
              { secret, paymentIntentId, ...this.amplitudePaymentData },
            );

            const {
              paymentIntent,
              error: confirmError,
            } = await this.stripe.confirmCardPayment(
              secret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false },
            );

            if (confirmError) {
              this.$amplitude.event(
                `${this.eventTrackerNamePrefix}_`
                + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
                { secret, paymentIntent, ...this.amplitudePaymentData },
              );

              this.debug(confirmError);
              this.payLoading = false;
              await this.$router.push({ name: 'payment-failed' });

              ev.complete('fail');
            } else {
              ev.complete('success');

              if (paymentIntent.status === 'requires_action') {
                const { error } = await this.stripe.confirmCardPayment(secret);
                if (error) {
                  this.$amplitude.event(
                    `${this.eventTrackerNamePrefix}_`
                    + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
                    { secret, paymentIntent, ...this.amplitudePaymentData },
                  );

                  this.debug(error);
                  this.payLoading = false;
                  await this.$router.push({ name: 'payment-failed' });

                  return;
                }
              }

              this.paymentIntentId = paymentIntentId;

              this.$amplitude.event(
                `${this.eventTrackerNamePrefix}_`
                + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_SUCCESS`,
                { secret, paymentIntent, ...this.amplitudePaymentData },
              );

              this.sendConfirm({ paymentIntentId });

              this.payLoading = false;

              if (this.showModalSorry) {
                this.modalSorry = () => {
                  return this.sendPhone({ paymentId: paymentIntentId });
                };
              } else {
                this.modalStatus = 'success';
              }
            }
          } catch (e) {
            this.$amplitude.event(
              `${this.eventTrackerNamePrefix}_`
              + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_FAILED`,
              this.amplitudePaymentData,
            );

            this.debug(e);
            this.payLoading = false;
            await this.$router.push({ name: 'payment-failed' });
          }
        });

        this.stripePaymentRequest.on('cancel', () => {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_`
            + `${this.applePay ? 'APPLE_PAY' : 'GOOGLE_PAY'}_CANCEL`,
            this.amplitudePaymentData,
          );

          this.payLoading = false;
        });
      } catch (e) {
        this.debug(e);
        this.payLoading = false;
        this.$router.push({ name: 'payment-failed' });
      }
    },
    async submitPayByCard() {
      this.cardLoading = true;

      const {
        feeChecked,
        currency,
        amount,
        feeAmount,
        groupId,
        workplacePayoutId,
        ...data
      } = this.getApiPaymentData();

      let tempListPayment = [];

      if (this.isMultiPayPage) {
        tempListPayment = data.listPayment;
      } else {
        tempListPayment = [{
          payoutId: data.payoutId,
          comment: data.comment,
          stars: data.stars,
          reviews: data.reviews,
          amount,
        }];
      }

      try {
        const {
          data: {
            sessionId,
            tipsUrl,
          },
        } = await api.createPayment({
          listPayment: tempListPayment,
          amount,
          currency,
          feeAmount,
          feeChecked,
          groupId,
          workplacePayoutId,
          paymentMethod: 'CARD',
          successUrl: this.successUrl,
          errorUrl: this.errorUrl,
          apiVersion: this.isUaeAndStripe ? 6 : 4,
        });

        if (sessionId) {
          this.$amplitude.event(`${this.eventTrackerNamePrefix}_PAY_BY_CARD_REDIRECT`, {
            ...this.amplitudePaymentData,
            sessionId,
          });

          if (tipsUrl) {
            window.location.href = tipsUrl;
          } else {
            this.stripe.redirectToCheckout({ sessionId });
          }

          return;
        }
      } catch (e) {
        this.debug(e);
      } finally {
        this.cardLoading = false;
      }

      this.$amplitude.event(
        `${this.eventTrackerNamePrefix}_PAY_BY_CARD_WRONG_SESSION`,
        this.amplitudePaymentData,
      );

      await this.$router.push({ name: 'payment-failed' });
    },
    async submitPayermaxPayByCard() {
      const {
        kycProcessed,
        phoneNumber,
        listFeedback,
        amount,
        totalAmount,
        feeAmount,
        feeChecked,
        ...data
      } = this.getApiPaymentData();

      let queryData = {
        ...data,
        amount,
        totalAmount,
        feeAmount,
        feeChecked,
        partnerId: this.partnerId,
        locationName: this.locationName,
        isCommon: this.isCommon,
        staff: JSON.stringify(this.staff),
        staffPayoutId: this.staffPayoutId[0],
        currencyIso: this.currency.iso,
        currencyLabel: this.currency.label,
        currencyRightPosition: +this.currency.rightPosition,
        listPayment: JSON.stringify(data.listPayment
          ? data.listPayment
          : []),
        reviews: JSON.stringify(data.reviews
          ? data.reviews
          : []),
        backUrl: JSON.stringify({
          rate: this.form.rate,
          locationId: this.$route.params.locationPayoutId,
          query: this.$route.query,
          payermaxPayment: this.isPayermaxPayment,
          amount: totalAmount,
        }),
      };

      if (this.isQrBillPayment) {
        if (this.totalSum) {
          queryData = {
            ...queryData,
            totalTipsFee: totalAmount,
            totalAmount: this.billAmount,
            amount,
            feeAmount,
            feeChecked,
          };
        } else {
          queryData = {
            ...queryData,
            totalTipsFee: 0,
            totalAmount: this.billAmount,
            amount: this.billAmount,
            feeAmount,
            feeChecked: Boolean(feeAmount),
          };
        }

        queryData = {
          ...queryData,
          billId: this.billId,
          backUrl: JSON.stringify({
            ...JSON.parse(queryData.backUrl),
            customerEmail: this.billCustomerInfo?.email,
          }),
          billCustomerInfo: JSON.stringify(this.billCustomerInfo),
        };
      }

      this.$router.push({
        name: 'payermax',
        query: queryData,
      });
    },
    submitCheckoutPayByCard() {
      const {
        feeAmount,
        kycProcessed,
        phoneNumber,
        totalAmount,
        listFeedback,
        ...data
      } = this.getApiPaymentData();

      this.$router.push({
        name: 'checkout',
        query: {
          ...data,
          partnerId: this.partnerId,
          locationName: this.locationName,
          isCommon: this.isCommon,
          staff: JSON.stringify(this.staff),
          staffPayoutId: this.staffPayoutId[0],
          currencyIso: this.currency.iso,
          currencyLabel: this.currency.label,
          currencyRightPosition: +this.currency.rightPosition,
          listPayment: JSON.stringify(data.listPayment
            ? data.listPayment
            : []),
          reviews: JSON.stringify(data.reviews
            ? data.reviews
            : []),
          backUrl: JSON.stringify({
            rate: this.form.rate,
            locationId: this.$route.params.locationPayoutId,
            query: this.$route.query,
            checkoutPayment: this.isCheckoutPayment,
            amount: totalAmount,
          }),
        },
      });
    },
    async submitGiroPay() {
      this.giroPayLoading = true;

      try {
        const {
          data: {
            secret,
          },
        } = await api.createGiroPayPayment({
          ...(this.getGiroPayPaymentData()),
          successUrl: this.successUrl,
          errorUrl: this.errorUrl,
        });

        if (secret) {
          await this.stripe.confirmGiropayPayment(
            secret,
            {
              payment_method: {
                billing_details: {
                  name: this.staffNames || 'NO NAME',
                },
              },
              return_url: this.giroPayUrl,
            },
          );

          return;
        }
      } catch (e) {
        this.debug(e);
      } finally {
        this.giroPayLoading = false;
      }

      await this.$router.push({ name: 'payment-failed' });
    },
    async handleGiroPayStatus(amplitudePaymentData) {
      try {
        const response = await this.stripe.retrievePaymentIntent(this.paymentIntentClientSecret);

        if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
          if (!this.showModalSorry) {
            this.modalStatus = 'success';
          }

          this.cardPay = true;

          const {
            paymentIntent,
          } = this;

          this.sendGiroPayConfirm({ paymentIntentId: paymentIntent });

          if (this.showModalSorry) {
            this.modalSorry = () => {
              return this.sendPhone({ paymentId: paymentIntent });
            };
          } else {
            this.modalStatus = 'success';

            this.$amplitude.event(
              `${this.eventTrackerNamePrefix}_GIROPAY_SUCCESS`,
              amplitudePaymentData,
            );
          }
        } else {
          this.$amplitude.event(
            `${this.eventTrackerNamePrefix}_GIROPAY_ERROR`,
            amplitudePaymentData,
          );

          await this.$router.push({ name: 'payment-failed' });
        }
      } catch (e) {
        this.debug(e);
      }
    },
    async checkPayPalPayment(data) {
      try {
        await api.checkoutPaymentConfirm(data);
      } catch (e) {
        this.debug(e);
        this.modalStatus = 'error';
      }
    },
    onStaffOpened({ payoutId }) {
      this.$amplitude.event('SALON_STAFF_SELECT', {
        payoutId,
        ...this.multiplePayEventsInfo,
      });
    },
    onGroupOpened({ payoutId }) {
      this.$amplitude.event('SALON_GROUP_SELECT', {
        payoutId,
        ...this.multiplePayEventsInfo,
      });
    },
    onSubLocationOpened({ payoutId }) {
      this.$amplitude.event('SUB_LOCATION_SELECT', { payoutId });
    },
    applePayButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_APPLE_PAY_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_APPLE_PAY_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    googlePayButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_GOOGLE_PAY_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_GOOGLE_PAY_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    payByCardButtonAmplitudeEvent(show, trueEvent, falsyEvent) {
      if (show) {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_PAY_BY_CARD_${trueEvent}`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_PAY_BY_CARD_${falsyEvent}`,
          this.amplitudePaymentData,
        );
      }
    },
    isUserSelectedById(id) {
      return this.selectedSpecialists.findIndex((elem) => elem.payoutId === id) !== -1;
    },
    selectSpecialist({ staff }) {
      const status = !this.isUserSelectedById(staff.payoutId);
      if (status) {
        this.selectedSpecialists.push(staff);
      } else {
        this.selectedSpecialists = this.selectedSpecialists.filter((localStaff) => localStaff.payoutId !== staff.payoutId);
      }
    },
  },

  mounted() {
    const unwatchPayButtons = this.$watch(() => this.showSubmit && !this.onlyFeedback && this.canPay, (shown) => {
      if (this.applePay) {
        this.applePayButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');
      }

      if (this.googlePay) {
        this.googlePayButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');
      }

      if (unwatchPayButtons) {
        unwatchPayButtons();
      }
    });

    const unwatchPayByCard = this.$watch(() => this.showSubmit && !this.onlyFeedback, (shown) => {
      this.payByCardButtonAmplitudeEvent(shown, 'SHOWN', 'HIDDEN');

      if (unwatchPayByCard) {
        unwatchPayByCard();
      }
    });
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

@function home-gradient($offset) {
  @return linear-gradient(#fff $offset, #fcb77b $offset, #a01212 100%);
}

.home {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__staff_card,
  &__location_card {
    margin-bottom: 20px;
  }

  &_multipay {
    .home {
      &__user-name {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
      }
      &__user-amount {
        .user-amount__input {
          margin-bottom: 0;
        }
        input {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;

          &::placeholder {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      &__user-comment-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        color: #333333;
      }
    }
  }

  &__bill {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-location-name,
    &-info,
    &-staff-list {
      margin-bottom: 20px;
    }

    &-location-name {
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      margin-top: 15px;
    }

    &-tips,
    &-feedback-payment {
      margin-top: 0 !important;
    }

    &-tips {
      margin: 0;
      margin-bottom: 20px;

      &-title {
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
      }

      &-fee {
        margin-top: 10px;
      }
    }

    &-feedback {
      margin-bottom: 40px;
    }

    &-total-price {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }

  &__body {
    max-width: $max-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__search-title {
    margin: 20px auto 40px;
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    color: $text;
  }
  &__multiple-switcher {
    margin: 0 auto 36px;
    display: flex;
    align-self: flex-start;
  }
  &__search-filter {
    margin-bottom: 20px;
    width: 100%;
  }
  &__search-staff,
  &__search-groups,
  &__search-sub-locations {
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;

    & + & {
      margin-top: 12px;
    }
  }

  &__user-name {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 6px;
    margin-top: 12px;
    text-align: center;
  }

  &__total-amount {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 14px;
  }

  &__user-amount-fees {
    margin-bottom: 40px;
  }

  &__user-location {
    font-size: 14px;
    margin: 0 0 20px 0;
    font-weight: 500;
    color: $gray-1;
  }

  &__tip-selector {
    margin-top: 22px;
  }

  &__tips {
    margin-bottom: 20px;
  }

  &__user-reviews {
    margin-top: 20px;
  }

  &__user-comment {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 20px;

    & .ui-input__input {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 12px;
    }

    & .ui-input__clear {
      top: 0;
    }
  }

  &__user-rates + &__user-amount {
    margin-top: 32px;
  }

  &__user-buttons {
    margin-top: 20px;

    .ui-button + .ui-button {
      margin-top: 12px;
    }
  }

  .ui-card + .ui-card {
    margin-top: 17px;
  }

  &__total-price {
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }

  &__tip-selector-card {
    &__title {
      @include block-caption;
    }
  }
}

.user-staff_list {
  margin-top: 40px;

  &:first-child {
    margin-top:  0;
  }
}
</style>
