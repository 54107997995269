<template>
  <ui-card>
    <div class="bill-staff-list-selected">
      <div class="bill-staff-list-selected__title">
        I’d like to leave a tip for
      </div>

      <div class="bill-staff-list-selected__items">
        <user-staff
          v-for="item in filteredStaffList"
          :key="item.payoutId"
          :staff="item"
          transparent
          multipay
          avatar-shadow
        >
          <user-amount
            :value="item.amount"
            :feeChecked.sync="data.feeChecked"
            :currency="data.currency"
            :payout-id="data.locationPayoutId"
            hide-fees
            :api-version="data.apiVersion"
            :min-tips-amount="data.minTipsAmount"
            show-error-anyway
            class="bill-staff-list-selected__item"
            @fee-opened="$emit('on-fee-opened')"
            @update:feeChecked="$emit('fee-checked', $event)"
            @update:error="$emit('error')"
            @update:fee-loading="$emit('fee-loading')"
            @update:typing="$emit('typing')"
            @update:value="(amount) => handleUpdate(amount, item.payoutId)"
          />
        </user-staff>
      </div>

      <user-amount
        v-if="showFee"
        class="bill-staff-list-selected__fee"
        :value="data.amountSum"
        :feeChecked="data.feeChecked"
        :fee-amount.sync="feeAmountSumValue"
        :currency="data.currency"
        :payout-id="data.locationPayoutId"
        hide-input
        :api-version="data.apiVersion"
        :min-tips-amount="data.minTipsAmount"
        @fee-opened="$emit('on-fee-opened')"
        @update:feeChecked="$emit('fee-checked', $event)"
        @update:error="$emit('error')"
        @update:fee-loading="$emit('fee-loading')"
      />

      <ui-button
        class="bill-staff-list-selected__button"
        @click="$emit('submit')"
      >
        Add or remove staff
      </ui-button>
    </div>
  </ui-card>
</template>
<script setup>
import {
  ref,
  computed,
  watch,
} from 'vue';
import UiCard from '@/components/UiCard.vue';
import UiButton from '@/components/UiButton.vue';
import UserStaff from '@/components/UserStaff.vue';
import UserAmount from '@/components/UserAmount.vue';

const props = defineProps({
  items: Array,
  data: Object,
});

const emit = defineEmits([
  'submit',
  'on-fee-opened',
  'typing',
  'error',
  'fee-checked',
  'fee-loading',
  'fee-amount',
  'update',
]);

const feeAmountSumValue = ref();
const localStaffList = ref([]);

const filteredStaffList = computed(() => {
  return localStaffList.value.filter(({ selected }) => selected);
});

const showFee = computed(() => {
  return props.data.amountSum !== 0;
});

const handleUpdate = (amount, payoutId) => {
  const index = localStaffList.value.findIndex((item) => item.payoutId === payoutId);

  if (index !== -1) {
    localStaffList.value[index].amount = amount;
    emit('update', localStaffList.value);
  }
};

watch(feeAmountSumValue, (value) => {
  emit('fee-amount', value);
});

watch(() => props.items, (value) => {
  localStaffList.value = value;
}, { immediate: true });
</script>
