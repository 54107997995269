<template>
  <div
    class="ui-switcher-review"
    :class="{
      'ui-switcher-review_positive': model,
      'ui-switcher-review_negative': model === 0,
    }"
    v-touch:swipe="handleSwipe"
  >
    <div
      class="ui-switcher-review__part_negative"
      @click="changeValue(1)"
    >
      <img
        v-if="model !== 0"
        class="ui-switcher-review__like"
        src="@/assets/like.svg"
        alt=""
      >

      <div class="ui-switcher-review_negative_content" v-if="model === 0">
        <img class="ui-switcher-review_negative_icon" src="@/assets/dislike-white.svg" alt="">

        <div class="ui-switcher-review_negative_label">
          Dislike!
        </div>
      </div>
    </div>

    <div
      class="ui-switcher-review__part_positive"
      @click="changeValue(0)"
    >
      <img
        v-if="model !== 1"
        class="ui-switcher-review__dislike"
        src="@/assets/dislike.svg"
        alt=""
      >

      <div class="ui-switcher-review_positive_content" v-if="model === 1">
        <img class="ui-switcher-review_positive_icon" src="@/assets/like-white.svg" alt="">

        <div class="ui-switcher-review_positive_label">
          Like!
        </div>
      </div>
    </div>

    <div
      class="ui-switcher-review__switcher"
      @click="toggleValue"
    >
      <div class="ui-switcher-review__switcher_content">
        <!--        <img-->
        <!--          class="ui-switcher-review__switcher_icon"-->
        <!--          src="@/assets/reviews/service.svg"-->
        <!--          alt=""-->
        <!--        >-->

        <img
          class="ui-switcher-review__switcher_icon"
          :src="icon"
          alt=""
        >

        <div class="ui-switcher-review__switcher_label">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  icon: { type: String },
  label: { type: String },
});

const emit = defineEmits(['change']);

const model = ref(null); // null 1 0

const changeValue = (selectedValue) => {
  model.value = selectedValue;

  emit('change', model.value);
};

const toggleValue = () => {
  switch (model.value) {
    case 0:
      model.value = null;

      break;
    case 1:
      model.value = 0;

      break;
    case null:
      model.value = 1;
  }

  emit('change', model.value);
};

const handleSwipe = (payload) => {
  if (payload === 'bottom') {
    model.value = 0;
  }

  if (payload === 'top') {
    model.value = 1;
  }

  emit('change', model.value);
};
</script>

<style scoped>

</style>
