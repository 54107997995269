import getBrowserLocale from '@/utils/getBrowserLocale';
import i18n from '@/plugins/i18n';
import storage from '@/plugins/storage';
import factory from '../factory';

/**
 * Save locale in i18n and storage.
 *
 * @param {string} value
 */
function setLocale(value) {
  i18n.global.locale = value;
  storage.set('locale', value);
}

/**
 * Exists specified locale in i18n plugin.
 *
 * @param  {string} value
 * @return {boolean}
 */
function isI18nDefined(value) {
  return value && i18n.global.availableLocales.includes(value);
}

/**
 * Locale initialization.
 *
 * @return {string}
 */
function getDefaultLocale() {
  const currentBrowserLocale = getBrowserLocale();
  const browserLocale = storage.get('browser-locale');

  let locale = storage.get('locale');
  if (browserLocale !== currentBrowserLocale) {
    storage.set('browser-locale', currentBrowserLocale);
    locale = currentBrowserLocale;
  }

  if (!isI18nDefined(locale)) {
    return 'en';
  }

  return locale;
}

export default factory({

  namespaced: true,

  state() {
    const locale = getDefaultLocale();

    setLocale(locale);

    return {
      locale,
      locales: ['en', 'de'],
    };
  },

  mutations: {
    setLocale(state, payload) {
      if (!isI18nDefined(payload)) {
        return;
      }

      setLocale(payload);
      state.locale = payload;
    },
  },
});
