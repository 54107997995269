<template>
  <label
    class="ui-checkbox"
    :class="{
      'ui-checkbox_small': small,
      'ui-checkbox_black': black,
      'ui-checkbox_red': red,
      'ui-checkbox_rounded': rounded,
      'ui-checkbox_disabled': disabled,
    }"
    :for="uniqueId"
  >
    <input
      v-bind="$attrs"
      :id="uniqueId"
      :checked="value === trueValue"
      :true-value="trueValue"
      :false-value="falseValue"
      :disabled="disabled"
      type="checkbox"
      class="ui-checkbox__input"
      @input="$emit('input', $event.target.checked ? trueValue : falseValue)"
    />
    <div class="ui-checkbox__box" @click.stop>
    <!--
      Temporary solution
      Problem with displaying a large number of rounded sprite icons on smartphones(Safari/Chrome)
    -->
      <img v-if="rounded" src="@/assets/check.svg">
      <ui-icon
        v-else
        name="check"
        class="ui-checkbox__icon"
      />
    </div>
    <div class="ui-checkbox__body">
      <slot></slot>
    </div>
  </label>
</template>

<script>
import { uniqueId } from 'lodash';
import UiIcon from './UiIcon.vue';

export default {

  inheritAttrs: false,

  components: {
    UiIcon,
  },

  props: {
    value: null,
    small: Boolean,
    black: Boolean,
    red: Boolean,
    rounded: Boolean,
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      uniqueId: uniqueId('ui-checkbox-'),
    };
  },
};
</script>

<style lang="scss">
.ui-checkbox {
  display: flex;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.29;

  &__box {
    margin: auto 0;
    min-width: 22px;
    height: 22px;
    border: 2px solid #fff;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__input {
    z-index: -1;
    opacity: 0;
    width: 0;
  }

  &__input:checked ~ &__box {
    background-color: #fff;
  }

  &__input:not(:checked) ~ &__box &__icon {
    visibility: hidden;
  }

  &__icon {
    width: 14px;
    height: 10px;
    left: 1px;
    position: relative;
    color: #e14040;
  }

  &__body {
    padding-left: 20px;
    align-self: center;
  }

  &_black &__box {
    border-color: #808080;
  }

  &_red &__box {
    border-color: #A01212;
  }

  &_black &__input:checked ~ &__box {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
  }

  &_black &__icon {
    color: #fff;
  }

  &_black &__body {
    color: #000;
  }

  &_red &__body {
    color: #A01212;
  }

  &_small &__box {
    min-width: 14px;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border-width: 1px;
  }

  &_small &__icon {
    width: 8px;
    height: 6px;
    left: 0;
  }

  &_small &__body {
    font-weight: 400;
    padding-left: 9px;
    vertical-align: top;
    position: relative;
  }

  &_rounded {
    .ui-checkbox {
      &__box {
        border-radius: 50%;
        border-color: #808080;
        height: 24px;
        width: 24px;
      }
      &__input:checked ~ .ui-checkbox__box {
        background-color: $primary;
        border-color: $primary;
      }
      &__icon {
        color: white;
      }
    }
  }

  &_disabled {
    .ui-checkbox {
      &__box {
        cursor: default;
      }
    }
  }
}
</style>
