<template>
  <svg
    v-bind="$attrs"
    :class="[$data.staticClass || '', $data.class]"
    :width="$props.width || $props.size"
    :height="$props.height || $props.size"
    fill="currentColor"
  >
    <use :xlink:href="`${require(`@/assets/sprite.svg`)}#${$props.name}`" />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: null,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
