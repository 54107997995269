import * as Sentry from '@sentry/vue';
import request from './request';

const createPaymentSentryCapture = (config) => {
  const { url, data } = config;
  const { feeChecked, feeAmount } = data;

  if (feeChecked && +feeAmount === 0) {
    Sentry.captureException(new Error('Wrong fee payment'), (scope) => {
      scope.setContext('Api', {
        url,
        data,
      });
      return scope;
    });
  }

  return request.pay(config);
};

/**
 * Staff profile data.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getStaff = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/staffs/${payoutId}`,
});

/**
 * Avatar image.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getAvatar = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/code/image/${payoutId}`,
  responseType: 'blob',
});

/**
 * Partner avatar image.
 *
 * @param  {string} [payload.payoutId]
 * @param  {string} [payload.partnerId]
 * @param  {object} payload
 * @return {Promise}
 */
const getPartnerAvatar = (payload) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payload.payoutId}/partner/${payload.partnerId}/image/`,
  responseType: 'blob',
});

/**
 * Location info.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocation = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}`,
});

/**
 * Location active staff members.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocationStaffs = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/staffs`,
});

/**
 * Location groups.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */

const getLocationGroups = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/groups`,
});

/**
 * Sub locations.
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getSubLocations = (payoutId) => request.admin({
  baseURL: process.env.VUE_APP_API_ADMIN_URL,
  method: 'get',
  url: `/v1/public/workplaces/${payoutId}/sublocations`,
});

/**
 * Location reviews (compliments and issues).
 *
 * @param  {string} payoutId
 * @return {Promise}
 */
const getLocationReviews = (payoutId) => request.admin({
  method: 'get',
  url: `/v4/public/workplaces/${payoutId}/reviews`,
});

/**
 * Get fee amount for specified total tips.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const getLocationFee = ({ apiVersion, ...data }) => request.pay({
  method: 'post',
  url: `/v${apiVersion}/payment/fee`,
  data,
});

/**
 * Location group parameters.
 *
 * @param  {string} locationId
 * @param  {string} payoutId
 * @return {Promise}
 */
const getGroup = (locationId, payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/workplaces/${locationId}/groups/${payoutId}`,
});

/**
 * Send feedback comment without payout tips.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const sendFeedback = ({ apiVersion, ...data }) => request.pay({
  method: 'post',
  url: `/v${apiVersion}/feedback`,
  data,
});

/**
 * Create payment invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createPayment = ({ apiVersion, ...data }) => request.pay({
  method: 'post',
  url: `/v${apiVersion}/payment`,
  data,
});

/**
 * Create giro pay invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createGiroPayPayment = (data) => request.pay({
  method: 'post',
  url: '/v4/payment/giropay',
  data,
});

/**
 * Create apple / google pay invoice.
 *
 * @param  {Object} data
 * @param  {Number} version
 * @return {Promise}
 */
const createMobilePayment = ({ isApplePay, apiVersion, ...data }) => {
  if (apiVersion === 6) {
    return request.pay({
      method: 'post',
      url: isApplePay
        ? '/v6/payment/applepay'
        : '/v6/payment/googlepay',
      data,
    });
  }

  return request.pay({
    method: 'post',
    url: '/v4/payment/applepay',
    data,
  });
};

/**
 * Send payment confirm.
 *
 * @param  {Object} params
 * @param  {Number} version
 * @return {Promise}
 */
const confirmPayment = ({ apiVersion, ...params }) => {
  if (apiVersion === 6) {
    const { success, ...data } = params;
    const status = success ? 'success' : 'error';

    return request.pay({
      method: 'post',
      url: `/v6/payment/${status}`,
      params: data,
    });
  }

  return request.pay({
    method: 'post',
    url: '/v4/payment/confirm',
    params,
  });
};

/**
 * Send giro pay payment confirm.
 *
 * @param  {Object} params
 * @param  {Number} version
 * @return {Promise}
 */
const confirmGiroPayPayment = (params) => request.pay({
  method: 'post',
  url: '/v4/payment/confirm',
  params,
});

/**
 * Send receipt to email
 *
 * @param {Object} params
 * @return {Promise}
 */
const sendReceiptEmail = ({ apiVersion, paymentId, email }) => {
  if (apiVersion) {
    return request.pay({
      method: 'post',
      url: `/v${apiVersion}/payment/${paymentId}/receipt`,
      data: {
        email,
      },
    });
  }

  return request.pay({
    method: 'post',
    url: '/v3/payment/receipt',
    data: {
      paymentIntentId: paymentId,
      email,
    },
  });
};

/**
 * Get intent by session.
 *
 * @param  {string} sessionId
 * @param  {number} workplacePayoutId
 * @param  {number} version
 * @return {Promise}
 */
const getIntent = ({ apiVersion, sessionId, workplacePayoutId }) => request.pay({
  method: 'get',
  url: `/v${apiVersion}/payment/intentBySession`,
  params: {
    sessionId,
    workplacePayoutId,
  },
});

/**
 * Get stripe public key for specified location.
 *
 * @param  {Object} workplacePayoutId
 * @return {Promise}
 */
const getPublicKey = (workplacePayoutId) => request.admin({
  method: 'get',
  url: '/v1/public/publicKey',
  params: {
    workplacePayoutId,
  },
});

/**
 * Send `sorry` phone after complete payment.
 *
 * @param  {string} paymentId
 * @param  {string} phoneNumber
 * @param  {number} version
 * @return {Promise}
 */
const sendFeedbackPhone = ({ apiVersion, phoneNumber, feedbackId }) => {
  if (apiVersion) {
    return request.pay({
      method: 'post',
      url: `/v${apiVersion}/feedback/${feedbackId}/enrich`,
      data: {
        phoneNumber,
      },
    });
  }

  return request.pay({
    method: 'post',
    url: '/v4/feedback/enrich',
    params: {
      feedbackId,
    },
    data: {
      phoneNumber,
    },
  });
};

const sendPaymentPhone = ({ apiVersion, paymentId, phoneNumber }) => {
  if (apiVersion) {
    return request.pay({
      method: 'post',
      url: `/v${apiVersion}/payment/${paymentId}/enrich`,
      data: {
        phoneNumber,
      },
    });
  }

  return request.pay({
    method: 'post',
    url: '/v4/payment/enrich',
    params: {
      paymentIntentId: paymentId,
    },
    data: {
      phoneNumber,
    },
  });
};

const getSeoKey = (payoutId) => request.admin({
  method: 'get',
  url: `/v1/public/staffs/${payoutId}/seo`,
});

const getStaffTypes = () => request.admin({
  method: 'get',
  url: '/v1/public/handbook/staffTypes',
});

const getStaffTypesVersion = () => request.admin({
  method: 'get',
  url: '/v1/public/handbook/versions',
});

/**
 * Create checkout payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createCheckoutPayment = (data) => createPaymentSentryCapture({
  method: 'post',
  url: '/v5/payment',
  data,
});

/**
 * Get checkout payment fee.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getCheckoutPaymentFee = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/fee',
  data,
});

/**
 * Get checkout public key.
 *
 * @param {Object} params
 * @return {Promise}
 */
const getCheckoutPaymentPublicKey = (params) => request.admin({
  method: 'get',
  url: '/v1/public/payments/publicKey',
  params,
});

/**
 * Create Checkout ApplePay payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createCheckoutApplePayPayment = (data) => createPaymentSentryCapture({
  method: 'post',
  url: '/v5/payment/applepay',
  data,
});

/**
 * Validate Checkout ApplePay session.
 *
 * @param {Object} data
 * @return {Promise}
 */
const validateCheckoutAppleSession = (data) => request.pay({
  method: 'post',
  url: '/v5/payment/applepay/validateSession',
  data,
});

/**
 * Confirm checkout pay by card.
 *
 * @param {Object} params
 * @return {Promise}
 */
const checkoutPaymentConfirm = ({ status, ...params }) => request.pay({
  method: 'post',
  url: `/v5/payment/${status}`,
  params,
});

/**
 * Create Checkout GooglePay payment.
 *
 * @param {Object} params
 * @return {Promise}
 */
const createCheckoutGooglePayPayment = (data) => createPaymentSentryCapture({
  method: 'post',
  url: '/v5/payment/googlepay',
  data,
});

/**
 * Get checkout payment configuration.
 *
 * @return {Promise}
 */
const getCheckoutConfiguration = (params) => request.admin({
  method: 'get',
  url: '/v1/public/paymentConfiguration',
  params,
});

/**
 * Create payermax apple pay payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createPayermaxApplePayment = (data, isQrBillPayment) => createPaymentSentryCapture({
  method: 'post',
  url: isQrBillPayment
    ? '/v7/payment/apple/bill'
    : '/v7/payment/apple',
  data,
});

/**
 * Get payermax apple pay session.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getPayermaxAppleSession = (data, isQrBillPayment) => request.pay({
  method: 'post',
  url: isQrBillPayment
    ? '/v7/payment/apple/bill/session'
    : '/v7/payment/apple/session',
  data,
});

/**
 * Create payermax pay by card payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createPayermaxCardPayment = (data, isQrBillPayment) => createPaymentSentryCapture({
  method: 'post',
  url: isQrBillPayment
    ? '/v7/payment/card/bill'
    : '/v7/payment/card',
  data,
});

/**
 * Get payermax pay by card session.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getPayermaxCardSession = (data, isQrBillPayment) => request.pay({
  method: 'post',
  url: isQrBillPayment
    ? '/v7/payment/card/bill/session'
    : '/v7/payment/card/session',
  data,
});

/**
 * Confirm payermax pay by card.
 *
 * @param {Object} params
 * @return {Promise}
 */
const payermaxPaymentConfirm = ({ status, ...params }) => request.pay({
  method: 'post',
  url: `/v7/payment/${status}`,
  params,
});

/**
 * Create Ryft pay by card payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createRyftCardPayment = (data) => createPaymentSentryCapture({
  method: 'post',
  url: '/v8/payment/card',
  data,
});

/**
 * Get Ryft pay by card session.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getRyftCardSession = (data) => request.pay({
  method: 'post',
  url: '/v8/payment/card/session',
  data,
});

/**
 * Create paypal payment.
 *
 * @param {Object} data
 * @return {Promise}
 */
const createPaypalPayment = (data) => createPaymentSentryCapture({
  method: 'post',
  url: '/v5/payment/paypal',
  data,
});

/**
 * Get features.
 *
 * @param {Object} data
 * @return {Promise}
 */
const getFeatures = (workplacePayoutId) => request.admin({
  method: 'get',
  url: `/v4/public/workplaces/${workplacePayoutId}/features`,
});

const getMenuInfo = ({ workplaceId, ...params }) => request.admin({
  baseURL: process.env.VUE_APP_API_ADMIN_URL,
  method: 'get',
  url: `/v1/public/workplaces/${workplaceId}/menu`,
  params,
});

/**
 * Location bill.
 *
 * @param  {string} payoutId
 * @param  {string} qrId
 * @return {Promise}
 */
const getLocationBill = (payoutId, qrId) => request.admin({
  method: 'get',
  url: '/v1/public/bills',
  params: {
    payoutId,
    qrId,
  },
});

/**
 * Track rate show event.
 *
 * @return {Promise}
 * @param workplacePayoutId
 */
const trackRateShow = (workplacePayoutId) => request.pay({
  method: 'put',
  url: `/v4/public/rate/${workplacePayoutId}/show`,
});

/**
 * Track rate submit event.
 *
 * @return {Promise}
 * @param {number} workplacePayoutId
 * @param {string} provider
 */
const trackRateSubmit = (workplacePayoutId, provider) => request.pay({
  method: 'put',
  url: `/v4/public/rate/${workplacePayoutId}/rate`,
  params: {
    provider,
  },
});

export default {
  getStaff,
  getAvatar,
  getPartnerAvatar,
  getLocation,
  getLocationStaffs,
  getLocationGroups,
  getSubLocations,
  getLocationReviews,
  getLocationFee,
  getGroup,
  sendFeedback,
  createPayment,
  createGiroPayPayment,
  createMobilePayment,
  confirmPayment,
  confirmGiroPayPayment,
  getIntent,
  getPublicKey,
  sendFeedbackPhone,
  sendPaymentPhone,
  sendReceiptEmail,
  getSeoKey,
  getStaffTypes,
  getStaffTypesVersion,
  createCheckoutPayment,
  getCheckoutPaymentFee,
  getCheckoutPaymentPublicKey,
  createCheckoutApplePayPayment,
  validateCheckoutAppleSession,
  checkoutPaymentConfirm,
  createCheckoutGooglePayPayment,
  getCheckoutConfiguration,
  createPaypalPayment,
  getFeatures,
  getMenuInfo,
  createPayermaxApplePayment,
  getPayermaxAppleSession,
  createPayermaxCardPayment,
  getPayermaxCardSession,
  payermaxPaymentConfirm,
  getLocationBill,
  trackRateShow,
  trackRateSubmit,
  getRyftCardSession,
  createRyftCardPayment,
};
