<template>
  <ui-bottom-sheet
    :value="modelValue"
    :class-list="{
      'modal-tips__wrapper': true,
      'modal-tips__feedback-pan': !selectedTipId,
      [`modal-tips__${bottomSheetSize}`]: bottomSheetSize,
    }"
    :title="bottomSheetTitle"
    auto-overflow
    :swipeable="false"
    @input="handleClose"
    @opened="handleOpened"
  >
    <div class="modal-tips">
      <div class="modal-tips__title">
        <div
          v-if="isMulti"
          v-html="multiTipsTitle"
        />
        <template v-else-if="isCommon">
          You can also leave a tip to<br>{{ paymentButtonsData.name }}
        </template>
        <template v-else-if="isIndividual">
          You can also leave a tip for<br>{{ paymentButtonsData.name }}
        </template>
      </div>

      <div
        v-if="isMulti"
        class="modal-tips__multi-staff-avatar"
      >
        <div
          class="modal-tips__multi-staff-avatar-group"
          :class="[`modal-tips__multi-staff-avatar-group_${avatarCount.length}`]"
        >
          <user-avatar
            v-for="item in avatarCount"
            :key="item"
            :payout-id="item"
            size="large"
            borderless
          />
        </div>

        <div
          v-if="staffCount > MAX_STAFF_COUNT"
          class="modal-tips__multi-staff-avatar-count"
        >
          {{ otherStaffCountLabel }}
        </div>
      </div>

      <user-avatar
        v-else-if="isCommon"
        :payout-id="payoutId"
        class="modal-tips__location-avatar"
        :class="{ 'modal-tips__location-avatar_bordered': selectedTipId }"
        size="large"
        borderless
      />

      <user-avatar
        v-else-if="isIndividual"
        :payout-id="staff.payoutId"
        class="modal-tips__staff-avatar"
        :class="{ 'modal-tips__location-avatar_bordered': selectedTipId }"
        size="large"
        borderless
      />

      <ui-stars
        class="modal-tips__stars"
        :value="rate"
        :max="5"
      />

      <ui-tips
        class="modal-tips__tip-buttons"
        :ab-variant="abVariant"
        :tips="filteredTips"
        :currency="currency"
        :selected-tip-id="selectedTipId"
        :auto-select="false"
        @select="handleTipSelect"
      />

      <!-- Multi Fee -->
      <user-amount
        v-if="isMulti && selectedTipId"
        class="modal-tips__fees"
        :value="amountSum"
        :fee-checked="feeCheckedValue"
        :fee-amount.sync="multiFeeAmountValue"
        :currency="currency"
        :payout-id="payoutId"
        hide-input
        :api-version="isCheckoutPayment ? 5 : 4"
        :min-tips-amount="minTipsAmount"
        @fee-opened="$emit('fee-opened')"
        @update:fee-checked="handleFeeChecked"
        @update:error="$emit('amount-error')"
        @update:fee-loading="$emit('fee-loading')"
      />

      <!-- Individual/Common Fee -->
      <user-amount
        v-else-if="!isMulti && selectedTipId"
        class="modal-tips__fees"
        :value="amount"
        :fee-checked="feeCheckedValue"
        :fee-amount.sync="multiFeeAmountValue"
        :currency="currency"
        :payout-id="payoutId"
        hide-input
        :api-version="isCheckoutPayment ? 5 : 4"
        :min-tips-amount="minTipsAmount"
        @fee-opened="$emit('fee-opened')"
        @update:fee-checked="handleFeeChecked"
        @update:error="$emit('amount-error')"
        @update:fee-loading="$emit('fee-loading')"
      />

      <payment-buttons
        v-if="selectedTipId"
        class="modal-tips__payment-buttons"
        :data="paymentButtonsData"
        no-offset
        @submit="handleSubmit"
      />

      <ui-button
        class="modal-tips__skip-button"
        link
        small
        @click="handleSkip"
      >
        I’ll leave a tip next time
      </ui-button>
    </div>
  </ui-bottom-sheet>
</template>

<script setup>
import { ref, computed, watch, toRefs } from 'vue';
import UserAvatar from '@/components/UserAvatar.vue';
import UserAmount from '@/components/UserAmount.vue';
import PaymentButtons from '@/components/PaymentButtons.vue';
import UiTips from '@/components/UiTips.vue';
import UiStars from '@/components/UiStars.vue';
import UiButton from '@/components/UiButton.vue';
import UiBottomSheet from '@/components/UiBottomSheet.vue';

const MAX_STAFF_COUNT = 4;

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  tips: Array,
  rate: Number,
  payoutId: String,
  abVariant: String,
  currency: String,
  multiFeeAmount: String,
  staffPayoutId: Number,
  minTipsAmount: {
    type: Number,
    default: 0.5,
  },
  staff: {
    type: Object,
    required: true,
  },
  paymentButtonsData: {
    type: Object,
    required: true,
  },
  isCommon: {
    type: Boolean,
    default: false,
  },
  isIndividual: {
    type: Boolean,
    default: false,
  },
  isMulti: {
    type: Boolean,
    default: false,
  },
  multiTipsTitle: {
    type: String,
    default: '',
  },
});

const {
  tips,
  modelValue,
  rate,
  currency,
  payoutId,
  abVariant,
  multiFeeAmount,
  staffPayoutId,
  minTipsAmount,
  paymentButtonsData,
  isCommon,
  isIndividual,
  isMulti,
} = toRefs(props);

const emit = defineEmits([
  'input',
  'select',
  'submit',
  'skip',
  'fee-amount',
  'fee-checked',
  'fee-opened',
  'fee-value',
  'fee-loading',
  'amount-error',
  'multi-fee-checked',
  'single-fee-checked',
  'opened',
  'closed',
]);

const card = ref();
const content = ref();
const pan = ref();
const bar = ref();

const currentPaymentMethod = ref('');
const multiFeeAmountValue = ref();
const feeCheckedValue = ref(true);
const selectedTip = ref(null);

const filteredTips = computed(() => {
  return tips.value.slice(0, tips.value.length - 1);
});

const staffCount = computed(() => {
  return staffPayoutId.value.length;
});

const otherStaffCountLabel = computed(() => {
  return `+${staffCount.value - MAX_STAFF_COUNT} others`;
});

const amount = computed(() => {
  return selectedTip.value?.value || 0;
});

const amountSum = computed(() => {
  return amount.value * staffCount.value;
});

const selectedTipId = computed(() => {
  return selectedTip.value?.id;
});

const avatarCount = computed(() => {
  if (staffCount.value > MAX_STAFF_COUNT) {
    return staffPayoutId.value.slice(0, MAX_STAFF_COUNT);
  }

  return staffPayoutId.value;
});

const bottomSheetSize = computed(() => {
  if (staffCount.value > MAX_STAFF_COUNT && selectedTipId.value) {
    return 'extra-large';
  }

  if (staffCount.value < MAX_STAFF_COUNT && selectedTipId.value) {
    return 'large';
  }

  if (staffCount.value > MAX_STAFF_COUNT && !selectedTipId.value) {
    return 'medium';
  }

  return 'small';
});

const bottomSheetTitle = computed(() => {
  if (['small', 'medium'].includes(bottomSheetSize.value)) {
    return 'Thank you for your <b>feedback!</b>';
  }

  return '';
});

const handleFeeChecked = (payload) => {
  feeCheckedValue.value = payload;

  if (isMulti.value) {
    emit('multi-fee-checked', payload);
  } else {
    emit('single-fee-checked', payload);
  }
};

const handleTipSelect = (tip) => {
  selectedTip.value = tip;
  emit('select', tip);
};

const handleSubmit = (payload) => {
  currentPaymentMethod.value = payload;
  emit('submit', payload);
};

const handleSkip = () => {
  emit('select', {});
  emit('skip');
  emit('input', false);
};

const handleClose = () => {
  const methods = ['submitCheckoutPayByCard', 'submitPayByCard', 'submitPay'];

  if (methods.includes(currentPaymentMethod.value)) {
    return;
  }

  selectedTip.value = null;

  emit('select', {});
  emit('input', false);
  emit('closed');
};

const handleOpened = () => {
  emit('opened');
};

watch(multiFeeAmount, (value) => {
  multiFeeAmountValue.value = value;
});

watch(multiFeeAmountValue, (value) => {
  emit('fee-amount', value);
});
</script>

<style lang="scss">
.modal-tips {
  min-height: 100%;
  font-family: 'RobotoRegular', sans-serif;
  font-style: normal;

  &__wrapper {
    .ui-bottom-sheet {
      height: 100%;
      max-height: 87vh;
      justify-content: flex-start;

      &__body {
        max-height: fit-content;
        padding: 0 24px 20px;
      }
    }
  }

  &__extra-large,
  &__extra-large .bottom-sheet__content {
    height: 660px !important;
  }

  &__large,
  &__large .bottom-sheet__content {
    height: 643px !important;
  }

  &__medium,
  &__small {
    .ui-bottom-sheet__body {
      padding: 20px 24px 20px;
    }

    .modal-tips {
      padding: 0 0 30px;
    }
  }

  &__medium,
  &__medium .bottom-sheet__content {
    height: 525px !important;
  }

  &__small,
  &__small .bottom-sheet__content {
    height: 490px !important;
  }

  &__feedback-pan {
    .bottom-sheet__pan {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 65px;
      border-radius: 14px 14px 0 0;
      background-image: url('@/assets/modal-tips.png');
      background-repeat: repeat-x;
      background-size: cover;
      background-position-x: 1%;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 14px 14px 0 0;
        background: rgba(0, 0, 0, 0.15);
        z-index: 2;
      }

      @media screen and (min-width: 680px) {
        background-size: contain;
      }
    }

    .bottom-sheet__bar {
      background: #D9D9D9;
      z-index: 3;
    }

    .bottom-sheet__title {
      font-family: 'RobotoRegular', sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      z-index: 3;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }

  &__location-avatar,
  &__staff-avatar {
    margin: 0 auto 16px;
    border: 4px solid transparent;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);

    &_bordered {
      border: 4px solid #EF3024;
    }
  }

  &__multi-staff-avatar {
    margin: 0 auto 16px;
  }

  &__multi-staff-avatar-group {
    max-width: 327px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 8px;

    .user-avatar__image {
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &__multi-staff-avatar-group_2 {
    .user-avatar:nth-child(1) { transform: translate(10%, 0); }
    .user-avatar:nth-child(2) { transform: translate(-10%, 0); }
  }

  &__multi-staff-avatar-group_3 {
    .user-avatar:nth-child(1) { transform: translate(30%, 0); }
    .user-avatar:nth-child(2) { transform: translate(0, 0); }
    .user-avatar:nth-child(3) { transform: translate(-30%, 0); }
  }

  &__multi-staff-avatar-group_4 {
    .user-avatar:nth-child(1) { transform: translate(80%, 0); }
    .user-avatar:nth-child(2) { transform: translate(20%, 0); }
    .user-avatar:nth-child(3) { transform: translate(-22%, 0); }
    .user-avatar:nth-child(4) { transform: translate(-80%, 0); }
  }

  &__multi-staff-avatar-count {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #555555;
    text-align: center;
    margin-bottom: 20px;
  }

  &__stars {
    margin-bottom: 25px;
  }

  &__tip-buttons {
    max-width: 232px;
    margin: 0 auto 20px;

    .ui-tip {
      max-width: 64px;
      max-height: 64px;
      flex-basis: 30%;
    }
  }

  &__fees .fee {
    justify-content: center;
    margin: 30px 0 20px;
  }

  &__payment-buttons {
    max-width: 370px;
  }

  &__skip-button {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #EF3024;
    text-decoration: underline;
    margin: 15px auto 20px;
  }
}
</style>
