import api from '@/api';

import factory from '../factory';

export default factory({

  namespaced: true,

  actions: {
    async trackRateShow({ rootState }) {
      try {
        const locationPayoutId = rootState.user?.locationPayoutId;

        if (locationPayoutId) {
          await api.trackRateShow(locationPayoutId);
        }
      } catch (e) {
        console.log(e);
      }
    },

    async trackRateSubmit({ rootState }, provider) {
      try {
        const locationPayoutId = rootState.user?.locationPayoutId;

        if (locationPayoutId) {
          await api.trackRateSubmit(locationPayoutId, provider);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
});
