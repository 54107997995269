import { defineRule } from 'vee-validate';
import {
  required,
  confirmed,
  min,
  max,
  numeric,
  email,
} from '@vee-validate/rules';

defineRule('required', (value, params) => {
  if (Array.isArray(params) ? params[0] : params?.invalid) {
    return false;
  }

  return required(value);
});

defineRule('confirmedPassword', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('email', email);
