<template>
  <ui-card class="bill-customer-info-form">
    <form
      class="form"
      @submit.prevent="onSubmit"
    >
      <div class="bill-customer-info-form__title">
        Enter your details
      </div>

      <ui-base-input
        v-model="form.firstName"
        label="First name"
        class="form__field"
        trim
        capitalized
        required
        validation-rules="required"
        validation-mode="lazy"
      />

      <ui-base-input
        v-model="form.lastName"
        label="Last name"
        class="form__field"
        trim
        capitalized
        required
        validation-rules="required"
        validation-mode="lazy"
      />

      <ui-base-input
        v-model="form.email"
        label="Email"
        class="form__field"
        trim
        required
        validation-rules="required|email"
        validation-mode="lazy"
      />

      <ui-input-phone
        v-model="phoneNumberValue"
        class="form__field"
        required
        :error="phoneNumber.errorMessage.value"
        @input="phoneNumber.handleChange($event, false)"
      />

      <ui-button
        class="bill-customer-info-form__button"
        type="submit"
        :is-loading="isLoading"
        :disabled="isLoading"
      >
        Next
      </ui-button>
    </form>
  </ui-card>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useForm, useField } from 'vee-validate';
import { isEmpty } from 'lodash';
import UiCard from '@/components/UiCard.vue';
import UiBaseInput from '@/components/UiBaseInput.vue';
import UiInputPhone from '@/components/UiInputPhone.vue';
import UiButton from '@/components/UiButton.vue';

const emit = defineEmits(['completed']);

const isLoading = ref(false);

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
});

const phoneNumberValue = ref('');

const { validate, errors } = useForm(form.value);
const phoneNumber = useField('phoneNumber', 'required', {
  modelPropName: 'value',
  validateOnValueUpdate: false,
  validateOnMount: false,
  syncVModel: false,
});

const isInvalid = computed(() => {
  return !isEmpty(errors.value);
});

const onSubmit = async () => {
  await validate();
  await phoneNumber.validate();

  if (isInvalid.value) {
    return;
  }

  emit('completed', {
    ...form.value,
    phoneNumber: phoneNumberValue.value,
  });
};

watch(() => form.value.phoneNumber, () => {
  validate();
}, { immediate: false });
</script>
