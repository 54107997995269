import { AbilityBuilder, createMongoAbility, Ability } from '@casl/ability';

const {
  can, build, rules,
} = new AbilityBuilder(createMongoAbility);

export function updateAbilities(list, ability) {
  list.forEach(({ externalId }) => {
    can(externalId);
  });

  ability.update(rules);
}

export default build();
