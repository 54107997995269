import { computed } from 'vue';
import { useStore } from 'vuex';

export function useEventTrackerMixin() {
  const store = useStore();

  const isStaff = computed(() => {
    return store.getters['user/isStaff'];
  });

  const eventTrackerNamePrefix = computed(() => {
    return isStaff.value ? 'STAFF' : 'RESTAURANT';
  });

  return {
    eventTrackerNamePrefix,
  };
}
