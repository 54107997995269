<template>
  <div class="dialogue-card">
    <div
      class="dialogue-card__image"
    >
      <slot name="image" />
    </div>
    <div v-if="title || description" class="dialogue-card__info">
      <div v-if="title" class="dialogue-card__title">
        {{ title }}
      </div>
      <div v-if="description" class="dialogue-card__description">
        {{ description }}
      </div>
    </div>
    <ui-button v-if="actionText" class="dialogue-card__btn" small @click="$emit('action')">
      {{ actionText }}
    </ui-button>
<!--    <ui-socials class="dialogue-card__socials" />-->
  </div>
</template>

<script>
import UiSocials from '@/components/UiSocials.vue';
import UiButton from '@/components/UiButton.vue';

export default {
  name: 'DialogueCard',
  components: { UiSocials, UiButton },
  props: {
    actionText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.dialogue-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.18);
  border-radius: 12px;
  background-color: white;
  padding: 48px 20px 49px;
  box-sizing: border-box;
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__socials {
    width: 100%;
  }
  &__info {
    text-align: center;
    margin-top: 30px;
    > * + * {
      margin-top: 12px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
  &__description {
    font-size: 14px;
    line-height: 20px;
    color: #808080;
    margin: 12px 5px 0;
  }
  &__btn {
    margin-top: auto;
    white-space: nowrap;
  }
}
</style>
