export const COUNTRY_DEFAULT = 'gb';

export const COUNTRY_AVAILABLE = [
  'de',
  'us',
  'gb',
  'ae',
  'ch',
];

export const COUNTRY_CONFIG = [
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    iso: 'af',
    dialCode: '93',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Albania (Shqipëri)',
    iso: 'al',
    dialCode: '355',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    iso: 'dz',
    dialCode: '213',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'American Samoa',
    iso: 'as',
    dialCode: '1',
    priority: 5,
    areaCodes: [
      '684',
    ],
  },
  {
    name: 'Andorra',
    iso: 'ad',
    dialCode: '376',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Angola',
    iso: 'ao',
    dialCode: '244',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Anguilla',
    iso: 'ai',
    dialCode: '1',
    priority: 6,
    areaCodes: [
      '264',
    ],
  },
  {
    name: 'Antigua and Barbuda',
    iso: 'ag',
    dialCode: '1',
    priority: 7,
    areaCodes: [
      '268',
    ],
  },
  {
    name: 'Argentina',
    iso: 'ar',
    dialCode: '54',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Armenia (Հայաստան)',
    iso: 'am',
    dialCode: '374',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Aruba',
    iso: 'aw',
    dialCode: '297',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Australia',
    iso: 'au',
    dialCode: '61',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Austria (Österreich)',
    iso: 'at',
    dialCode: '43',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    iso: 'az',
    dialCode: '994',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bahamas',
    iso: 'bs',
    dialCode: '1',
    priority: 8,
    areaCodes: [
      '242',
    ],
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    iso: 'bh',
    dialCode: '973',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    iso: 'bd',
    dialCode: '880',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Barbados',
    iso: 'bb',
    dialCode: '1',
    priority: 9,
    areaCodes: [
      '246',
    ],
  },
  {
    name: 'Belarus (Беларусь)',
    iso: 'by',
    dialCode: '375',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Belgium (België)',
    iso: 'be',
    dialCode: '32',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Belize',
    iso: 'bz',
    dialCode: '501',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Benin (Bénin)',
    iso: 'bj',
    dialCode: '229',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bermuda',
    iso: 'bm',
    dialCode: '1',
    priority: 10,
    areaCodes: [
      '441',
    ],
  },
  {
    name: 'Bhutan (འབྲུག)',
    iso: 'bt',
    dialCode: '975',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bolivia',
    iso: 'bo',
    dialCode: '591',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    iso: 'ba',
    dialCode: '387',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Botswana',
    iso: 'bw',
    dialCode: '267',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Brazil (Brasil)',
    iso: 'br',
    dialCode: '55',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'British Indian Ocean Territory',
    iso: 'io',
    dialCode: '246',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'British Virgin Islands',
    iso: 'vg',
    dialCode: '1',
    priority: 11,
    areaCodes: [
      '284',
    ],
  },
  {
    name: 'Brunei',
    iso: 'bn',
    dialCode: '673',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Bulgaria (България)',
    iso: 'bg',
    dialCode: '359',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Burkina Faso',
    iso: 'bf',
    dialCode: '226',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Burundi (Uburundi)',
    iso: 'bi',
    dialCode: '257',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    iso: 'kh',
    dialCode: '855',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Cameroon (Cameroun)',
    iso: 'cm',
    dialCode: '237',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Canada',
    iso: 'ca',
    dialCode: '1',
    priority: 1,
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    iso: 'cv',
    dialCode: '238',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Caribbean Netherlands',
    iso: 'bq',
    dialCode: '599',
    priority: 1,
    areaCodes: [
      '3',
      '4',
      '7',
    ],
  },
  {
    name: 'Cayman Islands',
    iso: 'ky',
    dialCode: '1',
    priority: 12,
    areaCodes: [
      '345',
    ],
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    iso: 'cf',
    dialCode: '236',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Chad (Tchad)',
    iso: 'td',
    dialCode: '235',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Chile',
    iso: 'cl',
    dialCode: '56',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'China (中国)',
    iso: 'cn',
    dialCode: '86',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Christmas Island',
    iso: 'cx',
    dialCode: '61',
    priority: 2,
    areaCodes: [
      '89164',
    ],
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso: 'cc',
    dialCode: '61',
    priority: 1,
    areaCodes: [
      '89162',
    ],
  },
  {
    name: 'Colombia',
    iso: 'co',
    dialCode: '57',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    iso: 'km',
    dialCode: '269',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    iso: 'cd',
    dialCode: '243',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    iso: 'cg',
    dialCode: '242',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Cook Islands',
    iso: 'ck',
    dialCode: '682',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Costa Rica',
    iso: 'cr',
    dialCode: '506',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Côte d’Ivoire',
    iso: 'ci',
    dialCode: '225',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Croatia (Hrvatska)',
    iso: 'hr',
    dialCode: '385',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Cuba',
    iso: 'cu',
    dialCode: '53',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Curaçao',
    iso: 'cw',
    dialCode: '599',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Cyprus (Κύπρος)',
    iso: 'cy',
    dialCode: '357',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Czech Republic (Česká republika)',
    iso: 'cz',
    dialCode: '420',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Denmark (Danmark)',
    iso: 'dk',
    dialCode: '45',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Djibouti',
    iso: 'dj',
    dialCode: '253',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Dominica',
    iso: 'dm',
    dialCode: '1',
    priority: 13,
    areaCodes: [
      '767',
    ],
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    iso: 'do',
    dialCode: '1',
    priority: 2,
    areaCodes: [
      '809',
      '829',
      '849',
    ],
  },
  {
    name: 'Ecuador',
    iso: 'ec',
    dialCode: '593',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    iso: 'eg',
    dialCode: '20',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'El Salvador',
    iso: 'sv',
    dialCode: '503',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    iso: 'gq',
    dialCode: '240',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Eritrea',
    iso: 'er',
    dialCode: '291',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Estonia (Eesti)',
    iso: 'ee',
    dialCode: '372',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Eswatini',
    iso: 'sz',
    dialCode: '268',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Ethiopia',
    iso: 'et',
    dialCode: '251',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    iso: 'fk',
    dialCode: '500',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Faroe Islands (Føroyar)',
    iso: 'fo',
    dialCode: '298',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Fiji',
    iso: 'fj',
    dialCode: '679',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Finland (Suomi)',
    iso: 'fi',
    dialCode: '358',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'France',
    iso: 'fr',
    dialCode: '33',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'French Guiana (Guyane française)',
    iso: 'gf',
    dialCode: '594',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'French Polynesia (Polynésie française)',
    iso: 'pf',
    dialCode: '689',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Gabon',
    iso: 'ga',
    dialCode: '241',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Gambia',
    iso: 'gm',
    dialCode: '220',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Georgia (საქართველო)',
    iso: 'ge',
    dialCode: '995',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Germany (Deutschland)',
    iso: 'de',
    dialCode: '49',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Ghana (Gaana)',
    iso: 'gh',
    dialCode: '233',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Gibraltar',
    iso: 'gi',
    dialCode: '350',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Greece (Ελλάδα)',
    iso: 'gr',
    dialCode: '30',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    iso: 'gl',
    dialCode: '299',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Grenada',
    iso: 'gd',
    dialCode: '1',
    priority: 14,
    areaCodes: [
      '473',
    ],
  },
  {
    name: 'Guadeloupe',
    iso: 'gp',
    dialCode: '590',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Guam',
    iso: 'gu',
    dialCode: '1',
    priority: 15,
    areaCodes: [
      '671',
    ],
  },
  {
    name: 'Guatemala',
    iso: 'gt',
    dialCode: '502',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Guernsey',
    iso: 'gg',
    dialCode: '44',
    priority: 1,
    areaCodes: [
      '1481',
      '7781',
      '7839',
      '7911',
    ],
  },
  {
    name: 'Guinea (Guinée)',
    iso: 'gn',
    dialCode: '224',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    iso: 'gw',
    dialCode: '245',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Guyana',
    iso: 'gy',
    dialCode: '592',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Haiti',
    iso: 'ht',
    dialCode: '509',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Honduras',
    iso: 'hn',
    dialCode: '504',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Hong Kong (香港)',
    iso: 'hk',
    dialCode: '852',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Hungary (Magyarország)',
    iso: 'hu',
    dialCode: '36',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Iceland (Ísland)',
    iso: 'is',
    dialCode: '354',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'India (भारत)',
    iso: 'in',
    dialCode: '91',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Indonesia',
    iso: 'id',
    dialCode: '62',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Iran (‫ایران‬‎)',
    iso: 'ir',
    dialCode: '98',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    iso: 'iq',
    dialCode: '964',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Ireland',
    iso: 'ie',
    dialCode: '353',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Isle of Man',
    iso: 'im',
    dialCode: '44',
    priority: 2,
    areaCodes: [
      '1624',
      '74576',
      '7524',
      '7924',
      '7624',
    ],
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    iso: 'il',
    dialCode: '972',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Italy (Italia)',
    iso: 'it',
    dialCode: '39',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Jamaica',
    iso: 'jm',
    dialCode: '1',
    priority: 4,
    areaCodes: [
      '876',
      '658',
    ],
  },
  {
    name: 'Japan (日本)',
    iso: 'jp',
    dialCode: '81',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Jersey',
    iso: 'je',
    dialCode: '44',
    priority: 3,
    areaCodes: [
      '1534',
      '7509',
      '7700',
      '7797',
      '7829',
      '7937',
    ],
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    iso: 'jo',
    dialCode: '962',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Kazakhstan (Казахстан)',
    iso: 'kz',
    dialCode: '7',
    priority: 1,
    areaCodes: [
      '33',
      '7',
    ],
  },
  {
    name: 'Kenya',
    iso: 'ke',
    dialCode: '254',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Kiribati',
    iso: 'ki',
    dialCode: '686',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Kosovo',
    iso: 'xk',
    dialCode: '383',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    iso: 'kw',
    dialCode: '965',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    iso: 'kg',
    dialCode: '996',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Laos (ລາວ)',
    iso: 'la',
    dialCode: '856',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Latvia (Latvija)',
    iso: 'lv',
    dialCode: '371',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    iso: 'lb',
    dialCode: '961',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Lesotho',
    iso: 'ls',
    dialCode: '266',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Liberia',
    iso: 'lr',
    dialCode: '231',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    iso: 'ly',
    dialCode: '218',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Liechtenstein',
    iso: 'li',
    dialCode: '423',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Lithuania (Lietuva)',
    iso: 'lt',
    dialCode: '370',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Luxembourg',
    iso: 'lu',
    dialCode: '352',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Macau (澳門)',
    iso: 'mo',
    dialCode: '853',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    iso: 'mk',
    dialCode: '389',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Madagascar (Madagasikara)',
    iso: 'mg',
    dialCode: '261',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Malawi',
    iso: 'mw',
    dialCode: '265',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Malaysia',
    iso: 'my',
    dialCode: '60',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Maldives',
    iso: 'mv',
    dialCode: '960',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mali',
    iso: 'ml',
    dialCode: '223',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Malta',
    iso: 'mt',
    dialCode: '356',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Marshall Islands',
    iso: 'mh',
    dialCode: '692',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Martinique',
    iso: 'mq',
    dialCode: '596',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    iso: 'mr',
    dialCode: '222',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mauritius (Moris)',
    iso: 'mu',
    dialCode: '230',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mayotte',
    iso: 'yt',
    dialCode: '262',
    priority: 1,
    areaCodes: [
      '269',
      '639',
    ],
  },
  {
    name: 'Mexico (México)',
    iso: 'mx',
    dialCode: '52',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Micronesia',
    iso: 'fm',
    dialCode: '691',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Moldova (Republica Moldova)',
    iso: 'md',
    dialCode: '373',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Monaco',
    iso: 'mc',
    dialCode: '377',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mongolia (Монгол)',
    iso: 'mn',
    dialCode: '976',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Montenegro (Crna Gora)',
    iso: 'me',
    dialCode: '382',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Montserrat',
    iso: 'ms',
    dialCode: '1',
    priority: 16,
    areaCodes: [
      '664',
    ],
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    iso: 'ma',
    dialCode: '212',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Mozambique (Moçambique)',
    iso: 'mz',
    dialCode: '258',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    iso: 'mm',
    dialCode: '95',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Namibia (Namibië)',
    iso: 'na',
    dialCode: '264',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Nauru',
    iso: 'nr',
    dialCode: '674',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Nepal (नेपाल)',
    iso: 'np',
    dialCode: '977',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Netherlands (Nederland)',
    iso: 'nl',
    dialCode: '31',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    iso: 'nc',
    dialCode: '687',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'New Zealand',
    iso: 'nz',
    dialCode: '64',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Nicaragua',
    iso: 'ni',
    dialCode: '505',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Niger (Nijar)',
    iso: 'ne',
    dialCode: '227',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Nigeria',
    iso: 'ng',
    dialCode: '234',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Niue',
    iso: 'nu',
    dialCode: '683',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Norfolk Island',
    iso: 'nf',
    dialCode: '672',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    iso: 'kp',
    dialCode: '850',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Northern Mariana Islands',
    iso: 'mp',
    dialCode: '1',
    priority: 17,
    areaCodes: [
      '670',
    ],
  },
  {
    name: 'Norway (Norge)',
    iso: 'no',
    dialCode: '47',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    iso: 'om',
    dialCode: '968',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    iso: 'pk',
    dialCode: '92',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Palau',
    iso: 'pw',
    dialCode: '680',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    iso: 'ps',
    dialCode: '970',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Panama (Panamá)',
    iso: 'pa',
    dialCode: '507',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Papua New Guinea',
    iso: 'pg',
    dialCode: '675',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Paraguay',
    iso: 'py',
    dialCode: '595',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Peru (Perú)',
    iso: 'pe',
    dialCode: '51',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Philippines',
    iso: 'ph',
    dialCode: '63',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Poland (Polska)',
    iso: 'pl',
    dialCode: '48',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Portugal',
    iso: 'pt',
    dialCode: '351',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Puerto Rico',
    iso: 'pr',
    dialCode: '1',
    priority: 3,
    areaCodes: [
      '787',
      '939',
    ],
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    iso: 'qa',
    dialCode: '974',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Réunion (La Réunion)',
    iso: 're',
    dialCode: '262',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Romania (România)',
    iso: 'ro',
    dialCode: '40',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Russia (Россия)',
    iso: 'ru',
    dialCode: '7',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Rwanda',
    iso: 'rw',
    dialCode: '250',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Saint Barthélemy',
    iso: 'bl',
    dialCode: '590',
    priority: 1,
    areaCodes: [],
  },
  {
    name: 'Saint Helena',
    iso: 'sh',
    dialCode: '290',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Saint Kitts and Nevis',
    iso: 'kn',
    dialCode: '1',
    priority: 18,
    areaCodes: [
      '869',
    ],
  },
  {
    name: 'Saint Lucia',
    iso: 'lc',
    dialCode: '1',
    priority: 19,
    areaCodes: [
      '758',
    ],
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    iso: 'mf',
    dialCode: '590',
    priority: 2,
    areaCodes: [],
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    iso: 'pm',
    dialCode: '508',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: 'vc',
    dialCode: '1',
    priority: 20,
    areaCodes: [
      '784',
    ],
  },
  {
    name: 'Samoa',
    iso: 'ws',
    dialCode: '685',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'San Marino',
    iso: 'sm',
    dialCode: '378',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    iso: 'st',
    dialCode: '239',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    iso: 'sa',
    dialCode: '966',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Senegal (Sénégal)',
    iso: 'sn',
    dialCode: '221',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Serbia (Србија)',
    iso: 'rs',
    dialCode: '381',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Seychelles',
    iso: 'sc',
    dialCode: '248',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Sierra Leone',
    iso: 'sl',
    dialCode: '232',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Singapore',
    iso: 'sg',
    dialCode: '65',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Sint Maarten',
    iso: 'sx',
    dialCode: '1',
    priority: 21,
    areaCodes: [
      '721',
    ],
  },
  {
    name: 'Slovakia (Slovensko)',
    iso: 'sk',
    dialCode: '421',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Slovenia (Slovenija)',
    iso: 'si',
    dialCode: '386',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Solomon Islands',
    iso: 'sb',
    dialCode: '677',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Somalia (Soomaaliya)',
    iso: 'so',
    dialCode: '252',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'South Africa',
    iso: 'za',
    dialCode: '27',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'South Korea (대한민국)',
    iso: 'kr',
    dialCode: '82',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    iso: 'ss',
    dialCode: '211',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Spain (España)',
    iso: 'es',
    dialCode: '34',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    iso: 'lk',
    dialCode: '94',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    iso: 'sd',
    dialCode: '249',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Suriname',
    iso: 'sr',
    dialCode: '597',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso: 'sj',
    dialCode: '47',
    priority: 1,
    areaCodes: [
      '79',
    ],
  },
  {
    name: 'Sweden (Sverige)',
    iso: 'se',
    dialCode: '46',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Switzerland (Schweiz)',
    iso: 'ch',
    dialCode: '41',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    iso: 'sy',
    dialCode: '963',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Taiwan (台灣)',
    iso: 'tw',
    dialCode: '886',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Tajikistan',
    iso: 'tj',
    dialCode: '992',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Tanzania',
    iso: 'tz',
    dialCode: '255',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Thailand (ไทย)',
    iso: 'th',
    dialCode: '66',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Timor-Leste',
    iso: 'tl',
    dialCode: '670',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Togo',
    iso: 'tg',
    dialCode: '228',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Tokelau',
    iso: 'tk',
    dialCode: '690',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Tonga',
    iso: 'to',
    dialCode: '676',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Trinidad and Tobago',
    iso: 'tt',
    dialCode: '1',
    priority: 22,
    areaCodes: [
      '868',
    ],
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    iso: 'tn',
    dialCode: '216',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Turkey (Türkiye)',
    iso: 'tr',
    dialCode: '90',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Turkmenistan',
    iso: 'tm',
    dialCode: '993',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Turks and Caicos Islands',
    iso: 'tc',
    dialCode: '1',
    priority: 23,
    areaCodes: [
      '649',
    ],
  },
  {
    name: 'Tuvalu',
    iso: 'tv',
    dialCode: '688',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'U.S. Virgin Islands',
    iso: 'vi',
    dialCode: '1',
    priority: 24,
    areaCodes: [
      '340',
    ],
  },
  {
    name: 'Uganda',
    iso: 'ug',
    dialCode: '256',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Ukraine (Україна)',
    iso: 'ua',
    dialCode: '380',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    iso: 'ae',
    dialCode: '971',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'United Kingdom',
    iso: 'gb',
    dialCode: '44',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'United States',
    iso: 'us',
    dialCode: '1',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Uruguay',
    iso: 'uy',
    dialCode: '598',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    iso: 'uz',
    dialCode: '998',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Vanuatu',
    iso: 'vu',
    dialCode: '678',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    iso: 'va',
    dialCode: '39',
    priority: 1,
    areaCodes: [
      '06698',
    ],
  },
  {
    name: 'Venezuela',
    iso: 've',
    dialCode: '58',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Vietnam (Việt Nam)',
    iso: 'vn',
    dialCode: '84',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Wallis and Futuna (Wallis-et-Futuna)',
    iso: 'wf',
    dialCode: '681',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    iso: 'eh',
    dialCode: '212',
    priority: 1,
    areaCodes: [
      '5288',
      '5289',
    ],
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    iso: 'ye',
    dialCode: '967',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Zambia',
    iso: 'zm',
    dialCode: '260',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Zimbabwe',
    iso: 'zw',
    dialCode: '263',
    priority: 0,
    areaCodes: [],
  },
  {
    name: 'Åland Islands',
    iso: 'ax',
    dialCode: '358',
    priority: 1,
    areaCodes: [
      '18',
    ],
  },
];

export const COUNTRY_AVAILABLE_CONFIG = COUNTRY_CONFIG.filter(({ iso }) => {
  return COUNTRY_AVAILABLE.includes(iso);
});
