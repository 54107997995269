<template>
  <div class="modal-max-tip-amount">
    <ui-bottom-sheet
      v-bind="$attrs"
      v-on="$listeners"
      #default="{ close }"
    >
      <div class="modal-max-tip-amount__body">
        <div class="modal-max-tip-amount__title">
          {{ $t('modal.maxTipAmount.title') }}
        </div>

        <div class="modal-max-tip-amount__description">
          {{ $t('modal.maxTipAmount.text', { money }) }}
        </div>

        <ui-button
          class="modal-max-tip-amount__button"
          @click="close"
        >
          {{ $t('modal.maxTipAmount.button') }}
        </ui-button>
      </div>
    </ui-bottom-sheet>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import Money from '@/class/Money';
import UiBottomSheet from './UiBottomSheet.vue';
import UiButton from './UiButton.vue';

const props = defineProps({
  amount: {
    type: Number,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { amount, currency } = toRefs(props);

const money = computed(() => {
  return new Money(amount.value, currency.value);
});
</script>

<style lang="scss">
.modal-max-tip-amount {
  &__title {
    font-family: 'RobotoMedium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }

  &__description {
    max-width: 270px;
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #777777;
    margin: 0 auto 30px;
    // margin-bottom: 30px;
  }

  &__button {
    font-family: 'RobotoRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
