export default {
  requestCustomerInfo: {
    key: 'requestCustomerInfo',
    order: 1,
    description: 'your details',
  },
  billPayment: {
    key: 'billPayment',
    order: 2,
    description: 'review and pay',
  },
};
