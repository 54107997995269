<template>
  <main-layout
    :company-logo="showCompanyLogo"
    :partner-id="partnerId"
    :payout-id="workplacePayoutId"
    no-social-icons
  >
    <div class="payermax">
      <div
        v-if="status"
        class="payermax__loader"
      >
        <ui-spinner />
      </div>
      <div
        v-else
        class="payermax__body"
        :class="{ 'payermax__body_no-staff': !staffList?.length }"
      >
        <location-card
          v-if="showLocationCard"
          class="payermax__location_card"
          :payoutId="workplacePayoutId"
          :name="locationName"
          :show-team-tips="!isQrBillPayment"
        />
        <staff-card
          v-else-if="showStaffCard"
          class="payermax__staff_card"
          :wish="wish"
          :staff="staffData"
        />

        <div class="payermax__card">
          <div class="payermax__info">
            <div class="payermax__total">
              <span class="payermax__title">EasyTip</span>
              <ui-money
                :value="totalMoney"
                class="payermax__amount"
                :class="{
                  'payermax__amount_small': totalMoney.amountLong,
                }"
              />
            </div>
            <ul class="payermax__payment-methods">
              <li
                v-for="item in paymentMethodsLogo"
                :key="item.id"
                class="payermax__payment-method"
              >
                <img
                  :src="require(`@/assets/payment-methods/${item.title}.svg`)"
                  :alt="item.title"
                />
              </li>
            </ul>
          </div>

          <div class="payermax__form">
            <div
              v-if="loading.card"
              class="payermax__form-loader"
            >
              <ui-spinner />
            </div>

            <div v-show="!loading.card" class="payermax__form-iframe" />

            <ul class="payermax__labels">
              <li
                v-for="item in paymentMethodsLabel"
                :key="item.id"
                class="payermax__label"
              >
                <img
                  :src="require(`@/assets/payment-methods/${item.title}_label.svg`)"
                  :alt="item.title"
                />
              </li>
            </ul>

            <ui-button
              class="payermax__form-button"
              :is-loading="loading.payment"
              :disabled="isSubmitDisabled"
              @click.prevent="handleSubmit"
            >
              <span>
                Pay <ui-money :value="totalMoney" />
              </span>
            </ui-button>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script setup>
import {
  ref,
  watch,
  reactive,
  computed,
  onMounted,
  getCurrentInstance,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import api from '@/api';
import Money from '@/class/Money';
import storage from '@/plugins/storage';
import MainLayout from '@/layouts/MainLayout.vue';
import StaffCard from '@/components/StaffCard.vue';
import LocationCard from '@/components/LocationCard.vue';
import UiMoney from '@/components/UiMoney.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import UiButton from '@/components/UiButton.vue';

const PAYERMAX_SANDBOX = process.env.VUE_APP_PAYERMAX_SANDBOX_ENV === 'true';
const REDIRECT_URL = 'redirect-url';
const PAYMENT_STATUS = {
  APPLY_SUCCESS: 'APPLY_SUCCESS',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
};

const props = defineProps({
  listPayment: Array,
  feeChecked: Boolean,
  currency: String,
  amount: Number,
  totalAmount: Number,
  feeAmount: Number,
  totalTipsFee: Number,
  groupId: String,
  workplacePayoutId: String,
  backUrl: Object,
  staffData: Object,
  staffPayoutId: String,
  paymentId: String,
  currencyInfo: Object,
  isCommon: Boolean,
  locationName: String,
  partnerId: String,
  status: String,
  billId: String,
  billCustomerInfo: Object,
});

const store = useStore();
const route = useRoute();
const router = useRouter();

const currentInstance = getCurrentInstance();
const { $amplitude } = currentInstance.appContext.config.globalProperties;

const disabled = ref(true);
const card = ref(null);
const payerMax = ref(null);
const paymentData = ref(null);
const loading = reactive({
  card: false,
  payment: false,
});

const location = computed(() => store.state['user/location']);
const wish = computed(() => store.getters['user/wish']);

const isQrBillPayment = computed(() => {
  return Boolean(props.billId);
});

const isSubmitDisabled = computed(() => {
  return !props.totalAmount
    || loading.payment
    || disabled.value;
});

const isStaff = computed(() => {
  return props.listPayment.length === 1;
});

const staffList = computed(() => {
  if (isStaff.value) {
    return [];
  }

  return location.value?.staffs
    .filter((item) => props.listPayment
      .some(({ payoutId }) => item.payoutId === payoutId))
    .map((item) => ({
      ...item,
    }));
});

const showStaffCard = computed(() => {
  if (isQrBillPayment.value) {
    return false;
  }

  return isStaff.value && props.staffData && !staffList.value?.length;
});

const showLocationCard = computed(() => {
  if (isQrBillPayment.value) {
    return false;
  }

  return props.isCommon;
});

const showCompanyLogo = computed(() => {
  if (isQrBillPayment.value) {
    return true;
  }

  return !isStaff;
});

const totalMoney = computed(() => {
  return new Money(props.totalAmount, props.currencyInfo);
});

const paymentMethodsLogo = computed(() => {
  return [
    {
      id: 1,
      title: 'mastercard',
    },
    {
      id: 2,
      title: 'visa',
    },
    {
      id: 3,
      title: 'jcb',
    },
    {
      id: 4,
      title: 'american-express',
    },
    {
      id: 5,
      title: 'diners-club',
    },
    {
      id: 6,
      title: 'giro-pay',
    },
    {
      id: 7,
      title: 'discover',
    },
  ];
});

const paymentMethodsLabel = computed(() => {
  return [
    {
      id: 1,
      title: 'mastercard',
    },
    {
      id: 2,
      title: 'visa',
    },
    {
      id: 3,
      title: 'american-express',
    },
  ];
});

const wrongPayload = computed(() => {
  if (isQrBillPayment.value && !props.totalTipsFee) {
    return false;
  }

  if (props.feeChecked && props.feeAmount === 0) {
    return true;
  }

  return props.listPayment.some((item) => {
    return item.amount === 0;
  });
});

const paymentPayload = computed(() => {
  const tipsDetails = {
    listPayment: props.listPayment,
    currency: props.currency,
    amount: +props.totalAmount,
    feeAmount: props.feeAmount,
    feeChecked: props.feeChecked,
    workplacePayoutId: props.workplacePayoutId,
    groupId: props.groupId,
  };

  if (isQrBillPayment.value) {
    const billPaymentData = {
      billId: props.billId,
      customerInfo: props.billCustomerInfo,
    };

    if (props.totalTipsFee) {
      return {
        ...billPaymentData,
        tipsDetails: {
          ...tipsDetails,
          amount: props.totalTipsFee,
        },
      };
    }

    return billPaymentData;
  }

  return tipsDetails;
});

const resetStorage = () => {
  storage.set(REDIRECT_URL, '');
};

const resetCustomerInfoData = () => {
  const billInfoStorage = storage.get(props.billId);
  if (billInfoStorage) {
    storage.set(props.billId, null);
  }
};

const loadLocationStaffs = () => {
  return store.dispatch('user/loadLocationStaffs', { payoutId: props.workplacePayoutId });
};

const loadPaymentData = async () => {
  try {
    const tips = {
      currency: props.currency,
      amount: +props.totalAmount,
    };

    let payload = tips;

    if (isQrBillPayment.value) {
      payload = {
        billId: props.billId,
      };

      if (props.totalTipsFee) {
        payload = {
          billId: props.billId,
          tips: {
            ...tips,
            amount: props.totalTipsFee,
          },
        };
      }
    }

    const { data } = await api.getPayermaxCardSession(payload, isQrBillPayment.value);

    paymentData.value = data;
  } catch (e) {
    redirectToWrongPayment();
  }
};

const onPayermaxLoaded = async () => {
  payerMax.value = window.PMdropin;

  await loadPaymentData();

  if (!paymentData.value) {
    return;
  }

  card.value = payerMax.value.create('card', {
    clientKey: paymentData.value?.clientKey,
    sessionKey: paymentData.value?.sessionKey,
    sandbox: PAYERMAX_SANDBOX,
    hideSaveCard: true,
    hideCardBrands: true,
    customLocalization: {
      en: {
        loading: '',
        loadingFailed: '',
        refresh: 'Refresh card form',
        confirm: 'Confirm',
        cancel: 'Cancel',
        cardnum: 'Card number',
        cardnumHint: '0000 0000 0000 0000',
        cardnumErrTip: 'Please enter a valid card number',
        cardbinErrTip: {
          CARD_NOT_SUPPORT: 'CARD_NOT_SUPPORT',
          CARD_INVALID: 'CARD_INVALID',
          CARD_NO_INVALID: 'CARD_NO_INVALID',
        },
        expdate: 'Expiry date',
        expdateHint: 'MM/YY',
        expdateErrTip: 'Please enter a valid expiry date',
        cvv: 'CVC/CVV',
        cvvHint: '000',
        cvvErrTip: 'Please enter a valid cvc/cvv code',
        name: 'Cardholder name',
        nameHint: 'XX XX',
        nameErrTip: 'Please enter a valid cardholder name',
      },
    },
    theme: 'custom',
    customTheme: [
      {
        name: 'custom',
        base: 'light',
        style: `:root {
          /* --- common --- */
          --bg-primary: #ffffff;
          --color-primary: #3782ff;
          --border-color-primary: #808080;
          --border-color-hover-primary: #808080;
          --font-size-primary: 13px;
          --border-radius-primary: 6px;
        
          /* --- frame --- */
          --padding-frame: 0;
          --bg-color-frame: transparent;
          --bg-color-mask: transparent;
        
          /* --- text --- */
          --color-text-primary: #808080;
          --color-text-secondary: #666666;
          --color-text-tip: #c8c8c8;
          --color-text-error: #a01212;

          /* text button */
          --color-active-btn: #808080;
        
          /* --- input --- */
          --bg-color-input: var(--bg-primary);
          --border-color-input: #c4c4c4;
          --border-color-hover-input: var(--border-color-input);
          --border-color-focus-input: #000000;
          --border-radius-input: 0;
          --shadow-color-focus-input: var(--bg-primary);
          --label-color-input: var(--color-text-primary);
          --label-color-focus-input: var(--color-text-primary);
          --action-color-input: var(--border-color-input);
          --action-color-hover-input: var(--border-color-hover-input);
          --action-color-active-input: #bbbbbb;
          --color-placeholder-input: var(--border-color-input);
          --color-input: #000000;
          --font-size-input: 16px;
          --font-size-input-label: 14px;
          --font-size-input-tip: 14px;
          --height-input: 40px;
        }

        /* --- Special --- */
        form .one-line:nth-child(1),
        form .one-line:nth-child(2) {
          margin-bottom: 25px !important;
        }

        label {
          font-family: "Roboto", sans-serif !important;
          font-weight: 400;
        }

        label .pmf-input-label {
          color: #808080 !important;
        }

        label .pmf-input-wrapper {
          border: none !important;
          border-bottom: 1px solid #cccccc !important;
          padding: 0 !important;
          padding-right: 8px !important;
        }

        label .pmf-input-wrapper[error="true"] {
          border-bottom: 1px solid #a01212 !important;
        }`,
      },
    ],
  });

  card.value.mount('.payermax__form-iframe');

  card.value.on('ready', () => {
    loading.card = false;
  });

  card.value.on('form-check', (e) => {
    if (e.isFormValid) {
      disabled.value = false;
    } else {
      disabled.value = true;
    }
  });
};

const getRedirectUrlFrom3DS = () => {
  const { origin } = window.location;

  const {
    query: {
      status,
      paymentId,
      ...queryData
    },
  } = route;

  const storageRedirectUrl = router.resolve({
    query: {
      ...queryData,
      redirected: true,
    },
  }).href;

  const redirectRedirectUrl = router.resolve({
    query: {
      redirected: true,
    },
  }).href;

  storage.set(REDIRECT_URL, storageRedirectUrl);

  return origin + redirectRedirectUrl;
};

const redirectToStatusPage = ({ status, paymentId }) => {
  router.push({
    path: `${props.backUrl.locationId}/${status}`,
    query: {
      ...props.backUrl.query,
      paymentId,
      rate: props.backUrl.rate,
      isPayermaxCardPayment: true,
      amount: props.totalAmount,
      feeAmount: props.feeAmount,
      customerEmail: props.backUrl.customerEmail,
    },
  });
};

const redirectToFullUrl = (redirectUrl) => {
  window.location.href = `${redirectUrl}&paymentId=${props.paymentId}&status=${props.status.toLowerCase()}`;
};

const createPayment = async (paymentToken) => {
  resetCustomerInfoData();

  try {
    const { data } = await api.createPayermaxCardPayment({
      ...paymentPayload.value,
      customerCardToken: paymentToken,
      sessionKey: paymentData.value?.sessionKey,
      successUrl: getRedirectUrlFrom3DS(),
    }, isQrBillPayment.value);

    if (data) {
      if (data.status === PAYMENT_STATUS.PENDING) {
        if (data.redirectInfo?.redirectUrl) {
          window.location.href = data.redirectInfo?.redirectUrl;
        } else if (data?.id) {
          redirectToStatusPage({
            status: 'success',
            paymentId: data.id,
          });
        }
      } else {
        redirectToStatusPage({
          paymentId: data.id,
          status: 'error',
        });
      }
    }
  } catch (e) {
    // noop
  } finally {
    loading.payment = false;
  }
};

const loadPayment = () => {
  if (loading.payment) {
    return;
  }

  card.value.emit('setDisabled', true);
  loading.payment = true;

  card.value.emit('canMakePayment')
    .then(async (response) => {
      card.value.emit('setDisabled', false);

      if (response.code === PAYMENT_STATUS.APPLY_SUCCESS) {
        const {
          data: {
            paymentToken,
          },
        } = response;

        await createPayment(paymentToken);
      } else {
        loading.payment = false;
      }
    })
    .catch(() => {
      card.value.emit('setDisabled', false);

      redirectToStatusPage({
        status: 'error',
        paymentId: null,
      });
    });
};

const redirectToWrongPayment = () => {
  $amplitude.event('WRONG_PAYERMAX_PAYMENT_BY_CARD', paymentPayload.value);

  const backUrl = router.resolve({
    name: 'home',
    params: {
      locationPayoutId: props.workplacePayoutId,
    },
    query: {
      staffId: props.listPayment.map(({ payoutId }) => payoutId),
    },
  })?.href;

  window.location.href = router.resolve({
    name: 'payment-failed',
    query: {
      backUrl,
      payermax: true,
    },
  }).href;
};

const handleSubmit = () => {
  if (wrongPayload.value) {
    redirectToWrongPayment();
  } else {
    loadPayment();
  }
};

watch(() => props.status, (value) => {
  if (!value) {
    return;
  }

  const redirectUrl = storage.get(REDIRECT_URL);

  switch (value) {
    case PAYMENT_STATUS.PENDING:
    case PAYMENT_STATUS.SUCCESS:
      if (redirectUrl) {
        resetStorage();
        redirectToFullUrl(redirectUrl);
      } else {
        redirectToStatusPage({
          status: 'success',
          paymentId: props.paymentId,
        });
      }
      break;
    default:
      if (redirectUrl) {
        resetStorage();
        redirectToFullUrl(redirectUrl);
      } else {
        redirectToStatusPage({
          status: 'error',
          paymentId: props.paymentId,
        });
      }
      break;
  }
}, { immediate: true });

onMounted(async () => {
  if (props.status) {
    return;
  }

  await loadLocationStaffs();

  if (payerMax.value) {
    onPayermaxLoaded();
  } else {
    loading.card = true;
    const script = document.createElement('script');

    script.async = true;
    script.src = 'https://cdn.payermax.com/dropin/js/pmdropin.min.js';
    script.onload = onPayermaxLoaded;

    document.head.appendChild(script);
  }
});
</script>

<style lang="scss">
@import "src/assets/sass/constants";

.payermax {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__staff_card,
  &__location_card {
    margin-bottom: 20px;
  }

  &__loader {
    width: 100%;
    height: 542px;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    > .ui-spinner {
      position: initial;
      padding: 0;
      margin: 0;

      svg {
        color: #000000;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    max-width: 335px;

    &_no-staff {
      margin-top: 20px;
    }
  }

  &__card {
    width: 100%;
    max-width: 335px;

    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.12);

    margin: 0 auto;
    margin-top: 20px;
  }

  &__form {
    margin: 0 auto;
    padding: 0 20px 20px;

    &-iframe {
      height: 320px;
      margin-top: 27px;

      iframe {
        height: 320px !important;
      }
    }

    &-loader {
      width: 100%;
      height: 345px;

      display: flex;
      justify-content: center;
      align-items: center;

      > .ui-spinner {
        position: initial;
        padding: 0;
        margin: 0;

        svg {
          color: #000000;
        }
      }
    }
  }

  &__info {
    display: flex;
  }

  &__total {
    min-width: 117px;
    min-height: 117px;
    max-width: 117px;
    max-height: 117px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    margin-top: -22px;
    margin-left: 20px;
  }

  &__title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
  }

  &__amount {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #000000;

    &_small {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__payment-methods {
    max-width: 158px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding: 0;
    margin: 20px 20px 20px 25px;
  }

  &__payment-method {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__labels {
    width: 100%;
    max-width: 335px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 20px auto 25px;
    color: rgba(0, 0, 0, 0.15);
  }

  &__label {
    list-style: none;
    padding: 0;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 365px) {
    &__info {
      flex-direction: column;
      align-items: center;
    }

    &__total {
      margin-left: 0;
    }

    &__payment-methods {
      justify-content: space-between;
      align-items: center;

      padding: 0;
      margin: 20px 20px 0;
    }
  }
}
</style>
