<template>
  <main-layout centered followUsBlock>
    <dialogue-card
      :title="title"
      :description="description"
      :action-text="actionText"
      class="payment-failed-dialogue"
      @action="tryAgainHandler"
    >
      <template #image>
        <img
          class="payment-failed-dialogue__image"
          src="@/assets/error-payment.svg"
          alt="Payment failed icon"
        />
      </template>
    </dialogue-card>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
import DialogueCard from '@/components/DialogueCard.vue';

export default {
  name: 'PaymentFailed',
  components: {
    DialogueCard,
    MainLayout,
  },
  props: {
    checkout: {
      type: Boolean,
      required: false,
      default: false,
    },
    payermax: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      backUrl: null,
    };
  },
  computed: {
    prefix() {
      if (this.checkout) {
        return 'dialogue.checkoutFailed';
      }

      if (this.payermax) {
        return 'dialogue.payermaxFailed';
      }

      return 'dialogue.paymentFailed';
    },
    title() {
      return this.$t(`${this.prefix}.title`);
    },
    description() {
      return this.$t(`${this.prefix}.description`);
    },
    actionText() {
      return this.$t(`${this.prefix}.action`);
    },
  },
  methods: {
    tryAgainHandler() {
      if (this.backUrl) {
        window.location.href = this.backUrl;
      } else {
        this.$router.back();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.backUrl = from.query?.backUrl || to.query?.backUrl;
    });
  },
};
</script>

<style lang="scss">
  .payment-failed-dialogue {
    margin: 0 auto;
    min-height: 393px;

    &__image {
      height: 64px;
      width: 64px;
    }
  }
</style>
