/**
 * From stripe js modules.
 *
 * @see {@link https://b.stripecdn.com/manage/assets/connect.vendors~connect_express.533b5137e7d7a1effc6e.min.js}
 * @type {Object}
 */
export default {
  AC: '62889',
  AD: '712 345',
  AE: '2 234 56783',
  AF: '23 456 7890',
  AG: '(268) 460-1234',
  AI: '(264) 461-2345',
  AL: '22 345 678',
  AM: '10 123456',
  AO: '222 123 456',
  AR: '11 2345 6789',
  AS: '(684) 622-1234',
  AT: '1 234567890',
  AU: '2 1234 5678',
  AW: '521 2345',
  AX: '18 1234567',
  AZ: '12 312 34 56',
  BA: '30 212 345',
  BB: '(246) 412-3456',
  BD: '2 7111234',
  BE: '12 34 56 78',
  BF: '20 49 12 34',
  BG: '2 123 456',
  BH: '1700 1234',
  BI: '22 20 12 34',
  BJ: '20 21 12 34',
  BL: '590 27 12 34',
  BM: '(441) 412-3456',
  BN: '234 5678',
  BO: '2 2123456',
  BQ: '715 1234',
  BR: '11 2345 6789',
  BS: '(242) 345-6789',
  BT: '2 345 678',
  BW: '240 1234',
  BY: '152 45 09 11',
  BZ: '222 1234',
  CA: '(506) 234-5678',
  CC: '8 9162 1234',
  CD: '12 34567',
  CF: '21 61 23 45',
  CG: '22 212 3456',
  CH: '21 234 56 78',
  CI: '21 23 45 67',
  CK: '21 234',
  CL: '2 2123 4567',
  CM: '2 22 12 34 56',
  CN: '10 1234 5678',
  CO: '1 2345678',
  CR: '2212 3456',
  CU: '7 1234567',
  CV: '221 12 34',
  CW: '9 435 1234',
  CX: '8 9164 1234',
  CY: '22 345678',
  CZ: '212 345 678',
  DE: [
    '3011 675312',
    '3011 6753122',
  ],
  DJ: '21 36 00 03',
  DK: '32 12 34 56',
  DM: '(767) 420-1234',
  DO: '(809) 234-5678',
  DZ: '12 34 56 78',
  EC: '2 212 3456',
  EE: '321 2345',
  EG: [
    '20 3456 7890',
    '2 3456 7890',
  ],
  EH: '5288 12345',
  ER: '8 370 362',
  ES: '810 12 34 56',
  ET: '11 111 2345',
  FI: '13 1234567',
  FJ: '321 2345',
  FK: '31234',
  FM: '320 1234',
  FO: '201234',
  FR: '1 23 45 67 89',
  GA: '01 44 12 34',
  GB: '121 234 5678',
  GD: '(473) 269-1234',
  GE: '32 212 34 56',
  GF: '594 10 12 34',
  GG: '1481 256789',
  GH: '30 234 5678',
  GI: '200 12345',
  GL: '32 10 00',
  GM: '566 1234',
  GN: '30 24 12 34',
  GP: '590 20 12 34',
  GQ: '333 091 234',
  GR: '21 2345 6789',
  GT: '2245 6789',
  GU: '(671) 300-1234',
  GW: '443 201 234',
  GY: '220 1234',
  HK: '2123 4567',
  HN: '2212 3456',
  HR: '1 2345 678',
  HT: '22 45 3300',
  HU: '1 234 5678',
  ID: '21 8350123',
  IE: '22 1234567',
  IL: '2 123 45678',
  IM: '1624 756789',
  IN: '74104 10123',
  IO: '370 9100',
  IQ: '1 234 5678',
  IR: '21 2345 6789',
  IS: '410 1234',
  IT: '02 1234 5678',
  JE: '1534 456789',
  JM: '(876) 523-0123',
  JO: '6 200 1234',
  JP: '3 1234 5678',
  KE: '20 2012345',
  KG: '312 123 456',
  KH: '23 756 789',
  KI: '31234',
  KM: '771 23 45',
  KN: '(869) 236-1234',
  KP: '2 123 4567',
  KR: '2 212 3456',
  KW: '2234 5678',
  KY: '(345) 222-1234',
  KZ: '71234 5 67 89',
  LA: '21 212 862',
  LB: '1 123 456',
  LC: '(758) 430-5678',
  LI: '234 56 78',
  LK: '112 345 678',
  LR: '21 234 567',
  LS: '2212 3456',
  LT: '312 34567',
  LU: '27 12 34 56',
  LV: '63 123 456',
  LY: '21 2345678',
  MA: '520 123456',
  MC: '99 12 34 56',
  MD: '22 212 345',
  ME: '30 234 567',
  MF: '590 27 12 34',
  MG: '20 21 234 56',
  MH: '247 1234',
  MK: '2 201 2345',
  ML: '20 21 23 45',
  MM: '1 234 567',
  MN: '5312 3456',
  MO: '2821 2345',
  MP: '(670) 234-5678',
  MQ: '596 30 12 34',
  MR: '35 12 34 56',
  MS: '(664) 491-2345',
  MT: '2100 1234',
  MU: '5448 0123',
  MV: '670 1234',
  MW: '1 234 567',
  MX: '200 123 4567',
  MY: '3 2385 6789',
  MZ: '21 123 456',
  NA: '61 221 234',
  NC: '20 12 34',
  NE: '20 20 12 34',
  NF: '10 6609',
  NG: '1 804 0123',
  NI: '2123 4567',
  NL: '10 123 4567',
  NO: '21 23 45 67',
  NP: '1 4567890',
  NR: '444 1234',
  NU: '7012',
  NZ: '3 234 5678',
  OM: '23 123456',
  PA: '200 1234',
  PE: '1 1234567',
  PF: '40 41 23 45',
  PG: '312 3456',
  PH: '2 123 4567',
  PK: '21 23456789',
  PL: '12 345 67 89',
  PM: '43 01 23',
  PR: '(787) 234-5678',
  PS: '2 223 4567',
  PT: '21 234 5678',
  PW: '277 1234',
  PY: '21 234 5678',
  QA: '4412 3456',
  RE: '262 16 12 34',
  RO: '21 123 4567',
  RS: '10 234567',
  RU: '301 123 45 67',
  RW: '250 123 456',
  SA: '11 234 5678',
  SB: '40123',
  SC: '4 217 123',
  SD: '15 312 3456',
  SE: [
    '8 12 34 56',
    '18 12 34 56',
    '218 12 34 56',
  ],
  SG: '6123 4567',
  SH: '22158',
  SI: '1 234 56 78',
  SJ: '79 12 34 56',
  SK: '2 212 345 67',
  SL: '22 221234',
  SM: '0549 886377',
  SN: '30 101 23 45',
  SO: '4 012345',
  SR: '211 234',
  SS: '181 234 567',
  ST: '222 1234',
  SV: '2123 4567',
  SX: '(721) 542-5678',
  SY: '11 234 5678',
  SZ: '2217 1234',
  TA: '8999',
  TC: '(649) 712-1234',
  TD: '22 50 12 34',
  TG: '22 21 23 45',
  TH: '2 123 4567',
  TJ: '372 12 3456',
  TK: '3101',
  TL: '211 2345',
  TM: '12 34 56 78',
  TN: '30 010 123',
  TO: '20 123',
  TR: '212 345 67 89',
  TT: '(868) 221-1234',
  TV: '20 123',
  TW: '2 2123 4567',
  TZ: '22 234 5678',
  UA: '3112 34567',
  UG: '31 2345678',
  US: '(201) 555-0123',
  UY: '2123 1234',
  UZ: '66 905 01 23',
  VA: '06 6981 2345',
  VC: '(784) 266-1234',
  VE: '212 1234567',
  VG: '(284) 229-1234',
  VI: '(340) 642-1234',
  VN: [
    '210 1234 567',
    '21 1234 567',
    '2 1234 567',
  ],
  VU: '22123',
  WF: '50 12 34',
  WS: '22123',
  XK: '28 012 345',
  YE: '1 234 567',
  YT: '269 60 12 34',
  ZA: '10 123 4567',
  ZM: '211 234 567',
  ZW: '13 12345',
};
