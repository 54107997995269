<template>
  <ui-checkbox class="user-agree-checkbox" v-bind="$attrs" v-on="$listeners" black>
    <div class="user-agree-checkbox__content">
      <span class="user-agree-checkbox__text">
        {{ $t('form.agree[0]') }}
      </span>
      <modal-terms @opened="onTermsOpened">
        <template #activator="{ open }">
          <div class="user-agree-checkbox__text">
            <a href="javascript:void(0)" @click.stop.prevent="open">
              {{ $t('form.agree[1]') }}
            </a>
          </div>
        </template>
      </modal-terms>
      <span class="user-agree-checkbox__text">
        {{ $t('form.agree[2]') }}
      </span>
      <modal-privacy @opened="onPrivacyOpened">
        <template #activator="{ open }">
          <div class="user-agree-checkbox__text">
            <a href="javascript:void(0)" @click.stop.prevent="open">
              {{ $t('form.agree[3]') }}
            </a>
          </div>
        </template>
      </modal-privacy>
    </div>
  </ui-checkbox>
</template>

<script>
import { useEventTrackerMixin } from '@/mixins/event-tracker-mixin';
import UiCheckbox from './UiCheckbox.vue';
import ModalTerms from './ModalTerms.vue';
import ModalPrivacy from './ModalPrivacy.vue';

export default {
  name: 'UserAgreeCheckbox',
  components: {
    UiCheckbox,
    ModalTerms,
    ModalPrivacy,
  },
  props: {
    payoutId: {
      type: Array,
      required: true,
    },
    isStaff: Boolean,
  },
  setup() {
    const { eventTrackerNamePrefix } = useEventTrackerMixin();

    return {
      eventTrackerNamePrefix,
    };
  },
  methods: {
    onTermsOpened() {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_TERMS_OPEN`, {
        payoutId: this.payoutId.join(','),
      });
    },
    onPrivacyOpened() {
      this.$amplitude.event(`${this.eventTrackerNamePrefix}_PRIVACY_OPEN`, {
        payoutId: this.payoutId.join(','),
      });
    },
  },
};
</script>

<style lang="scss">
  .user-agree-checkbox {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-self: start;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    &__content {
      display: flex;
      flex-wrap: wrap;
    }
    &__text {
      color: $text;
      margin-right: 3px;
      a {
        color: inherit;
      }
    }
  }
</style>
