<template>
  <div class="user-rates">
    <div class="user-rates__title">
      {{ title }}
    </div>
    <div class="user-rates__body">
      <ui-icon-button
        v-for="index in 5"
        :key="index"
        :name="index <= selected ? 'star-primary' : 'star'"
        size="48px"
        aria-label="Star rate button"
        class="user-rates__button"
        @click="selected = index"
      />
    </div>
  </div>
</template>

<script>
import UiIconButton from './UiIconButton.vue';

export default {

  components: {
    UiIconButton,
  },

  props: {
    value: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
  },

  data: () => ({
    selected: 0,
  }),

  computed: {
    title() {
      return this.selected > 0
        ? this.$t(`rate.titleByRate.${this.selected - 1}`)
        : this.$t('rate.title');
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value !== this.selected) {
          this.selected = value;
        }
      },
    },
    selected(selected) {
      if (selected !== this.value) {
        this.$emit('input', selected);
      }
    },
  },
};
</script>

<style lang="scss">
.user-rates {
  &__title {
    line-height: 1.3;
    @include block-caption;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    border: 0;
    padding: 0;
    background: transparent;
    color: #efefef;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}
</style>
