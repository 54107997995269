<template>
  <div
    v-if="localStaffList.length"
    class="bill-staff-list"
  >
    <bill-staff-list-selected
      v-if="hasSelected"
      :items="selectedStaffList"
      :data="data"
      @submit="handleOpen"
      @fee-amount="$emit('fee-amount', $event)"
      @fee-checked="$emit('fee-checked', $event)"
      @fee-loading="$emit('fee-loading')"
      @fee-opened="$emit('on-fee-opened')"
      @error="$emit('error')"
      @typing="$emit('typing')"
      @update="handleSelectedUpdate"
    />
    <bill-staff-list-avatar
      v-else
      :items="localStaffList"
      @submit="handleOpen"
    />

    <bill-staff-list-modal
      v-model="showModal"
      :data="data"
      :items="localStaffList"
      @submit="handleConfirm"
      @fee-amount="$emit('fee-amount', $event)"
      @fee-checked="$emit('fee-checked', $event)"
      @fee-loading="$emit('fee-loading')"
      @fee-opened="$emit('on-fee-opened')"
      @error="$emit('error')"
      @select="$emit('select', $event)"
    />
  </div>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  onMounted,
} from 'vue';
import BillStaffListAvatar from '@/components/bill/BillStaffListAvatar.vue';
import BillStaffListSelected from '@/components/bill/BillStaffListSelected.vue';
import BillStaffListModal from '@/components/bill/BillStaffListModal.vue';

const props = defineProps({
  staffList: Array,
  data: Object,
});

const emit = defineEmits([
  'form-amount',
  'fee-checked',
  'fee-loading',
  'on-fee-opened',
  'select',
  'typing',
  'error',
]);

const localStaffList = ref([]);
const selectedStaffList = ref([]);
const showModal = ref(false);

const hasSelected = computed(() => {
  return selectedStaffList.value?.length
    && selectedStaffList.value?.some(({ selected }) => selected);
});

const amountList = computed(() => {
  return localStaffList.value
    .filter((item) => item.amount)
    .reduce((acc, item) => {
      if (item.selected) {
        acc[item.payoutId] = item.amount;
      }

      return acc;
    }, {});
});

const handleOpen = () => {
  showModal.value = true;
};

const handleConfirm = (payload) => {
  localStaffList.value = payload;
  selectedStaffList.value = payload;
  emit('form-amount', {
    payload: amountList.value,
    resetTip: false,
  });
};

const handleSelectedUpdate = (payload) => {
  selectedStaffList.value = payload;
  emit('form-amount', {
    payload: amountList.value,
    resetTip: true,
  });
};

onMounted(() => {
  if (props.staffList?.length) {
    localStaffList.value = props.staffList.map((item) => ({
      ...item,
      selected: false,
    }));
  }
});
</script>
