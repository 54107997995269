<template>
  <checkout-by-apple
    v-if="type === 'apple'"
    v-bind="$attrs"
    v-on="listeners"
    :list-payment="listPayment"
    :amount="amount"
    :fee-checked="feeChecked"
    :fee-amount="feeAmount"
    :workplace-payout-id="workplacePayoutId"
    :total-amount="totalAmount"
    :merchant-id="applePayMerchantId"
  />
  <checkout-by-google
    v-else-if="type === 'google'"
    v-bind="$attrs"
    v-on="listeners"
    :list-payment="listPayment"
    :amount="amount"
    :fee-checked="feeChecked"
    :fee-amount="feeAmount"
    :workplace-payout-id="workplacePayoutId"
    :public-key="publicKey"
    :total-amount="totalAmount"
    :merchant-id="googlePayMerchantId"
  />
</template>

<script>
import api from '@/api';
import { useEventTrackerMixin } from '@/mixins/event-tracker-mixin';
import CheckoutByApple from './CheckoutByApple.vue';
import CheckoutByGoogle from './CheckoutByGoogle.vue';

export default {
  name: 'CheckoutByPay',

  components: {
    CheckoutByApple,
    CheckoutByGoogle,
  },

  props: {
    listPayment: {
      type: Array,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    feeChecked: {
      type: Boolean,
      required: true,
    },
    feeAmount: {
      type: Number,
      required: true,
    },
    staffPayoutId: {
      type: String,
      required: false,
    },
    workplacePayoutId: {
      type: String,
      required: true,
    },
    isStaff: {
      type: Boolean,
      required: true,
    },
    amplitudePaymentData: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { eventTrackerNamePrefix } = useEventTrackerMixin();

    return {
      eventTrackerNamePrefix,
    };
  },

  data: () => ({
    type: '',
    publicKey: '',
    applePayMerchantId: '',
    googlePayMerchantId: '',
  }),

  computed: {
    listeners() {
      const { submit, ...otherListeners } = this.$listeners;

      return {
        submit: (method) => this.handleSubmit(() => submit(method)),
        ...otherListeners,
      };
    },
    totalAmount() {
      const totalAmount = this.feeChecked
        ? this.amount + this.feeAmount
        : this.amount;

      return totalAmount.toFixed(2);
    },
  },

  async mounted() {
    await this.loadCheckoutPublicKey();
    await this.loadCheckoutConfiguration();

    if (window.ApplePaySession) {
      this.type = 'apple';
    } else {
      this.type = 'google';
    }
  },

  methods: {
    wrongPayload() {
      if (this.feeChecked && this.feeAmount === 0) {
        return true;
      }

      return this.listPayment.some((item) => {
        return item.amount === 0;
      });
    },
    handleSubmit(callback) {
      if (this.wrongPayload()) {
        if (this.type === 'apple') {
          this.$amplitude.event('WRONG_CHECKOUT_PAYMENT_APPLE', this.amplitudePaymentData);
        } else {
          this.$amplitude.event('WRONG_CHECKOUT_PAYMENT_GOOGLE', this.amplitudePaymentData);
        }

        window.location.href = this.$router.resolve({
          name: 'payment-failed',
          query: {
            checkout: true,
          },
        }).href;

        return;
      }

      if (this.type === 'apple') {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_CHECKOUT_APPLE_PAY_PRESS`,
          this.amplitudePaymentData,
        );
      } else {
        this.$amplitude.event(
          `${this.eventTrackerNamePrefix}_CHECKOUT_GOOGLE_PAY_PRESS`,
          this.amplitudePaymentData,
        );
      }

      callback();
    },
    async loadCheckoutPublicKey() {
      try {
        const {
          data: {
            publicKey,
          },
        } = await api.getCheckoutPaymentPublicKey({
          workplacePayoutId: this.workplacePayoutId,
          staffPayoutId: this.staffPayoutId,
        });

        this.publicKey = publicKey;
      } catch (e) {
        console.log(e);
      }
    },
    async loadCheckoutConfiguration() {
      try {
        const { data } = await api.getCheckoutConfiguration({
          workplaceId: this.workplacePayoutId,
          staffId: this.staffPayoutId,
        });

        if (data) {
          this.applePayMerchantId = data.checkout.applePaymentInfo.merchantId;
          this.googlePayMerchantId = data.checkout.googlePaymentInfo.merchantId;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
