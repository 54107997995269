import { UNKNOWN } from '@/config/staffTypes';

/**
 * Install plugin.
 *
 * @param  {Vue} Vue
*/
export default (Vue) => {
  let staffTypesForceUpdated = false;

  /**
   * Get staff type name.
   *
   * @param {string} staffType
   * @return {string}
  */
  function getStaffTypeName(staffType) {
    const name = this.$store.getters['staffTypes/staffTypeName'](staffType);

    if (name === UNKNOWN && !staffTypesForceUpdated) {
      this.$store.dispatch('staffTypes/getStaffTypes', { force: true });
      staffTypesForceUpdated = true;
    }

    return name;
  }

  Vue.prototype.$getStaffTypeName = getStaffTypeName;
};
