<template>
  <div>
    <ui-modal
      v-model="show"
      v-bind="$attrs"
      class="modal-status"
      :class="{
        [`modal-status_${status}`]: status,
      }"
      v-on="$listeners"
      :closable="false"
      layout
    >
      <template #header>
        <img
          v-if="status === 'success'"
          src="@/assets/ok.svg"
          class="modal-status__image"
          alt="Success image"
        />
        <img
          v-if="status === 'error'"
          src="@/assets/payment-failed.svg"
          class="modal-status__image"
          alt="Failed image"
        />

        <div>
          {{ $t(`modal.${status}.title`) }}
        </div>

        <div
            v-if="showThankYouFeedback"
            class="modal-status__feedback-text modal-status__subtitle"
        >
          Thanks for your feedback!
        </div>
      </template>

      <template #default>
        <div
          v-if="status === 'error'"
          class="modal-status__text"
        >
          {{ $t(`modal.${status}.text`) }}
        </div>

        <ui-receipt
          v-if="showReceipt"
          :is-loading="isLoading"
          :customer-email="customerEmail"
          class="modal-status__receipt"
          @checked="toggle"
          @send="$emit('send-receipt', $event)"
        ></ui-receipt>

        <ui-button
          v-if="showOk"
          @click="$emit('closed', $event)"
          class="modal-status__button"
          small
        >
          Ok
        </ui-button>

  <!--      <ui-socials-->
  <!--        v-if="status === 'success'"-->
  <!--        @clicked-link="clickedLink"-->
  <!--      />-->
      </template>
    </ui-modal>

    <modal-rate-us
      v-model="showModalRateUs"
      :items="feedbackLinks"
      :location="location"
      :amplitude-data="data"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { setTransactionHash } from '@/utils/transactionCollisions';
import UiModal from './UiModal.vue';
import UiReceipt from './UiReceipt.vue';
// import UiSocials from './UiSocials.vue';
import UiButton from './UiButton.vue';
import ModalRateUs from './ModalRateUs.vue';

export default {

  inheritAttrs: false,

  components: {
    UiModal,
    UiReceipt,
    UiButton,
    ModalRateUs,
  },

  props: {
    status: {
      type: String,
      required: true,
    },
    cardPay: {
      type: Boolean,
      required: true,
    },
    feedbackSent: {
      type: Boolean,
      required: true,
    },
    rate: Number,
    isLoading: Boolean,
    layout: Boolean,
    data: Object,
    feedbackLinks: Object,
    customerEmail: String,
  },

  data: () => ({
    checked: false,
    show: true,
    showModalRateUs: false,
    timeoutId: null,
  }),

  computed: {
    ...mapState('user', [
      'location',
    ]),
    typeOfReview() {
      return this.location.typeOfReview;
    },
    showReceipt() {
      return !this.cardPay && !this.feedbackSent && this.status === 'success';
    },
    showOk() {
      return !this.checked;
    },
    showFeedbackLinks() {
      const reviewTypes = ['PUBLIC_REVIEWS_ONLY', 'STANDARD_AND_PUBLIC_REVIEWS_ONLY'];

      const isReviewTypeOrRateAllowed = this.rate === 5
        || reviewTypes.includes(this.typeOfReview);

      return this.$can('subscription:tips-review-buttons-view')
        && this.feedbackLinks
        && Object.keys(this.feedbackLinks).length !== 0
        && isReviewTypeOrRateAllowed;
    },
    showFeedbackSuccess() {
      return this.showFeedbackLinks && this.status === 'success' && this.rate === 5;
    },
    showThankYouFeedback() {
      return !(!this.feedbackSent || this.showFeedbackLinks) || this.isPerfectFeedback;
    },
    isPerfectFeedback() {
      return this.feedbackSent && this.showFeedbackLinks && this.rate !== 5;
    },
  },

  methods: {
    ...mapActions('trackEvents', [
      'trackRateShow',
    ]),
    toggle(checked) {
      this.checked = !this.checked;

      this.$emit('toggle-receipt');
    },
    clickedLink(linkName) {
      this.$emit('clicked-link', linkName);
    },
    saveInfoAboutTransaction() {
      const { locationPayoutId } = this.$route.params;
      const { amount, staffId } = this.$route.query;
      setTransactionHash({ locationPayoutId, amount, staffId });
    },
  },

  created() {
    this.saveInfoAboutTransaction();
  },

  mounted() {
    this.timeoutId = setTimeout(() => {
      if (!this.showFeedbackLinks) {
        return;
      }

      this.showModalRateUs = true;
    }, 1000);
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },

  watch: {
    showModalRateUs(show) {
      if (show) {
        this.trackRateShow();
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  .modal-status {
    text-align: center;

    &__image {
      margin: 0 auto;
      margin-bottom: 39px;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 35px;
    }

    &__feedback-text {
      font-size: 14px;
      line-height: 20px;
      color: #808080;
      margin-bottom: 35px;
      font-weight: 400;
    }

    &__button {
      display: inline-block;
    }

    &_error {
      color: #a01212;
    }

    &__receipt .ui-checkbox {
      display: flex;
      justify-content: center;
    }

    &__subtitle {
      margin-top: 12px;
    }
  }

  .modal-status .ui-modal-wrapper.opened {
    background-color: $background;
  }
</style>
