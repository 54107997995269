<template>
  <div
    class="user-avatar"
    :class="{
      [`user-avatar_${size}`]: size,
      'user-avatar_rectangle': rectangle,
      'user-avatar_borderless': borderless,
    }"
  >
    <img class="user-avatar__image" :src="image" />
  </div>
</template>

<script>
import api from '@/api';

const memoryCache = {};
const noimage = require('@/assets/noimage.png');

export default {

  props: {
    payoutId: {
      type: String,
      required: true,
    },
    partnerId: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: '',
    },
    rectangle: {
      type: Boolean,
    },
    borderless: {
      type: Boolean,
    },
  },

  data: () => ({
    image: noimage,
  }),

  methods: {
    isBlobValidImage(blob) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = URL.createObjectURL(blob);
      });
    },
  },

  async mounted() {
    if (this.partnerId) {
      if (this.partnerId in memoryCache) {
        this.image = memoryCache[this.partnerId];

        return;
      }
    } else if (this.payoutId in memoryCache) {
      this.image = memoryCache[this.payoutId];

      return;
    }

    const method = this.partnerId ? 'getPartnerAvatar' : 'getAvatar';
    const payload = this.partnerId
      ? { partnerId: this.partnerId, payoutId: this.payoutId }
      : this.payoutId;

    try {
      const { data } = await api[method](payload);
      const isImageValid = await this.isBlobValidImage(data);

      if (isImageValid) {
        this.image = URL.createObjectURL(data);
      }
      this.$emit('status', isImageValid);
    } catch (e) {
      // noop
    }

    if (this.partnerId) {
      memoryCache[this.partnerId] = this.image;
    } else {
      memoryCache[this.payoutId] = this.image;
    }
  },
};
</script>

<style lang="scss">
.user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;

  &_large {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }

  &_medium {
    width: 90px;
    min-width: 90px;
    height: 90px;
  }

  &_small {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }

  &_tiny {
    width: 76px;
    height: 76px;
    border: none;
  }

  &_extra-small {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }

  &_micro {
    width: 40px;
    height: 40px;
    border: none;
  }

  &_macro {
    width: 48px;
    height: 48px;
    border: none;
  }

  &_rectangle {
    border-radius: 0px;
  }

  &_rectangle img {
    border-radius: 0px;
  }

  &__image {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;
  }

  &_borderless {
    border: none;
  }
}
</style>
