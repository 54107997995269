<template>
  <ui-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    #default="{ close }"
    @opened="handleOpened"
    @closed="handleClosed"
  >
    <div class="bill-staff-list-modal">
      <div class="bill-staff-list-modal__title">
        Please choose a specialist<br>you would like to tip
      </div>

      <ui-search
        v-model="filter"
        :placeholder="$t('search.inputPlaceholder')"
        class="bill-staff-list-modal__search-filter"
      />

      <div class="bill-staff-list-modal__items">
        <user-staff
          v-for="item in filteredStaffList"
          :key="item.payoutId"
          :staff="item"
          :selected="item.selected"
          size="macro"
          selectable
          transparent
          multipay
          horizontal
          @click="handleSelectStaff(item.payoutId)"
        />
      </div>

      <ui-tips
        class="bill-staff-list-modal__tips"
        :ab-variant="data.abVariant"
        :tips="data.tips"
        :currency="data.currency"
        :selected-tip-id="selectedTip.id"
        @select="handleSelectTip"
      />

      <user-amount
        v-if="isFeeVisible"
        class="bill-staff-list-modal__fee"
        :value="totalAmount"
        :feeChecked="data.feeChecked"
        :fee-amount.sync="feeAmountSumValue"
        :currency="data.currency"
        :payout-id="data.locationPayoutId"
        hide-input
        :api-version="data.apiVersion"
        :min-tips-amount="data.minTipsAmount"
        @fee-opened="$emit('on-fee-opened')"
        @update:feeChecked="$emit('fee-checked', $event)"
        @update:error="$emit('error')"
        @update:fee-loading="$emit('fee-loading')"
      />

      <div class="bill-staff-list-modal__controls">
        <ui-button
          class="bill-staff-list-modal__controls-submit"
          @click="handleSubmit(close)"
        >
          Confirm
        </ui-button>

        <ui-button
          class="bill-staff-list-modal__controls-cancel"
          link
          @click="close"
        >
          Cancel
        </ui-button>
      </div>
    </div>
  </ui-bottom-sheet>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { isEmpty } from 'lodash';
import getFio from '@/utils/getFio';
import UiBottomSheet from '@/components/UiBottomSheet.vue';
import UiButton from '@/components/UiButton.vue';
import UiSearch from '@/components/UiSearch.vue';
import UiTips from '@/components/UiTips.vue';
import UserAmount from '@/components/UserAmount.vue';
import UserStaff from '@/components/UserStaff.vue';

const props = defineProps({
  items: Array,
  data: Object,
});

const emit = defineEmits([
  'select',
  'submit',
  'on-fee-opened',
  'error',
  'fee-checked',
  'fee-loading',
  'fee-amount',
]);

const feeAmountSumValue = ref(0);
const localStaffList = ref([]);
const filter = ref('');
const selectedTip = ref({});

const selectedStaffList = computed(() => {
  return localStaffList.value.filter(({ selected }) => selected);
});

const filteredStaffList = computed(() => {
  return localStaffList.value.filter((item) => {
    const search = filter.value.toLowerCase();
    const value = `${item.nickName || getFio(item)}`.toLowerCase();

    return value.includes(search) && item.payoutId;
  });
});

const totalAmount = computed(() => {
  return (selectedTip.value?.value || 0) * (selectedStaffList.value?.length || 0);
});

const isFeeVisible = computed(() => {
  return !isEmpty(selectedStaffList.value)
    && !isEmpty(selectedTip.value);
});

watch(totalAmount, (value) => {
  feeAmountSumValue.value = value;
});

const handleSelectStaff = (payoutId) => {
  const index = localStaffList.value.findIndex((item) => item.payoutId === payoutId);
  const isSelected = localStaffList.value[index].selected;
  const selectedTipValue = selectedTip.value?.value;

  if (index !== -1) {
    localStaffList.value[index].selected = !isSelected;

    if (selectedTipValue) {
      localStaffList.value[index].amount = selectedTipValue;
    }
  }
};

const handleSelectTip = (payload) => {
  localStaffList.value.forEach((item) => {
    if (item.selected) {
      item.amount = payload.value;
    }
  });

  selectedTip.value = selectedTip.value?.id === payload.id ? {} : payload;
};

const handleSubmit = (close) => {
  if ((!selectedTip.value?.id && selectedStaffList.value?.length)
    || (selectedTip.value?.id && !selectedStaffList.value?.length)
  ) {
    close();
    return;
  }

  emit('select', selectedTip.value);
  emit('fee-amount', feeAmountSumValue.value);
  emit('submit', localStaffList.value);
  close();
};

const handleOpened = () => {
  localStaffList.value = props.items;
};

const handleClosed = () => {
  localStaffList.value = [];
};
</script>
