<template>
  <base-layout
    class="location__layout"
    :style="{
      '--location-button-color': colors.buttonColor,
      '--location-background-color': colors.backgroundColor,
    }"
  >
    <div class="location__wrapper">
      <location-background
        v-if="backgroundInfo"
        :background-link="backgroundInfo.link"
      />
      <ui-spinner
        v-if="isLoading"
      />
      <div
        v-else
        class="location"
      >
        <div class="location__body">
          <div class="location__info">
            <div class="location__avatar">
              <user-avatar :payout-id="payoutId" />
            </div>
            <div v-if="location.name" class="location__name">
              {{ location.name }}
            </div>
          </div>
          <div class="location__actions">
            <ui-button
              v-if="showMenu"
              type="primary"
              class="location__button location__button--primary"
              icon="call"
              @click="goToMenu"
            >
              <ui-icon name="call" />
              Online menu
            </ui-button>
            <ui-button
              transparent
              class="location__button location__button--secondary"
              @click="goToTips"
            >
              Leave a tip and review
            </ui-button>
          </div>
        </div>
        <div class="location__footer">
          <div class="location__socials">
            <a
              v-if="contactInfo.instagramUrl"
              :href="contactInfo.instagramUrl"
              target="_blank"
            >
              <ui-icon-button name="instagram" size="28px" />
            </a>
            <a
              v-if="contactInfo.facebookUrl"
              :href="contactInfo.facebookUrl"
              target="_blank"
            >
              <ui-icon-button name="facebook" size="28px" />
            </a>
            <a
              v-if="contactInfo.phoneNumber"
              :href="`tel:${contactInfo.phoneNumber}`"
              class="location__phone-link"
            >
              <ui-icon-button
                name="phone"
                size="16px"
              />
              <div class="location__phone">
                {{ contactInfo.phoneNumber }}
              </div>
            </a>
          </div>
          <div v-if="contactInfo.address" class="location__address">
            {{ contactInfo.address }}
          </div>
        </div>
      </div>
    </div>
    <bottom-section
      no-social-icons
    />
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import LocationBackground from '@/components/location/LocationBackground.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import UserAvatar from '@/components/UserAvatar.vue';
import UiButton from '@/components/UiButton.vue';
import UiIconButton from '@/components/UiIconButton.vue';
import BottomSection from '@/components/BottomSection.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import UiIcon from '@/components/UiIcon.vue';

export default {
  props: {
    payoutId: {
      type: String,
      required: true,
    },
  },

  components: {
    UiIcon,
    UiSpinner,
    BottomSection,
    UiIconButton,
    UiButton,
    UserAvatar,
    LocationBackground,
    BaseLayout,
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.menu.isLoading,
      backgroundInfo: (state) => state.menu.backgroundInfo,
      contactInfo: (state) => state.menu.contactInfo,
      location: (state) => state.user.location,
      colors: (state) => state.menu.colors,
    }),
    ...mapGetters({
      previewMenuLink: 'menu/previewMenuLink',
      menuItems: 'menu/sortedMenuItems',
    }),
    showMenu() {
      return this.menuItems?.length;
    },
  },

  methods: {
    ...mapActions({
      loadLocation: 'user/loadLocation',
      loadMenuInfo: 'menu/loadMenuInfo',
    }),
    goToMenu() {
      this.$router.push({ name: 'menu', query: this.$route.query });
    },
    goToTips() {
      this.$router.push({ name: 'home', query: this.$route.query });
    },
  },

  created() {
    sessionStorage.setItem('firstVisitChecked', 'true');
    if (!this.location.payoutId) {
      this.loadLocation({ payoutId: this.payoutId });
    }
    this.loadMenuInfo({ payoutId: this.payoutId });
  },
};
</script>
