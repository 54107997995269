export default {
  "dialogue": {
    "notActive": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das digitale Trinkgeld ist in dieser Location derzeit nicht verfügbar. Bitte informieren Sie das Management oder den Administrator."])}
    },
    "paymentFailed": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung fehlgeschlagen"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas lief schief"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nochmal versuchen"])}
    },
    "submitPay": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’ve just left a tip"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You’ve just left a tip. Are you sure you want to make another payment for ", _interpolate(_named("total")), "?"])},
      "submit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay ", _interpolate(_named("total")), " anyway"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "search": {
    "staffsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie einen Mitarbeiter aus, dem Sie ein Trinkgeld geben möchten"])},
    "groupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen sie den Service, für den Sie Trinkgeld geben möchten"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen suchen"])},
    "multipleSpecialistLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie mehrere Spezialisten aus"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "chooseOrTipManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Trinkgeld manuell auswählen oder eingeben"])}
  },
  "form": {
    "amountPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Trinkgeld ein"])},
    "amountError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Trinkgeldbetrag muss mindestens ", _interpolate(_named("money")), " betragen"])},
    "fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+", _interpolate(_named("money")), " Kartengebühren"])},
    "agree": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme den"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsbedingungen"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung zu"])},
      
    ],
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung anfordern"])},
    "commentPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Kommentar abgeben"])},
    "button": {
      "onlyFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Feedback abgeben"])},
      "payCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Karte zahlen"])}
    },
    "amountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])}
  },
  "rate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerten Sie Ihren Besuch"])},
    "titleByRate": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrecklich"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlecht"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ordnung"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgezeichnet"])},
      
    ]
  },
  "reviews": {
    "titleBad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist schief gelaufen?"])},
    "titleGood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was hat Ihnen am besten gefallen?"])}
  },
  "modal": {
    "fee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum werde ich zur Zahlung von Kartengebühren aufgefordert?"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind ein kleines Unternehmen, daher sind die Kosten für die Bearbeitung dieser Kartentransaktionen unverhältnismäßig hoch. Wir erheben von unseren Benutzern lediglich eine geringe Gebühr, die weniger als ein Drittel der Transaktionskosten beträgt."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indem Sie zur Deckung dieser Gebühren als Teil Ihres Trinkgeldes beitragen, stellen Sie sicher, dass der Kellner den vollen Betrag erhält."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn wir wachsen, werden diese Kosten sinken, und wir können diese Einsparungen weitergeben."])},
        
      ],
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank!"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechnung wurde an Ihre E-Mail-Adresse versandt"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagen Sie es weiter!"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte hinterlassen Sie uns eine Bewertung!"])},
        
      ]
    },
    "sorry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Ihre Rezension"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns leid, dass Sie mit Ihrem Aufenthalt bei uns nicht zufrieden waren. Dürfen wir Sie kontaktieren, um es das nächste Mal besser zu machen?"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email oder Telefonnummer"])},
      "button": {
        "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
        "notNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht jetzt"])}
      }
    },
    "error": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppla!"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen :("])}
    },
    "terms": {
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done!"])}
    }
  },
  "social": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefällt Ihnen EasyTip?<br>Folgen Sie uns"])}
  },
  "Atmosphere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphäre"])},
  "Bathroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badezimmer"])},
  "Cleanliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauberkeit"])},
  "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
  "Drinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getränke"])},
  "Food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essen"])},
  "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
  "Music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musik"])},
  "Prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Table plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tisch-plan"])},
  "Bathroom was dirty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Badezimmer war schmutzig"])},
  "Bathroom could be cleaner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badezimmer könnte sauberer sein"])},
  "Staff were rude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Personal war unhöflich"])},
  "Staff were unhelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Personal war nicht hilfsbereit"])},
  "Staff were adequate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Personal war okay"])},
  "Dinning area was filthy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Speisesaal war schmutzig"])},
  "Dinning area was dirty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essbereich war schmutzig"])},
  "Dinning area not clean enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essbereich war nicht sauber genug"])},
  "Food was inedible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittel waren ungenießbar"])},
  "Food was poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Essen war schlecht"])},
  "Food was alright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Essen war in Ordnung"])},
  "Friendliness was lacking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service war nicht freundlich"])},
  "Service non-existant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service nicht existent"])},
  "Service was painful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Service war grauenhaft"])},
  "Service was slow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Service war langsam"])},
  "Ridiculously overpriced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lächerlich überteuert"])},
  "Overpriced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überteuert"])},
  "Pricey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teuer"])},
  "Atmosphere was depressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphäre bedrückend"])},
  "Atmosphere was not right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Atmosphäre stimmte nicht"])},
  "Atmosphere was flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Atmosphäre war fade"])},
  "Menu choice disappointing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menüauswahl war enttäuschend"])},
  "Menu needs revision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü muss überarbeitet werden"])},
  "Menu needs improvement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü muss verbessert werden"])},
  "Friendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freundlichkeit"])},
  "Hospitality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistung"])},
  "Kids friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinderfreundlich"])},
  "Entertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhaltung"])},
  "Comfort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komfort"])},
  "Entertainment not available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entertainment nicht verfügbar"])},
  "Entertainment choice very poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhaltungsangebot sehr schlecht"])},
  "Entertainment choice quite limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhaltungsangebot war recht begrenzt"])},
  "Hospitality was awful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gastfreundschaft war furchtbar"])},
  "Hospitality was lacking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es fehlte die Gastfreundschaft"])},
  "Hospitality can be improved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastfreundschaft kann verbessert werden"])},
  "Staff not friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal was unfreundlich"])},
  "Staff not engaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal nicht engagiert"])},
  "Furniture was very uncomfortable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mobiliar war sehr unbequem"])},
  "Furniture was uncomfortable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Möbel waren unbequem"])},
  "Furniture was alright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möbel waren in Ordnung"])},
  "Design was outdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Design ist veraltet"])},
  "Design was questionable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Design war fragwürdig"])},
  "Design was ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Design war okay"])},
  "Work station was dirty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Arbeitsbereich war verschmutzt"])},
  "Work station not clean enough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsplatz nicht sauber genug"])},
  "Atmosphere was not welcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphäre war nicht einladend"])},
  "Atmosphere was underwhelming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Atmosphäre war nicht überzeugend"])},
  "Pet friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierfreundlichkeit"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertschätzung"])},
  "Care products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflegeprodukte"])},
  "Delayed appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminverzögerung"])},
  "Cleanliness concerns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangelhafte Sauberkeit"])},
  "Service was rushed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service war gehetzt"])},
  "Comfort could be improved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Komfort ist ausbaufähig"])},
  "cookiePolicy": {
    "weAreKeenly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind uns des in uns gesetzten Vertrauens und unserer Verantwortung, Ihre Privatsphäre zu schützen, sehr bewusst. Als Teil dieser Verantwortung informieren wir Sie darüber, welche Informationen wir mit Hilfe von Cookies sammeln, wenn Sie unsere Websites nutzen, warum wir sie sammeln und wie wir sie verwenden, um Ihre Erfahrung zu verbessern."])},
    "introduction": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINLEITUNG"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Tip Ltd (“wir“ oder “uns“ oder “unser“) kann Cookies, Web Beacons, tracking pixel und andere Tracking-Technologien verwenden, wenn Sie unsere Website <a href=\"www.easytip.net\">www.easytip.net</a> und deren Subdomains besuchen, unabhängig davon über welche Technik oder welche ggf. mobile Anwendung wir die Informationen bereitstellen (zusammenfassend die “Website“), um die Website anzupassen und Ihre Erfahrung zu verbessern."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir behalten uns das Recht vor, diese Cookie-Richtlinie jederzeit zu ändern. Wir werden Sie über alle Änderungen informieren, indem wir das Datum der “letzten Aktualisierung“ dieser Cookie-Richtlinie aktualisieren. Jegliche Änderungen oder Modifikationen werden sofort mit der Veröffentlichung der aktualisierten Cookie-Richtlinie auf der Website wirksam, und Sie verzichten auf das Recht, über jede derartige Änderung oder Modifikation gesondert informiert zu werden."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen Ihnen, diese Cookie-Richtlinie regelmäßig zu prüfen, um sich über Aktualisierungen zu informieren. Änderungen in einer überarbeiteten Cookie-Policy gelten als von Ihnen akzeptiert, wenn Sie die Website nach dem Datum der Veröffentlichung einer solchen überarbeiteten Cookie-Richtlinie weiter nutzen."])},
        
      ]
    },
    "useOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERWENDUNG VON COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein \"Cookie\" ist eine Informationskette, die Ihnen eine eindeutige Kennung zuweist, die wir auf Ihrem Computer speichern. Ihr Browser stellt unserer Webseite diese eindeutige Kennung zur Verfügung, wenn Sie sie aufrufen. Wir verwenden Cookies unter anderem, um die festzustellen auf welchen Seiten unserer Webseite sie waren, Registrierungsinformationen aufzuzeichnen, Ihre Benutzereinstellungen zu speichern, Sie auf der Site angemeldet zu halten, Zahlungsabwicklungen zu erleichtern, Aktionen und Ereignisse auf der Site aufzuzeichnen und die von Ihnen besuchten Seiten zu verfolgen."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keines der für unsere Sites verwendeten Cookies kann dazu verwendet werden, Sie persönlich zu identifizieren. Sie werden nur verwendet, um die Site für Sie besser zu machen. Um mehr über Cookies und deren Verwaltung zu erfahren, besuchen Sie <a href=\"http://aboutcookies.org\">http://aboutcookies.org/</a> oder <a href=\"https://www.allaboutcookies.org\">https://www.allaboutcookies.org/</a>."])},
        
      ]
    },
    "typesOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARTEN VON COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Arten von Cookies können verwendet werden, wenn Sie die Website besuchen:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies sind Cookies, die für den Betrieb einer Website und für Aspekte wie Authentifizierung, Betrugsprävention und -erkennung, Sicherheit und Funktionalität unbedingt erforderlich sind. Ohne diese Cookies würde diese Website nicht richtig funktionieren. Dementsprechend bitten wir Sie nicht um Ihre Einwilligung für diese Cookies. Für alle anderen Cookies ist Ihre Einwilligung erforderlich."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse-Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytics-Cookies überwachen, wie Benutzer auf die Website gelangt sind und wie sie mit der Website interagieren und sich auf ihr bewegen, sobald sie auf der Website sind. Diese Cookies geben uns Aufschluss darüber, welche Funktionen der Website am besten funktionieren und welche Funktionen der Website verbessert werden können."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierungs-Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierungs-Cookies werden verwendet, um wiederholte Besucher der Website zu erkennen. Wir verwenden diese Cookies, um Ihren Browserverlauf, die von Ihnen besuchten Seiten sowie Ihre Einstellungen und Präferenzen bei jedem Besuch der Website aufzuzeichnen, wir erfassen nicht, welche anderen Webseiten oder Dienste Sie besuchen."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbe-Cookies"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbe-Cookies werden von Werbetreibenden und Ad-Servern auf Ihrem Computer platziert, um Ihnen Werbung anzuzeigen, die für Sie höchstwahrscheinlich von Interesse ist. Diese Cookies ermöglichen es Werbetreibenden und Anzeigenservern, Informationen über Ihre Besuche auf der Website und anderen Websites zu sammeln, die an einen bestimmten – den von Ihnen genutzten – Computer gesendeten Anzeigen zu ändern und zu verfolgen, wie oft und von wem eine Anzeige angesehen wurde. Diese Cookies sind mit einem Computer verknüpft und sammeln keine persönlichen Informationen über Sie."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies von Drittanbietern"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies von Drittanbietern können von Unternehmen, die bestimmte von uns angebotene Dienste betreiben, auf Ihrem Computer platziert werden, wenn Sie die Website besuchen. Diese Cookies ermöglichen es den Drittanbietern, bestimmte Informationen über Sie zu sammeln und zu verfolgen. Diese Cookies können manuell in Ihrem Browser deaktiviert werden."])},
        
      ]
    },
    "CookiesUsedByQrTip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERWENDETE COOKIES"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweck"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])}
    },
    "ControlOfCookies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IHRE KONTROLLE DER COOKIES"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Browser sind so eingestellt, dass sie Cookies standardmäßig akzeptieren. Sie können jedoch Cookies in den Einstellungen Ihres Browsers entfernen oder ablehnen. Bitte beachten Sie, dass eine solche Maßnahme die Verfügbarkeit und Funktionalität der Website beeinträchtigen kann."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen darüber, wie Sie Cookies kontrollieren können, finden Sie in den Einstellungen Ihres Browsers oder Geräts oder unter den folgenden Links:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darüber hinaus können Sie einige Cookies von Drittanbietern über das <a href=\"optout.networkadvertising.org/#!/\">Opt-Out-Tool der Network Advertising Initiative</a> ablehnen."])},
        
      ]
    },
    "OtherTrackingTechnologies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANDERE TRACKING-TECHNOLOGIEN"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich zu Cookies können wir Web-Beacons, Pixel-Tags und andere Tracking-Technologien auf der Website verwenden, um die Website anzupassen und Ihre Erfahrung zu verbessern. Ein \"Web-Beacon\" oder \"Pixel-Tag\" ist ein kleines Objekt oder Bild, das in eine Webseite oder E-Mail eingebettet ist. Sie werden verwendet, um die Anzahl der Benutzer zu verfolgen, die bestimmte Seiten besucht und E-Mails angesehen haben, und um andere statistische Daten zu erfassen. Sie erfassen nur eine begrenzte Anzahl von Daten, wie z. B. eine Cookie-Nummer, Uhrzeit und Datum des Seiten- oder E-Mail-Aufrufs und eine Beschreibung der Seite oder E-Mail, auf der sie sich befinden. Web-Beacons und Pixel-Tags können nicht abgelehnt werden. Sie können jedoch ihre Verwendung einschränken, indem Sie die Cookies steuern, die mit ihnen interagieren."])},
        
      ]
    },
    "privacyPolicy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIVACY-POLITIK"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen darüber, wie wir die durch Cookies und andere Tracking-Technologien gesammelten Informationen verwenden, finden Sie in unserer Datenschutzrichtlinie auf der Website. Diese Cookie-Richtlinie ist Teil unserer Datenschutzrichtlinie und ist in diese integriert. Durch die Nutzung der Website erklären Sie sich mit dieser Cookie-Richtlinie und unserer Datenschutzrichtlinie einverstanden."])},
        
      ]
    },
    "contactUs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
      "text": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Fragen oder Kommentare zu dieser Cookie-Richtlinie haben, kontaktieren Sie uns bitte unter:"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Tip Ltd<br/>Unit 3, 8 Greenock Road,<br/>London W3 8DU<br/>United Kingdom<br/><a href=\"mailto:info", "@", "easytip.net\">info", "@", "easytip.net</a><br/>"])},
        
      ]
    },
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
  },
  "validationErrors": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld muss ausgefüllt sein"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine gültige E-Mail-Adresse ein"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Kommentar muss mindestens ", _interpolate(_named("length")), " Zeichen enthalten"])}
  }
}