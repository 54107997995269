<template>
  <div class="location-card">
    <user-avatar
      :payout-id="payoutId"
      class="location-card__avatar"
      size="medium"
      borderless
    />

    <div class="location-card__info">
      <div class="location-card__name">
        {{ name }}
      </div>

      <div v-if="showTeamTips" class="location-card__type">
        Team tips
      </div>

      <ui-quote
        v-if="wish"
        class="location-card__wish"
      >
        {{ wish }}
      </ui-quote>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import UserAvatar from '@/components/UserAvatar.vue';
import UiQuote from '@/components/UiQuote.vue';

const props = defineProps({
  payoutId: { type: String },
  name: { type: String },
  showTeamTips: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();
const wish = computed(() => store.getters['user/wish']);
</script>
