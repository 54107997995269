<template>
  <div
    class="ui-field"
    :class="[classes, {
      _focused: focused,
      _disabled: disabled,
      [`ui-field--${kind}`]: kind,
      [`ui-field--${size}`]: size,
      'ui-field--outlined': outlined,
      'ui-field--clearable': showClear,
      'ui-field--label-top': labelTop,
      'ui-field--has-label': hasLabel,
      'ui-field--has-error': hasError,
      'ui-field--bordered': bordered,
    }]"
  >
    <!-- Field label -->
    <label v-if="label" class="ui-field__label">
      {{ label }}
      <span v-if="required" class="ui-field__required">*</span>
    </label>

    <!-- Field input container -->
    <div class="ui-field__body">
      <ui-spinner
        v-if="isLoading"
        size="12"
      />

      <template v-else>
        <!-- Field input -->
        <div v-if="hasPrefix" class="ui-field__prefix">
          <slot name="prefix">
            {{ prefix }}
          </slot>
        </div>

        <slot
          v-bind="{
            ...$attrs,
            id,
            name,
            modelValue: outterValue,
            required,
            disabled,
            placeholder,
            handleInput,
            handleChange,
            handleBlur: handleChange,
          }"
        />

        <div v-if="hasSuffix" class="ui-field__suffix">
          <slot name="suffix">
            {{ suffix }}
          </slot>
        </div>

        <!-- Clear button -->
        <div
          v-if="showClear"
          class="ui-field__clear"
          @click="handleClear"
        >
          &times;
        </div>
      </template>
    </div>

    <!-- Error message -->
    <div v-if="showErrorMessage" class="ui-field__error">
      <slot name="error">
        {{ errorMessage }}
      </slot>
    </div>

    <!-- Help message -->
    <div v-if="showHelp" class="ui-field__help">
      {{ help }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref, toRef, useSlots, watch } from 'vue';
import { uniqueId } from 'lodash';
import { useField } from 'vee-validate';
import UiSpinner from '@/components/UiSpinner.vue';

const props = defineProps({
  value: null,
  classes: {
    type: String,
    default: '',
    validator: (value) => {
      return [
        '',
        'error',
        'warning',
        'success',
        'draft',
        'info',
        'incorrect',
        'incorrect-info',
      ].includes(value);
    },
  },
  label: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: () => uniqueId('id-'),
  },
  name: {
    type: String,
    default: () => uniqueId('field-'),
  },
  placeholder: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  suffix: {
    type: String,
    default: '',
  },
  kind: {
    type: String,
    default: '',
    validator: (value) => {
      return [
        '',
        'secondary',
      ].includes(value);
    },
  },
  size: {
    type: String,
    default: '',
    validator: (value) => {
      return [
        '',
        'small',
        'large',
        'huge',
      ].includes(value);
    },
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  hasValue: {
    type: Boolean,
    default: false,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  hideErrorMessage: {
    type: Boolean,
    default: false,
  },
  help: {
    type: String,
    default: '',
  },
  outlined: Boolean,
  trim: Boolean,
  validationValue: null,
  validationRules: {
    type: [String, Object],
    default: '',
  },
  validationMode: {
    type: String,
    validator: (value) => {
      return [
        'aggressive',
        'lazy',
        'off',
      ].includes(value);
    },
    default: 'aggressive',
  },
});

const outterValue = ref();
const modelValue = toRef(props, 'value');
const emit = defineEmits(['input']);

// Slot scope and others.
const slots = useSlots();
const hasPrefix = computed(() => props.prefix || Boolean(slots.prefix));
const hasSuffix = computed(() => props.suffix || Boolean(slots.suffix));
const showHelp = computed(() => props.help.length > 0);
const classes = toRef(props, 'classes');

// Model value.
const hasLabel = computed(() => props.label.length > 0);
const hasPlaceholder = computed(() => props.placeholder.length > 0);
const labelTop = computed(() => props.hasValue || hasPlaceholder.value || props.focused || hasPrefix.value);

const handleUpdate = (value) => {
  if (modelValue.value !== value) {
    const trimmedValue = props.trim ? value.trim() : value;

    outterValue.value = trimmedValue;
    emit('input', trimmedValue);
  }
};

// Validation and errors.
const hasValidationValue = computed(() => typeof props.validationValue !== 'undefined');
const validationRules = toRef(props, 'validationRules');
const name = toRef(props, 'name');
const hideErrorMessage = toRef(props, 'hideErrorMessage');
const validator = useField(name, validationRules, {
  modelPropName: hasValidationValue.value ? 'validationValue' : 'value',
  validateOnValueUpdate: false,
  validateOnMount: false,
  syncVModel: false,
});

const errorMessage = computed(() => validator.errorMessage.value || props.error);
const showErrorMessage = computed(() => typeof errorMessage.value === 'string' && !hideErrorMessage.value);
const hasError = computed(() => errorMessage.value !== false);

watch(() => {
  if (hasValidationValue.value) {
    return props.validationValue;
  }

  return modelValue.value;
}, (newValue) => {
  validator.resetField({
    value: newValue,
  });
}, { immediate: true });

const handleInput = (e) => {
  if (!hasValidationValue.value) {
    validator.handleChange(e, props.validationMode === 'aggressive');
  }

  handleUpdate(e.target.value);
};

const handleChange = (e) => {
  if (!hasValidationValue.value) {
    validator.handleChange(e, props.validationMode === 'lazy');
  }
};

// Clearing.
const showClear = computed(() => props.clearable && props.hasValue);

const handleClear = () => {
  if (!hasValidationValue.value && props.validationMode === 'off') {
    validator.setValue('');
  }

  handleUpdate('');
};
</script>
