<template>
  <ui-bottom-sheet
    v-model="config.show"
    :name="name"
    :swipeable="config.swipeable"
    #default="{ close }"
    @opened="handleOpened"
  >
    <div
      class="ui-alert"
      :class="{
        [`ui-alert--${config.status}`]: config.status,
        'ui-alert--center': config.center,
        'ui-alert--small': config.small,
      }"
    >
      <div v-if="isTypeLoading" class="ui-alert__loading">
        <ui-spinner size="64" />
      </div>

      <div v-if="config.title" class="ui-alert__title">
        {{ config.title }}
      </div>

      <div class="ui-alert__content">
        <component
          v-if="config.component"
          :is="config.component"
          v-bind="config.componentProps"
        />
        <template v-else>
          <ui-icon
            v-if="icon"
            :name="icon"
            size="20"
            class="ui-alert__icon"
          />
          <span
            v-html="message"
            class="ui-alert__text"
          />
        </template>
      </div>

      <div class="ui-alert__actions">
        <ui-button
          v-if="!isTypeLoading"
          wide
          :is-loading="isOkLoading"
          :disabled="isCancelLoading"
          @click="handleOk(close)"
        >
          {{ okButton }}
        </ui-button>

        <ui-button
          v-if="isTypeConfirm"
          wide
          cancel
          small
          :is-loading="isCancelLoading"
          :disabled="isOkLoading"
          @click="handleCancel(close)"
        >
          {{ cancelButton }}
        </ui-button>
      </div>
    </div>
  </ui-bottom-sheet>
</template>

<script setup>
import { uniqueId } from 'lodash';
import { computed, ref } from 'vue';
import UiButton from '@/components/UiButton.vue';
import UiIcon from '@/components/UiIcon.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import UiBottomSheet from '@/components/UiBottomSheet.vue';
import { AlertType } from '@/store/modules/alert';
import { useStore } from 'vuex';

const props = defineProps({
  name: {
    type: String,
    default: () => uniqueId('vbs-alerts-'),
  },
});

const store = useStore();

const config = computed(() => store.state.alert.config || {});

// Default config parameters.
const icon = computed(() => {
  if (config.value.icon) {
    return config.value.icon;
  }

  switch (config.value.status) {
    case 'success':
      return 'check-round';
    case 'error':
      return 'square-exclamation';
    case 'pending':
      return 'hourglass';
    case 'info':
      return 'circle-exclamation-small';
    default:
      return '';
  }
});

const message = computed(() => {
  if (config.value.message) {
    return config.value.message;
  }

  if (config.value.type === AlertType.CONFIRM) {
    return 'Please confirm you want to<br/>perform something';
  }

  return '';
});

const okButton = computed(() => {
  if (config.value.okButton) {
    return config.value.okButton;
  }

  if (config.value.type === AlertType.CONFIRM) {
    return 'Confirm';
  }

  return 'Ok';
});

const cancelButton = computed(() => {
  if (config.value.cancelButton) {
    return config.value.cancelButton;
  }

  return 'Cancel';
});

// Loading and handling.
const isTypeLoading = computed(() => config.value.type === AlertType.LOADING);
const isTypeConfirm = computed(() => config.value.type === AlertType.CONFIRM);
const isOkLoading = ref(false);
const isCancelLoading = ref(false);

const handleOpened = () => {
  isOkLoading.value = false;
  isCancelLoading.value = false;
};

const handleOk = async (close) => {
  config.value.resolve(true);

  if (config.value.okClick) {
    isOkLoading.value = true;

    try {
      await config.value.okClick();
    } finally {
      isOkLoading.value = false;
    }
  }

  close();
};

const handleCancel = async (close) => {
  config.value.resolve(false);

  if (config.value.cancelClick) {
    isCancelLoading.value = true;

    try {
      await config.value.cancelClick();
    } finally {
      isCancelLoading.value = false;
    }
  }

  close();
};
</script>

<style lang="scss">
  .ui-alert {
    font-family: 'Roboto', sans-serif;

    &__actions {
      @include flex-column(stretch, center, 20px);
      margin-bottom: 20px;
    }

    &__loading {
      color: $ui-alert-loading-color;
      margin-top: 20px;
      margin-bottom: 24px;
      text-align: center;
    }

    &__content {
      @include flex-row(stretch, center, 12px);
      margin-bottom: 20px;
    }

    &__title {
      @include font(20px, 24px, 500);
      text-align: center;
      margin-bottom: 20px;
    }

    &__text {
      @include font(16px, 24px, 400);
      color: $ui-alert-text-color;
    }

    &__icon {
      margin-top: 3px;
      margin-bottom: auto;
    }

    &--success &__icon {
      color: $ui-alert-success-icon-color;
    }
    &--error &__icon {
      color: $ui-alert-error-icon-color;
    }
    &--pending &__icon {
      color: $ui-alert-pending-icon-color;
    }
    &--info &__icon {
      color: $ui-alert-info-icon-color;
    }

    &--center &__content {
      justify-content: center;
    }
    &--center &__text {
      text-align: center;
    }

    &--small &__text {
      @include font(12px, 20px, 400);
    }
  }
</style>
