<template>
   <div class="document">
    <h1 class="title">EASYTIP DATENSCHUTZERKLÄRUNG</h1>
    <span class="updated-date">
      <b>Zuletzt aktualisiert: 08.12.2021</b>
    </span>
    <h2 id="section-1">1. DATENSCHUTZRICHTLINIE</h2>
    <p>
      In dieser Datenschutzrichtlinie (die “<b>EasyTip-Datenschutzrichtlinie</b>”) informieren wir Sie darüber, wie QR Tip Ltd, eine Gesellschaft mit beschränkter Haftung (Gesellschaftsnummer 12646560), die nach englischem Recht gegründet wurde und deren eingetragene Adresse Unit 3, 8 Greenock Road, London W3 8DU (“<b>EasyTip</b>”) ist, Ihre personenbezogenen Daten ( in Abschnitt 3 unten definiert) verarbeitet, wenn Sie die EasyTip-Website nutzen, die unter <a href="https://www.easytip.net">www.easytip.net</a> und aller Subdomains, die in Verbindung mit dieser und allen zukünftigen Versionen angeboten wird (die “<b>EasyTip-Website</b>”), sowie bei der Nutzung der mobilen Anwendungen von EasyTip (die “<b>EasyTip-App</b>”). Zusammen bilden die EasyTip-Website und die EasyTip-App die “<b>EasyTip-Plattform</b>”
    </p>
    <p>Indem Sie uns Ihre Daten zur Verfügung stellen, versichern Sie uns, dass Sie über 16 Jahre alt sind.</p>
    <p>
      EasyTip ist als Verantwortlicher für die Verarbeitung Ihrerpersonenbezogenen Daten durch EasyTip verantwortlich (EasyTip wird in diesem Datenschutzhinweis auch als “wir”, “uns” oder “unser” bezeichnet).
    </p>

    <h2 id="section-2">2. KONTAKT</h2>
    <p>Unsere vollständigen Kontaktdaten sind:</p>
    <p>QR Tip Ltd<br/>Gerät 3<br/>8 Greenock Road<br/>London<br/>W3 8DU</p>
    <p>E-Mail Adresse: <a href="mailto:info@easytip.net">info@easytip.net</a></p>
    <p>
      Es ist wichtig, dass die Informationen, die EasyTip über Sie verarbeitet, korrekt und aktuell sind. Bitte informieren Sie uns über alle Änderungen Ihrer persönlichen Daten, indem Sie uns eine E-Mail an die oben genannte E-Mail-Adresse senden.
    </p>

    <h2 id="section-3">3. Zweck der Verarbeitung</h2>
    <p>Zweck der Verarbeitung ist es, Ihnen die EasyTip-Plattform und alle damit verbundenen Dienste zur Verfügung zu stellen.</p>

    <h2 id="section-4">4. ARTEN DER ERHOBENEN UND VERARBEITETEN DATEN</h2>
    <p>
      “<b>Personenbezogene Daten</b>” sind alle Informationen, die geeignet sind, eine Einzelperson zu identifizieren (anonymisierten Daten sind keine personenbezogenen Daten).
    </p>
    <p>EasyTip kann die folgenden Kategorien von personenbezogenen Daten über Sie verarbeiten:</p>
    <ul class="list-style-lower-latin">
      <li>
        <p><b>Kommunikationsdaten</b></p>
        <p>
          “<b>Kommunikationsdaten</b>” umfassen alle Mitteilungen, die Sie an EasyTip senden, sei es über das Kontaktformular auf unserer Website, per E-Mail, Textnachricht, Social-Media-Nachrichten, Social-Media-Posting oder auf anderem Wege. EasyTip verarbeitet diese Daten zum Zweck der Kommunikation mit Ihnen. Wir verarbeiten sie auch, um Aufzeichnungen zu führen und um Rechtsansprüche zu begründen, zu verfolgen oder zu verteidigen. Die Rechtsgrundlage für diese Art der Verarbeitung ist unser berechtigtes Interesse an der Beantwortung von Mitteilungen, die wir erhalten, an der Führung von Aufzeichnungen und an der Begründung, Verfolgung oder Verteidigung von Rechtsansprüchen.
        </p>
      </li>
      <li>
        <p><b>Kundendaten</b></p>
        <p>
          “<b>Kundendaten</b>” umfassen: (1) Daten, die Sie bei der Eröffnung eines EasyTip-Kontos auf der EasyTip-Plattform angegeben haben, einschließlich Ihres Namens, Titels, Ihrer E-Mail-Adresse, Telefonnummer und anderer Kontaktdaten; und (2) Daten in Bezug auf Zahlungen, die Sie über die von Ihnen beauftragten Zahlungsdienstleister, die Sie über die EasyTip-Plattform kontaktiert haben, getätigt oder erhalten haben (wie z. B. Betrag, Datum und Empfänger der Zahlung). EasyTip verarbeitet diese Daten, um Ihnen den Zugriff auf die EasyTip-Plattform zu ermöglichen und um Aufzeichnungen über die von den Nutzern getätigten Zahlungen zu führen. Die Rechtsgrundlage von EasyTip für diese Verarbeitung ist, das die Verarbeitung für die Durchführung des Vertrags mit Ihnen erforderlich ist.
        </p>
      </li>
      <li>
        <p><b>Benutzerdaten</b></p>
        <p>
          “<b>Benutzerdaten</b>” umfassen Daten darüber, wie Sie die EasyTip-Plattform nutzen, zusammen mit allen Daten, die Sie auf der EasyTip-Plattform veröffentlichen, einschließlich aller Bewertungen, die Sie auf der EasyTip-Plattform veröffentlichen. EasyTip verarbeitet diese Daten, um die EasyTip-Plattform zu betreiben und um sicherzustellen, dass Ihnen geeignete Inhalte zur Verfügung gestellt werden. EasyTip verarbeitet diese Daten auch, um die Sicherheit der EasyTip-Plattform zu gewährleisten, um Backups von Informationen zu führen und um die EasyTip-Plattform anbieten zu könnenund Verwaltung der EasyTip-Plattform zu ermöglichen. Die Rechtsgrundlage ist unser berechtigtes Interesse, an der ordnungsgemäßen Verwaltung der EasyTip-Plattform und unsere Geschäfts zu ermöglichen. Außerdem ist die Verarbeitung für die Durchführung des Vertrags mit Ihnen erforderlich.
        </p>
      </li>
      <li>
        <p><b>Technische Daten</b>
        </p>
        <p>“<b>Technische Daten</b>” umfassen Daten über Ihre Nutzung der EasyTip-Plattform und beinhalten:</p>
        <ul>
          <li>Ihre IP-Adresse, Anmeldedaten und Browserdetails;</li><li>die Dauer, Anzahl und Häufigkeit Ihrer Besuche auf den EasyTip-Webseiten und der EasyTip-App sowie Ihre Seitenaufrufe und Navigationspfade; und</li><li>Zeitzoneneinstellungen und andere Details zu den Geräten, mit denen Sie auf die EasyTip-Plattform zugreifen.</li>
        </ul>
        <p>
          Diese technischen Daten werden von einem Analytik-Tracking-System erzeugt. EasyTip verarbeitet diese Daten, um Ihre Nutzung der EasyTip-Plattform zu analysieren, um die EasyTip-Plattform zu verwalten und unser Geschäft zu ermöglichen, um Ihnen Website-Inhalte und Werbung zu liefern und um die Wirksamkeit dieser Werbung zu analysieren. Der rechtmäßige Grund für die Verarbeitung dieser Art von Daten durch EasyTip ist unser berechtigtes Interesse, die ordnungsgemäße Verwaltung der EasyTip-Plattform zu ermöglichen und unsere Geschäfts- und Marketingstrategien zu entwickeln.
        </p>
      </li>
      <li>
        <p><b>Marketing-Daten</b></p>
        <p>
          “<b>Marketingdaten</b>” umfassen Daten bezüglich Ihrer Kommunikations- und Marketingpräferenzen und Ihrer Präferenzen bezüglich EasyTip und Dritten. EasyTip verarbeitet diese Daten, um Ihnen relevante Inhalte und Werbung zukommen zu lassen und um die Effektivität dieser Werbung zu analysieren. Der rechtmäßige Grund für die Verarbeitung dieser Art von Daten durch EasyTip ist unser berechtigtes Interesse daran, zu untersuchen, wie Nutzer unsere Produkte und Dienstleistungen nutzen, diese Produkte und Dienstleistungen weiterzuentwickeln und unsere Marketingstrategie festzulegen.
        </p>
        <p>
          EasyTip kann Kundendaten, Benutzerdaten, technische Daten und Marketingdaten verwenden, um Ihnen relevante Inhalte, Werbung und andere Marketingmitteilungen zukommen zu lassen und um die Effektivität dieser Werbung zu bestimmen. Die Rechtsgrundlage von EasyTip hierfür ist unser berechtigtes Interesse, unser Geschäft zu entwickeln.
        </p>
      </li>
      <li>
        <p><b>Besondere Kategorien personenbezogener Daten</b></p>
        <p>
          “<b>Besondere Kategorien personenbezogener Daten</b>” sind Daten, die Details über Ihre Rasse oder ethnische Zugehörigkeit, religiöse oder philosophische Überzeugungen, Ihr Sexualleben, Ihre sexuelle Orientierung, politische Meinungen, Mitgliedschaft in Gewerkschaften und Gesundheit oder genetische und biometrische Daten beinhalten. EASYTIP ERHEBT KEINE PERSONENBEZOGENEN DATEN AUS DEM BEREICH DER BESONDEREN KATEGORIEN PERSONENBEZOGENER DATEN ÜBER SIE.
        </p>
      </li>
    </ul>
    <p>
      EasyTip kann gesetzlich dazu verpflichtet sein, personenbezogene Daten zu sammeln. EasyTip kann dies auch gemäß den Bedingungen des Vertrags zwischen EasyTip und Ihnen tun. Wenn Sie EasyTip diese Daten nicht zur Verfügung stellen, wenn Sie dazu aufgefordert werden, ist EasyTip möglicherweise nicht in der Lage, die entsprechenden Dienstleistungen zu erbringen.
    </p>
    <p>
      EasyTip wird Ihre personenbezogenen Daten nur für den Zweck verwenden, für den sie ursprünglich erhoben wurden, oder, falls erforderlich, für einen vernünftigerweise kompatiblen Zweck. Wenn EasyTip Ihre Daten für einen neuen und nicht damit verbundenen neuen Zweck verwenden muss, wird EasyTip Sie über die Gründe für diese Verwendung informieren.
    </p>
    <p>
      EasyTip kann Ihre personenbezogenen Daten ohne Ihr Wissen oder Ihre Zustimmung verarbeiten, wenn dies gesetzlich erforderlich oder erlaubt ist.
    </p>

    <h2 id="section-5">5. ERHEBUNG PERSÖNLICHER DATEN</h2>
    <p>
      EasyTip kann Daten über Sie erheben, wenn Sie uns diese Daten direkt zur Verfügung stellen (z. B. durch das Ausfüllen eines Formulars auf der EasyTip-Plattform, durch das Senden von E-Mails oder Nachrichten an uns oder durch die Übermittlung von Feedback an uns). EasyTip kann bestimmte Daten von Ihnen automatisch sammeln, während Sie die EasyTip-Plattform nutzen. Bitte lesen Sie die Cookie-Richtlinie von EasyTip für weitere Details: <a href="https://www.easytip.net/privacy-policy">https://www.easytip.net/privacy-policy</a>
    </p>
    <p>
      EasyTip kann Daten über Sie von Dritten erhalten, z. B. von Analyseanbietern, Werbenetzwerken und Anbietern von Suchinformationen mit Sitz außerhalb der EU sowie von Anbietern von Zahlungs- und technischen Dienstleistungen.
    </p>

    <h2 id="section-6">6. MARKETINGKOMMUNIKATION</h2>
    <p>
      EasyTip's rechtmäßiger Grund für die Verarbeitung Ihrer persönlichen Daten, um Ihnen Marketingmitteilungen zu senden, ist entweder Ihre Zustimmung oder unser berechtigtes Interesse an der Entwicklung unseres Geschäfts.
    </p>
    <p>Nach geltendem Recht darf EasyTip Ihnen Marketingmitteilungen senden, wenn:</p>
    <ul class="list-style-lower-latin">
      <li>Sie Informationen von EasyTip angefordert oder Waren oder Dienstleistungen von EasyTip gekauft haben; oder</li>
      <li>Sie  EasyTip Ihre Daten zur Verfügung gestellt haben, wenn Sie an einem Gewinnspiel teilnehmen oder sich für eine Werbeaktion oder kostenlose Ressourcen registrieren; und</li>
      <li>Im Fall von (a) oder (b) oben haben Sie sich für den Erhalt dieser Marketingmitteilungen entschieden.</li>
    </ul>
    <p>
      EasyTip wird Ihre ausdrückliche Zustimmung einholen, bevor es Ihre personenbezogenen Daten an Dritte für deren Marketingzwecke weitergibt.
    </p>
    <p>
      Sie können EasyTip oder Dritte jederzeit auffordern, Ihnen keine Marketingnachrichten mehr zu senden, indem Sie (1) dem Opt-out-Link anklicken, der auf jeder Marketingmitteilung von uns zu finden ist, oder (2) uns eine E-Mail an die oben genannte E-Mail-Adresse schicken.
    </p>
    <p>
      Wenn Sie sich gegen den Erhalt von Marketingmitteilungen entscheiden, gilt dies nicht für personenbezogene Daten, die uns als Ergebnis der Nutzung eines Produkts oder einer Dienstleistung oder anderer Transaktionen zur Verfügung gestellt werden.
    </p>

    <h2 id="section-7">7. EMPFÄNGER IHRER PERSONENBEZOGENEN DATEN</h2>
    <p>EasyTip kann verpflichtet sein, Ihre personenbezogenen Daten an die folgenden Parteien weiterzugeben:</p>
    <ul>
      <li>Anbieter von IT- und Systemadministrationsdienstleistungen;</li>
      <li>Professionelle Berater wie Anwälte, Banker, Wirtschaftsprüfer und Versicherer;</li>
      <li>Steuerbehörden;</li>
      <li>Andere Aufsichtsbehörden und Behörden im Vereinigten Königreich und anderen relevanten Gerichtsbarkeiten, die unter bestimmten Umständen eine Meldung von Verarbeitungsaktivitäten verlangen können; und</li>
      <li>Dritte, an die EasyTip Teile unseres Geschäfts oder unserer Vermögenswerte verkauft, überträgt oder fusioniert.</li>
    </ul>
    <p>
      EasyTip verlangt von allen Dritten, an die wir Ihre Daten weitergeben, die Sicherheit Ihrer personenbezogenen Daten zu respektieren und sie in Übereinstimmung mit dem Gesetz zu behandeln. EasyTip erlaubt solchen Dritten nur, Ihre personenbezogenen Daten für bestimmte Zwecke zu verarbeiten, die mit dieser Datenschutzrichtlinie übereinstimmen und denen EasyTip zugestimmt hat.
    </p>
    <p>
      Im Falle von Dienstanbietern, die die EasyTip-Plattform nutzen, um sich mit einem Zahlungsanbieter zu verbinden, um Zahlungen von Trinkgeldern über diesen Zahlungsanbieter zu erhalten, kann EasyTip verpflichtet sein, personenbezogene Daten an Ihren Arbeitgeber weiterzugeben, um Ihre Beschäftigung zu verifizieren.
    </p>

    <h2 id="section-8">8. INTERNATIONALE DATENÜBERMITTLUNG</h2>
    <p>
      EasyTip unterliegt den Bestimmungen der Datenschutzgrundverordnung, die Ihre personenbezogenen Daten schützen. Wenn EasyTip Ihre personenbezogenen Daten an Dritte außerhalb des EWR weitergibt, stellen wir sicher, dass bestimmte Sicherheitsvorkehrungen getroffen werden, um ein ähnliches Maß an Sicherheit für Ihre personenbezogene Daten zu gewährleisten, wie es in der EU und EWR gilt:
    </p>
    <ul>
      <li>
        EasyTip kann Ihre personenbezogene Daten in Länder übermitteln, die von der Europäischen Kommission anerkannt wurden ein angemessenes Schutzniveau für personenbezogene Daten zu haben; oder
      </li>
      <li>
        Wenn EasyTip bestimmte Dienstleister einsetzt, die außerhalb des EWR ansässig sind, können wir spezielle Verträge oder Verhaltenskodizes oder Zertifizierungsmechanismen verwenden, die von der Europäischen Kommission genehmigt wurden und den personenbezogenen Daten den gleichen Schutz bieten wie in Europa.
      </li>
    </ul>
    <p>
      Wenn keine der oben genannten Sicherheitsvorkehrungen verfügbar ist, kann EasyTip Ihre ausdrückliche Zustimmung zu der spezifischen Übertragung einholen. Sie haben das Recht, diese Zustimmung jederzeit zu widerrufen.
    </p>

    <h2 id="section-9">9. DATENSICHERHEIT</h2>
    <p>
      EasyTip hat Sicherheitsvorkehrungen getroffen, um zu verhindern, dass Ihre personenbezogenen Daten versehentlich verloren gehen, verwendet, verändert, offengelegt oder unbefugt darauf zugegriffen wird. EasyTip erlaubt den Zugriff auf Ihre personenbezogenen Daten nur denjenigen Mitarbeitern und Partnern, die eine geschäftliche Notwendigkeit haben, diese Daten zu kennen. Diese Personen werden Ihre personenbezogenen Daten nur auf Anweisung von EasyTip verarbeiten und müssen sie vertraulich behandeln.
    </p>
    <p>
      EasyTip hat Verfahren für den Umgang mit mutmaßlichen Verstößen gegen personenbezogene Daten eingerichtet. EasyTip wird Sie und alle relevanten Aufsichtsbehörden über einen Verstoß benachrichtigen, wenn wir gesetzlich dazu verpflichtet sind.
    </p>

    <h2 id="section-10">10. SPEICHERDAUER</h2>
    <p>
      EasyTip wird Ihre personenbezogenen Daten nur so lange speichern, wie es notwendig ist, um die Zwecke zu erfüllen, für die wir sie gesammelt haben (einschließlich der Erfüllung von gesetzlichen und buchhalterischen Anforderungen).
    </p>
    <p>
      Bei der Bestimmung des angemessenen Zeitraums für die Aufbewahrung von Daten berücksichtigt EasyTip die folgenden Faktoren in Bezug auf die relevanten Daten: Menge, Art und Sensibilität, potenzielles Risiko eines Schadens durch unbefugte Nutzung/Veröffentlichung, Verarbeitungszwecke und gesetzliche Anforderungen.
    </p>
    <p>
      Steuerrechtliche Vorschriften schreiben vor, dass EasyTip grundlegende Informationen über unsere Kunden (einschließlich Kontakt- und Identitätsdaten sowie Finanz- und Transaktionsdaten) für sechs Jahre aufbewahrt muss. Speicherfristen sind (a) für Kontaktdaten und Identitätsdaten: sechs (6) Jahre nachdem Sie kein Kunde mehr von EasyTip sind, d.h. der Vertrag zwischen uns beendet ist und (b) für Finanz- und Transaktionsdaten: sechs (6) Jahre ab dem Ende des Jahres in dem die Transaktion durchgeführt wurde.
    </p>
    <p>
      Unter bestimmten Umständen kann EasyTip Ihre personenbezogenen Daten zu Forschungs- oder statistischen Zwecken anonymisieren; in diesem Fall kann EasyTip diese Informationen ohne weitere Mitteilung an Sie unbegrenzt verwenden.
    </p>

    <h2 id="section-11">11. IHRE RECHTE</h2>
    <p>
      Nach den Datenschutzgesetzen haben Sie Rechte in Bezug auf Ihre personenbezogenen Daten, einschließlich des Rechts auf Zugang, Berichtigung, Löschung, Einschränkung, Übertragung, Widerspruch gegen die Verarbeitung, Datenübertragbarkeit und (wenn der rechtmäßige Grund für die Verarbeitung die Einwilligung ist) auf Widerruf der Einwilligung.
    </p>
    <p>
      Weitere Informationen über diese Rechte finden Sie unter: <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a>
    </p>
    <p>
      Wenn Sie eines Ihrer Rechte ausüben möchten, wenden Sie sich bitte per E-Mail an EasyTip unter der in Abschnitt 2 oben genannten E-Mail Adresse.
    </p>
    <p>
      Für den Zugriff auf Ihre personenbezogenen Daten (oder die Ausübung der anderen Rechte) werden Ihnen keine Gebühren berechnet. EasyTip kann jedoch eine angemessene Gebühr erheben, wenn Ihre Anfrage offensichtlich unbegründet, wiederholend oder übermäßig ist. EasyTip kann sich unter diesen Umständen auch weigern, Ihrer Anfrage nachzukommen.
    </p>
    <p>
      EasyTip kann verpflichtet sein, bestimmte Informationen von Ihnen anzufordern, um Ihre Identität zu bestätigen und Ihr Recht auf Zugang zu Ihren personenbezogenen Daten (oder zur Ausübung anderer Rechte) zu gewährleisten. Dies ist eine Sicherheitsmaßnahme, um sicherzustellen, dass personenbezogene Daten nicht unrechtmäßig an eine Person weitergegeben werden. EasyTip kann Sie auch kontaktieren, um Sie um weitere Informationen in Bezug auf Ihre Anfrage zu bitten, um unsere Antwort zu beschleunigen.
    </p>
    <p>
      EasyTip ist bestrebt, auf alle berechtigten Anfragen innerhalb eines Monats zu antworten. Es kann gelegentlich länger als einen Monat dauern, um zu antworten, wenn Ihre Anfrage besonders komplex ist oder Sie zahlreiche Anfragen gestellt haben. In diesem Fall wird EasyTip Sie benachrichtigen.
    </p>
    <p>
      Wenn Sie mit irgendeinem Aspekt der Art und Weise, wie EasyTip Ihre Daten sammelt und verwendet, nicht zufrieden sind, haben Sie das Recht, sich beim Information Commissioner's Office (ICO), der britischen Aufsichtsbehörde für Datenschutzfragen, zu beschweren (www.ico.org.uk). EasyTip würde es begrüßen, wenn Sie sich im Falle einer Beschwerde zuerst mit uns in Verbindung setzen, damit wir versuchen können, das Problem für Sie zu lösen.
    </p>

    <h2 id="section-12">12. BEREITSTELLUNG IHRER PERSONENBEZOGENEN DATEN IST VORAUSSETZUNG FÜR DIE VERTRAGSDURCHFÜHRUNG</h2>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten, wie hier beschrieben, ist grundsätzlich notwendig, um Ihnen die EasyTip-Plattform zur Verfügung zu stellen. Wenn Sie Ihre personenbezogenen Daten nicht bereitstellen wollen, können wir Ihnen die EasyTip-Plattform daher nicht zur Verfügung stellen. Eine Ausnahme hiervon ist, dass Sie auf unserer Website surfen und wir nur eine kleine Teilmenge an Daten durch Cookies sammeln.
    </p>

    <h2 id="section-13">13. LINKS VON DRITTEN</h2>
    <p>
      Die EasyTip-Plattform kann Links zu Websites, Plug-ins und Anwendungen Dritter enthalten. Wenn Sie auf diese Links klicken oder diese Verbindungen aktivieren, können Dritte Daten über Sie sammeln oder weitergeben. EasyTip hat keine Kontrolle über diese Drittanbieter-Websites und wir sind nicht für deren Datenmanagementverfahren verantwortlich. Wenn Sie die EasyTip-Plattform verlassen, empfehlen wir Ihnen, die Datenschutzerklärung jeder Website, die Sie besuchen, zu lesen.
    </p>

    <h2 id="section-14">14. COOKIES</h2>
    <p>
      Sie können Ihren Browser so einstellen, dass alle oder einige Browser-Cookies abgelehnt werden oder dass Sie gewarnt werden, wenn Websites Cookies setzen oder darauf zugreifen. Wenn Sie Cookies deaktivieren oder ablehnen, beachten Sie bitte, dass einige Teile der EasyTip-Plattform möglicherweise nicht mehr zugänglich sind oder nicht mehr richtig funktionieren. Für weitere Informationen lesen Sie bitte unsere Cookie-Richtlinie unter: <a href="https://www.easytip.net/privacy-policy">https://www.easytip.net/privacy-policy</a>
    </p>

    <h2 id="section-15">15. NUTZUNG DER EASYTIP-PLATTFORM - BEDINGUNGEN UND KONDITIONEN</h2>
    <p>
      Durch das Durchsuchen und die Nutzung der EasyTip-Plattform erklären Sie sich mit den EasyTip-Nutzungsbedingungen einverstanden, die Sie unter: <a href="https://www.easytip.net/terms-conditions">https://www.easytip.net/terms-conditions</a> einsehen können und an diese gebunden sind
    </p>
  </div>
</template>
