<template>
  <ui-card>
    <div class="bill-staff-list-avatar">
      <div class="bill-staff-list-avatar__title">
        Would you like to leave a tip?
      </div>

      <div class="bill-staff-list-avatar__list">
        <div
          class="bill-staff-list-avatar__list-group"
          :class="[
            `bill-staff-list-avatar__list-group_${avatarCount.length}`,
            { 'bill-staff-list-avatar__list-group--has-more': isOtherStaff },
          ]"
        >
          <user-avatar
            v-for="item in avatarCount"
            :key="item.payoutId"
            :payout-id="item.payoutId"
            size="medium"
            borderless
          />

          <div
            v-if="isOtherStaff"
            class="bill-staff-list-avatar__list-count user-avatar user-avatar_medium"
          >
            {{ otherStaffCountLabel }}
          </div>
        </div>
      </div>

      <ui-button
        class="bill-staff-list-avatar__button"
        @click="$emit('submit')"
      >
        Select staff
      </ui-button>
    </div>
  </ui-card>
</template>

<script setup>
import { computed } from 'vue';
import UiCard from '@/components/UiCard.vue';
import UiButton from '@/components/UiButton.vue';
import UserAvatar from '@/components/UserAvatar.vue';

const MAX_STAFF_COUNT = 3;

const props = defineProps({
  items: Array,
});

defineEmits(['submit']);

const staffCount = computed(() => {
  return props.items.length;
});

const isOtherStaff = computed(() => {
  return staffCount.value > MAX_STAFF_COUNT;
})

const otherStaffCountLabel = computed(() => {
  return `+${staffCount.value - MAX_STAFF_COUNT}`;
});

const avatarCount = computed(() => {
  if (staffCount.value > MAX_STAFF_COUNT) {
    return props.items.slice(0, MAX_STAFF_COUNT);
  }

  return props.items;
});
</script>
