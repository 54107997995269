<template>
  <div class="bottom-section">
    <div v-if="!noSocialIcons" class="bottom-section__socials">
      <ui-socials :only-icons="!followUsBlock" />
    </div>

    <footer class="bottom-section__footer">
      <a
        class="bottom-section__contact-link"
        href="https://www.easytip.net/contact-us"
        target="_blank"
      >
        Contact us
      </a>

      <div class="bottom-section__footer_section">
        <div class="bottom-section__payments">
          <img src="@/assets/payment-methods/visa.svg" width="40" height="14" alt="Visa logo" />
          <img src="@/assets/payment-methods/mastercard.svg" width="23" height="23" alt="Mastercard logo" />
          <img src="@/assets/payment-methods/american-express.svg" width="38" height="24" alt="American express logo" />
        </div>

        <div class="bottom-section__copyright">
          Copyright © {{ currentYear }} EASYTIP
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import UiSocials from '@/components/UiSocials.vue';
import { computed } from 'vue';

defineProps({
  noSocialIcons: {
    type: Boolean,
    default: false,
  },
  followUsBlock: { type: Boolean },
});

const currentYear = computed(() => new Date().getFullYear());
</script>
