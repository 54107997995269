<template>
  <component
    :is="tag"
    v-bind="$attrs"
    :type="type"
    :disabled="disabled"
    class="ui-button"
    :class="{
      'ui-button_pay': isPay,
      'ui-button_giropay': giroPay,
      'ui-button_small': small,
      'ui-button_link': link,
      'ui-button_outlined': outlined,
      'ui-button_transparent': transparent,
      'ui-button_ghost': ghost,
      'ui-button_paypal': payPal,
      'ui-button_disabled': disabled,
      'ui-button_underline': underline,
      'ui-button_cancel': cancel,
    }"
    v-on="listeners"
  >
    <ui-spinner v-if="isLoading" />
    <ui-icon
      v-else-if="name"
      :name="name"
      class="ui-button__icon"
    />
    <slot v-else />
  </component>
</template>

<script>
import UiIcon from './UiIcon.vue';
import UiSpinner from './UiSpinner.vue';

export default {

  components: {
    UiIcon,
    UiSpinner,
  },

  props: {
    type: {
      type: String,
      default: 'button',
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    isLoading: Boolean,
    applePay: Boolean,
    googlePay: Boolean,
    giroPay: Boolean,
    payPal: Boolean,
    small: Boolean,
    link: Boolean,
    outlined: Boolean,
    transparent: Boolean,
    disabled: Boolean,
    cancel: Boolean,
    underline: Boolean,
  },

  computed: {
    isPay() {
      return this.applePay || this.googlePay;
    },
    name() {
      if (this.applePay) {
        return 'apple-pay';
      }

      if (this.googlePay) {
        return 'google-pay';
      }

      if (this.giroPay) {
        return 'giro-pay';
      }

      if (this.payPal) {
        return 'pay-pal';
      }

      return '';
    },
    listeners() { // Safari <button disabled /> doesnt works in same versions.
      const { click, ...otherListeners } = this.$listeners;

      return {
        click: (...args) => {
          if (this.disabled) {
            return;
          }

          if (typeof click === 'function') {
            click(...args);
          }
        },
        ...otherListeners,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-button {
  position: relative;
  border: 0;
  height: 56px;
  background-color: $primary;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &_disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  &_pay &__icon {
    width: 75px;
    height: 31px;
  }

  &_giropay {
    background-color: #000268;
  }

  &_giropay &__icon {
    width: 74px;
    height: 32px;
  }

  &_paypal {
    background-color: #0070BA;
  }

  &_paypal &__icon {
    width: 92px;
    height: 24px;
  }

  &_small {
    border-radius: 42px;
    height: 36px;
    padding: 0 55px;
    width: auto;
  }

  &_outlined {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    width: 88px;
    height: 28px;
    border: 1.5px solid #D5D5D5;
    box-sizing: border-box;
    border-radius: 42px;
  }

  &_transparent {
    background-color: transparent !important;
  }

  &_link {
    width: auto;
    border: 0;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #a60400;
  }

  &_ghost {
    background-color: initial;
    color: #808080;
  }

  &_pay {
    background-color: rgba(0,0,0,0.9) !important;
  }

  &_underline {
    text-decoration: underline;
  }

  &_cancel {
    @include font(16px, 24px, 400);
    background-color: transparent;
    color: $main-brand-color;
    text-decoration: underline;
  }
}
</style>
