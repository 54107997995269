import { configure } from 'vee-validate';
import './rules';

export default {
  install(app, i18n) {
    configure({
      generateMessage: ({ rule }) => {
        const {
          name = 'default',
          params = [],
        } = rule ?? {};

        return i18n.global.t(`validationErrors.${name}`, params);
      },
    });
  },
};
