<template>
  <div>
    <slot
      name="activator"
      v-bind="{ open, close }"
    />
      <div
        class="ui-modal-wrapper"
        :class="{
          opened: showed,
          'ui-modal-wrapper_popup': popup,
          'ui-modal-wrapper_layout': layout,
        }"
        @click.self.prevent="closable && close()"
      >
        <main-layout centered v-if="layout" followUsBlock header-transparent>
          <div class="ui-modal" @click.self.prevent>
            <div v-if="$scopedSlots.header || title" class="ui-modal__header">
              <slot name="header">
                {{ title }}
              </slot>
            </div>

            <div class="ui-modal__body">
              <slot v-bind="{ open, close }" />
            </div>
          </div>
        </main-layout>

        <div class="ui-modal" @click.self.prevent v-else>
          <div v-if="$scopedSlots.header || title" class="ui-modal__header">
            <slot name="header">
              {{ title }}
            </slot>
          </div>

          <div class="ui-modal__body">
            <slot v-bind="{ open, close }" />
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
export default {
  components: { MainLayout },
  props: {
    value: Boolean,
    title: String,
    closable: {
      type: Boolean,
      default: true,
    },
    popup: {
      type: Boolean,
    },
    layout: {
      type: Boolean,
    },
  },

  data() {
    return {
      showed: this.value,
    };
  },

  watch: {
    value(val) {
      if (val === this.showed) {
        return;
      }

      if (val) {
        this.open();
      } else {
        this.close();
      }
    },
    showed(val) {
      if (val !== this.value) {
        this.$emit('input', val);
      }
    },
  },

  methods: {
    open() {
      this.showed = true;
      this.$emit('opened');
    },
    close() {
      this.showed = false;
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss">
.ui-modal-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100vw;
  background-color: rgba(51,51,51,.5);
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  display: none;

  &.opened {
    display: flex;
  }

  &_popup {
    background: none !important;
  }

  &_layout {
    background: $background;
    padding: 0 !important;
  }
}

.ui-modal-wrapper,
.ui-modal {
  width: 100%;
  box-sizing: border-box;
}

.ui-modal {
  max-width: 410px;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,.18);
  border-radius: 12px;
  padding: 20px;
  padding-top: 41px;
  padding-bottom: 41px;
  z-index: 100;
  margin: auto;
  min-height: 393px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;

  &__header {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 22px;
  }
}
</style>
