<template>
  <main-layout
    :company-logo="!isStaff"
    :partner-id="localPartnerId"
    :payout-id="localPayoutId"
    no-social-icons
  >
    <div class="checkout">
      <div
        v-if="someLoading"
        class="checkout__loader"
      >
        <ui-spinner />
      </div>
      <div
        v-else
        class="checkout__body"
        :class="{ 'checkout__body_no-stuff': !staffList.length }"
      >
        <location-card
          class="checkout__location_card"
          v-if="isCommon"
          :payoutId="workplacePayoutId"
          :name="locationName"
        />

        <staff-card
          class="checkout__staff_card"
          v-else-if="isStaff && staffData && !staffList.length"
          :wish="wish"
          :staff="staffData"
        />

        <div class="checkout__card">
          <div class="checkout__info">
            <div class="checkout__total">
              <span class="checkout__title">EasyTip</span>
              <ui-money
                :value="totalMoney"
                class="checkout__amount"
                :class="{
                  'checkout__amount_small': totalMoney.amountLong,
                }"
              />
            </div>
            <ul class="checkout__payment-methods">
              <li
                v-for="item in paymentMethodsLogo"
                :key="item.id"
                class="checkout__payment-method"
              >
                <img
                  :src="require(`@/assets/payment-methods/${item.title}.svg`)"
                  :alt="item.title"
                />
              </li>
            </ul>
          </div>
          <div class="checkout__form">
            <checkout-by-card
              v-if="!ckoSessionId"
              :public-key="publicKey"
              :currency="currencyInfo"
              :total-amount="totalAmount"
              :paymentMethodsLabel="paymentMethodsLabel"
              @submit="handleSubmit"
              @create-payment="createPayment"
            />
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import api from '@/api';
import { mapActions, mapGetters, mapState } from 'vuex';
import storage from '@/plugins/storage';
import Money from '@/class/Money';
import CheckoutByCard from '@/components/CheckoutByCard.vue';
import UiSpinner from '@/components/UiSpinner.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import StaffCard from '@/components/StaffCard.vue';
import LocationCard from '@/components/LocationCard.vue';
import UiMoney from '../components/UiMoney.vue';

const REDIRECT_URL = {
  success: 'redirect-success-url',
  error: 'redirect-error-url',
};

export default {
  name: 'Checkout',

  components: {
    CheckoutByCard,
    UiSpinner,
    UiMoney,
    MainLayout,
    StaffCard,
    LocationCard,
  },

  props: {
    staffData: Object,
    feeChecked: Boolean,
    currency: String,
    amount: Number,
    groupId: String,
    staffPayoutId: String,
    workplacePayoutId: String,
    listPayment: Array,
    backUrl: Object,
    ckoSessionId: String,
    paymentStatus: String,
    paymentId: String,
    currencyInfo: Object,
    isCommon: Boolean,
    locationName: String,
    partnerId: String,
  },

  data() {
    return {
      loadingFee: false,
      loadingPublicKey: false,
      loadingCreatePayment: false,
      loadingConfirmPayment: false,
      totalAmount: 0,
      fee: 0,
      publicKey: '',
    };
  },

  mounted() {
    this.loadData();
  },

  watch: {
    ckoSessionId: {
      immediate: true,
      handler(value) {
        if (value) {
          const redirectUrl = storage.get(REDIRECT_URL[this.paymentStatus]);

          if (this.paymentStatus === 'success') {
            if (redirectUrl) {
              this.resetStorage();
              this.redirectToFullUrl(redirectUrl);
            } else {
              this.confirmPayment({
                status: 'success',
                paymentId: this.paymentId,
                ckoSessionId: value,
              });
            }
          } else if (this.paymentStatus === 'error') {
            if (redirectUrl) {
              this.resetStorage();
              this.redirectToFullUrl(redirectUrl);
            } else {
              this.confirmPayment({
                status: 'error',
                paymentId: this.paymentId,
                ckoSessionId: value,
              });
            }
          }
        }
      },
    },
  },

  computed: {
    ...mapState('user', [
      'location',
    ]),
    ...mapGetters('user', [
      'wish',
      'nickName',
    ]),
    isStaff() {
      return this.listPayment.length === 1;
    },
    localPartnerId() {
      return this.partnerId;
    },
    localPayoutId() {
      return this.workplacePayoutId;
    },
    totalMoney() {
      return new Money(this.totalAmount, this.currencyInfo);
    },
    someLoading() {
      return this.loadingFee
        || this.loadingPublicKey
        || this.loadingConfirmPayment;
    },
    paymentMethodsLogo() {
      return [
        {
          id: 1,
          title: 'mastercard',
        },
        {
          id: 2,
          title: 'visa',
        },
        {
          id: 3,
          title: 'jcb',
        },
        {
          id: 4,
          title: 'american-express',
        },
        {
          id: 5,
          title: 'diners-club',
        },
        {
          id: 6,
          title: 'giro-pay',
        },
        {
          id: 7,
          title: 'discover',
        },
      ];
    },
    paymentMethodsLabel() {
      return [
        {
          id: 1,
          title: 'mastercard',
        },
        {
          id: 2,
          title: 'visa',
        },
        {
          id: 3,
          title: 'american-express',
        },
      ];
    },
    staffList() {
      if (this.isStaff) {
        return [];
      }

      return this.location?.staffs
        .filter((item) => this.listPayment
          .some(({ payoutId }) => item.payoutId === payoutId))
        .map((item) => ({
          ...item,
        }));
    },
    paymentPayload() {
      return {
        listPayment: this.listPayment,
        feeChecked: this.feeChecked,
        currency: this.currency,
        amount: this.totalAmount,
        feeAmount: this.fee,
        workplacePayoutId: this.workplacePayoutId,
        groupId: this.groupId,
      };
    },
  },

  methods: {
    ...mapActions('user', [
      'loadLocationStaffs',
      'loadUser',
    ]),
    resetStorage() {
      storage.set(REDIRECT_URL.success, '');
      storage.set(REDIRECT_URL.error, '');
    },
    getRedirectUrlFrom3DS(status) {
      const { origin } = window.location;
      const {
        query: {
          'cko-session-id': ckoSessionId,
          paymentId,
          ...queryData
        },
      } = this.$route;
      const storageRedirectUrl = this.$router.resolve({
        query: {
          ...queryData,
          paymentStatus: status,
        },
      }).href;
      const checkoutRedirectUrl = this.$router.resolve({
        query: {
          paymentStatus: status,
        },
      }).href;

      storage.set(REDIRECT_URL[status], storageRedirectUrl);

      return origin + checkoutRedirectUrl;
    },
    redirectToStatusPage({ status, paymentId }) {
      this.$router.push({
        path: `${this.backUrl.locationId}/${status}`,
        query: {
          ...this.backUrl.query,
          checkoutPaymentId: paymentId,
          rate: this.backUrl.rate,
          isCardCheckoutPayment: true,
          amount: this.totalAmount,
        },
      });
    },
    redirectToFullUrl(redirectUrl) {
      window.location.href = `${redirectUrl}&paymentId=${this.paymentId}&cko-session-id=${this.ckoSessionId}`;
    },
    async loadCheckoutPaymentPublicKey() {
      this.loadingPublicKey = true;

      try {
        const {
          data: {
            publicKey,
          },
        } = await api.getCheckoutPaymentPublicKey({
          workplacePayoutId: this.workplacePayoutId,
          staffPayoutId: this.staffPayoutId,
        });

        this.publicKey = publicKey;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingPublicKey = false;
      }
    },
    async loadFee() {
      this.loadingFee = true;

      try {
        const {
          data: {
            amount: fee,
          },
        } = await api.getCheckoutPaymentFee({
          workplacePayoutId: this.workplacePayoutId,
          amount: this.amount,
        });

        this.fee = fee;
        this.totalAmount = this.feeChecked
          ? parseFloat((this.amount + fee).toFixed(2))
          : this.amount;
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingFee = false;
      }
    },
    loadData() {
      if (!this.workplacePayoutId) {
        return false;
      }

      return Promise.all([
        this.loadCheckoutPaymentPublicKey(),
        this.loadFee(),
        this.loadLocationStaffs({ payoutId: this.workplacePayoutId }),
      ]);
    },
    wrongPayload() {
      if (this.feeChecked && this.fee === 0) {
        return true;
      }

      return this.listPayment.some((item) => {
        return item.amount === 0;
      });
    },
    handleSubmit(callback) {
      if (this.wrongPayload()) {
        this.$amplitude.event('WRONG_CHECKOUT_PAYMENT_BY_CARD', this.paymentPayload);

        const backUrl = this.$router.resolve({
          name: 'home',
          params: {
            locationPayoutId: this.workplacePayoutId,
          },
          query: {
            staffId: this.listPayment.map(({ payoutId }) => payoutId),
          },
        })?.href;

        window.location.href = this.$router.resolve({
          name: 'payment-failed',
          query: {
            backUrl,
            checkout: true,
          },
        }).href;
      } else {
        callback();
      }
    },
    async createPayment({ tokenized, token }) {
      if (!tokenized) {
        return;
      }

      try {
        const { data } = await api.createCheckoutPayment({
          ...this.paymentPayload,
          successUrl: this.getRedirectUrlFrom3DS('success'),
          errorUrl: this.getRedirectUrlFrom3DS('error'),
          customerCardToken: token,
        });

        if (data.is3DSEnabled && data.redirectLinkTo3dsFrame) {
          window.location.href = data.redirectLinkTo3dsFrame;
        } else if (data?.id) {
          this.redirectToStatusPage({
            status: 'success',
            paymentId: data?.id,
          });
        }
      } catch (e) {
        this.handleError(e);
      }
    },
    async confirmPayment({ status, paymentId, ckoSessionId }) {
      if (this.loadingConfirmPayment) {
        return;
      }

      this.loadingConfirmPayment = true;

      try {
        const { data } = await api.checkoutPaymentConfirm({
          status,
          paymentId,
          ckoSessionId,
        });

        if (data?.id) {
          this.redirectToStatusPage({
            status,
            paymentId: data?.id,
          });
        }
      } catch (e) {
        this.redirectToStatusPage({
          status,
          paymentId: null,
        });
      } finally {
        this.loadingConfirmPayment = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

.checkout {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__staff_card,
  &__location_card {
    margin-bottom: 20px;
  }

  &__alert-error {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A01212;
    margin-bottom: 25px;
  }

  &__header {
    height: $header-default-top;
    max-width: $max-width;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__location-logo {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  &__body {
    display: flex;
    flex-direction: column;

    margin: auto;
    max-width: 335px;

    &_no-stuff {
      margin-top: 20px;
    }
  }

  &__feedback-text {
    margin-top: 12px;
  }

  &__loader {
    width: 100%;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    > .ui-spinner {
      position: initial;
      padding: 0;
      margin: 0;

      svg {
        color: #000000;
      }
    }
  }

  &__info {
    display: flex;
  }

  &__total {
    min-width: 117px;
    min-height: 117px;
    max-width: 117px;
    max-height: 117px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    margin-top: -22px;
    margin-left: 20px;
  }

  &__title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #808080;
  }

  &__amount {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #000000;

    &_small {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &__card {
    width: 100%;
    max-width: 335px;

    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 8px rgba(0, 0, 0, 0.12);

    margin: 0 auto;
    margin-top: 20px;
  }

  &__payment-methods {
    max-width: 158px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    padding: 0;
    margin: 20px 20px 20px 25px;
  }

  &__payment-method {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__labels {
    width: 100%;
    max-width: 335px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 40px auto 36px;
    color: rgba(0, 0, 0, 0.15);
  }

  &__label {
    list-style: none;
    padding: 0;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 365px) {
    &__info {
      flex-direction: column;
      align-items: center;
    }

    &__total {
      margin-left: 0;
    }

    &__payment-methods {
      justify-content: space-between;
      align-items: center;

      padding: 0;
      margin: 20px 20px 0;
    }
  }
}
</style>
