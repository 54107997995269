/* eslint-disable quote-props */

export default {
  new: [
    'ATMOSPHERE',
    'BATHROOM',
    'CARE_PRODUCTS',
    'CLEANLINESS',
    'COMFORT',
    'DESIGN',
    'DINNING_AREA',
    'DRINKS',
    'ENTERTAINMENT',
    'FOOD',
    'FRIENDLINESS',
    'HOOKAH',
    'HOSPITALITY',
    'KIDS_FRIENDLY',
    'LOCATION',
    'MENU_CHOICE',
    'MUSIC',
    'PET_FRIENDLY',
    'PRICES',
    'PUNCTUALITY',
    'SERVICE',
    'STAFF',
    'TABLE_PLAN',
    'VALUE',
    'CHECK_IN',
  ],
  restaurant: {
    'Atmosphere': 'restaurant-atmosphere.svg',
    'Bathroom': 'restaurant-bathroom.svg',
    'Cleanliness': 'restaurant-cleanliness.svg',
    'Design': 'restaurant-design.svg',
    'Drinks': 'restaurant-drinks.svg',
    'Food': 'restaurant-food.svg',
    'Location': 'restaurant-location.svg',
    'Music': 'restaurant-music.svg',
    'Prices': 'restaurant-prices.svg',
    'Service': 'restaurant-service.svg',
    'Table plan': 'restaurant-table-plan.svg',
    'Hookah': 'restaurant-hookah.svg',
  },
  beauty_salon: {
    'Atmosphere': 'salon-atmosphere.svg',
    'Friendliness': 'salon-friendliness.svg',
    'Hospitality': 'salon-hospitality.svg',
    'Kids friendly': 'salon-kids-friendly.svg',
    'Entertainment': 'salon-entertainment.svg',
    'Comfort': 'salon-comfort.svg',
    'Pet friendly': 'salon-pet-friendly.svg',
    'Cleanliness': 'salon-cleanliness.svg',
    'Design': 'salon-design.svg',
    'Value': 'salon-value.svg',
    'Care products': 'salon-care-products.svg',
  },
  spa: {
    'Atmosphere': 'salon-atmosphere.svg',
    'Friendliness': 'salon-friendliness.svg',
    'Hospitality': 'salon-hospitality.svg',
    'Kids friendly': 'salon-kids-friendly.svg',
    'Entertainment': 'salon-entertainment.svg',
    'Comfort': 'salon-comfort.svg',
    'Pet friendly': 'salon-pet-friendly.svg',
    'Cleanliness': 'salon-cleanliness.svg',
    'Design': 'salon-design.svg',
    'Value': 'salon-value.svg',
    'Care products': 'salon-care-products.svg',
  },
  delivery: {
    'Atmosphere': 'restaurant-atmosphere.svg',
    'Bathroom': 'restaurant-bathroom.svg',
    'Cleanliness': 'restaurant-cleanliness.svg',
    'Design': 'restaurant-design.svg',
    'Drinks': 'restaurant-drinks.svg',
    'Food': 'restaurant-food.svg',
    'Location': 'restaurant-location.svg',
    'Music': 'restaurant-music.svg',
    'Prices': 'restaurant-prices.svg',
    'Service': 'restaurant-service.svg',
    'Table plan': 'restaurant-table-plan.svg',
  },
  hotel: {
    'Atmosphere': 'restaurant-atmosphere.svg',
    'Bathroom': 'restaurant-bathroom.svg',
    'Cleanliness': 'restaurant-cleanliness.svg',
    'Design': 'restaurant-design.svg',
    'Drinks': 'restaurant-drinks.svg',
    'Food': 'restaurant-food.svg',
    'Location': 'restaurant-location.svg',
    'Music': 'restaurant-music.svg',
    'Prices': 'restaurant-prices.svg',
    'Service': 'restaurant-service.svg',
    'Table plan': 'restaurant-table-plan.svg',
  },
};
