<template>
  <ul class="bill-discount">
    <li class="bill-discount__item">
      <ui-clip
        class="bill-dicount__item--percentage"
        :label="label"
        kind="success"
        size="tiny"
      />
      <div class="bill-discount__item--amount">
        {{ amount }}
      </div>
    </li>
  </ul>
</template>

<script setup>
import { computed } from 'vue';
import Money from '@/class/Money';
import UiClip from '@/components/UiClip.vue';

const props = defineProps({
  data: Object,
  currency: Object,
});

const label = computed(() => {
  return `${props.data.percentage}% OFF`;
});

const amount = computed(() => {
  return new Money(props.data.amount, props.currency);
});
</script>
