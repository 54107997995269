<template>
  <div class="base-layout" :class="{ 'base-layout_vertical-centering': verticalCentering }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseLayout',
  props: {
    verticalCentering: Boolean,
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/constants";

.base-layout {
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  @include home-gradient($header-default-top);
  display: flex;
  align-items: center;
  flex-direction: column;
  &_vertical-centering {
    justify-content: center;
  }
}
</style>
