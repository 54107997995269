/* eslint-disable quote-props */

export default {
  'Attendant': 'attendant.svg',
  'Bar': 'bar.svg',
  'Beautician': 'beautician.svg',
  'Cafe': 'cafe.svg',
  'Fitness': 'fitness.svg',
  'Nails': 'nails.svg',
  'Reception': 'reception.svg',
  'Salon': 'salon.svg',
  'Sauna': 'sauna.svg',
  'Yoga': 'yoga.svg',
  'Wellness': 'wellness.svg',
};
