<template>
  <base-layout
    class="menu__layout"
    :style="{
      '--menu-background-color': colors.backgroundColor,
    }"
  >
    <ui-spinner
      v-if="!isFilesLoaded"
      class="menu__loader"
    />
    <menu-background
      v-if="backgroundInfo"
      :background-url="backgroundInfo.link"
    />
    <menu-header
      :location-name="location.name"
      :contact-info="contactInfo"
    />
    <div class="menu__categories-selector-wrapper">
      <ui-selector
        v-if="showCategories"
        v-model="selectedCategoryIndex"
        :items="menuCategories"
        :color="colors.buttonColor"
        class="menu__categories-selector"
      />
    </div>
    <div class="menu">
      <img
        v-for="menuPage in menuPagesForShow"
        v-show="isFilesLoaded"
        :key="menuPage.link"
        class="menu__image"
        :src="menuPage.link"
        alt="menu"
        @load="onImagesLoaded"
      >
    </div>
    <div class="menu__sticky-footer">
      <ui-button class="menu__tips-button" @click="goToTips">
        Want to leave a tip?
      </ui-button>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue';
import MenuHeader from '@/components/menu/MenuHeader.vue';
import UiButton from '@/components/UiButton.vue';
import UiSelector from '@/components/UiSelector.vue';
import MenuBackground from '@/components/menu/MenuBackground.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import UiSpinner from '@/components/UiSpinner.vue';

export default {
  name: 'Menu',

  components: {
    UiSpinner,
    MenuBackground,
    UiSelector,
    UiButton,
    MenuHeader,
    BaseLayout,
  },

  props: {
    payoutId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedCategoryIndex: 0,
      loadedImagesCount: 0,
    };
  },

  computed: {
    ...mapState({
      backgroundInfo: (state) => state.menu.backgroundInfo,
      contactInfo: (state) => state.menu.contactInfo,
      colors: (state) => state.menu.colors,
      location: (state) => state.user.location,
    }),
    ...mapGetters({
      menuItems: 'menu/sortedMenuItems',
      showCategories: 'menu/showCategories',
    }),
    menuPagesForShow() {
      return this.menuItems[this.selectedCategoryIndex]?.pages || [];
    },
    menuCategories() {
      return this.menuItems.map((item, index) => ({
        id: index,
        title: item.section,
      }));
    },
    isFilesLoaded() {
      return this.loadedImagesCount >= this.menuPagesForShow.length;
    },
  },

  methods: {
    ...mapActions({
      loadLocation: 'user/loadLocation',
      loadMenuInfo: 'menu/loadMenuInfo',
    }),
    goToTips() {
      this.$router.push({ name: 'home' });
    },
    onImagesLoaded() {
      this.loadedImagesCount += 1;
    },
  },

  created() {
    if (!this.location.payoutId) {
      this.loadLocation({ payoutId: this.payoutId });
    }
    this.loadMenuInfo({ payoutId: this.payoutId });
  },

  watch: {
    selectedCategoryIndex() {
      this.loadedImagesCount = 0;
    },
  },
};
</script>
