import factory from '@/store/factory';

export default factory({

  namespaced: true,

  state: {
    moneyInputTrigger: false,
  },

  actions: {
    focusMoneyInput({ state, commit }) {
      commit('set', {
        moneyInputTrigger: !state.moneyInputTrigger,
      });
    },
  },
});
