<template>
  <div class="flow-layout">
    <ui-stepper
      v-if="showStepper"
      :step-info="stepInfo"
      :current-step="currentStep"
    />

     <slot name="default" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import UiStepper from '@/components/UiStepper.vue';

const props = defineProps({
  stepInfo: {
    type: Array,
    default: () => [],
  },
  currentStep: {
    type: Number,
    default: 1,
  },
});

const showStepper = computed(() => props.stepInfo.length > 1);
</script>
