import { createApp, configureCompat } from 'vue';
import Vue3TouchEvents from 'vue3-touch-events';
import { abilitiesPlugin } from '@casl/vue';
import { createMetaManager } from 'vue-meta';
import VueMask from '@devindex/vue-mask';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './plugins/i18n';
import amplitude from './plugins/amplitude';
import staffTypeName from './plugins/staff-type-name';
import apiSwitch from './plugins/api-switch';
import validation from './validation/validation';
import 'normalize.css';
import './assets/sass/index.scss';
import './plugins/sentry';
import ability from './plugins/ability';

const app = createApp(App);

app.use(store)
  .use(router)
  .use(i18n)
  .use(validation, i18n)
  .use(Vue3TouchEvents)
  .use(VueMask)
  .use(createMetaManager())
  .use(amplitude)
  .use(staffTypeName)
  .use(apiSwitch)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  });

configureCompat({
  // default everything to Vue 2 behavior
  MODE: 2,
});

app.mount('#app');
