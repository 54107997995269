<template>
  <div
    class="sub-location"
    v-on="$listeners"
  >
    <img
      :src="iconSrc"
      class="sub-location__image"
    />
    <h3 class="sub-location__name">
      <template v-if="name">
        {{ name }}
      </template>
      <span v-else class="sub-location__empty">
        No name
      </span>
    </h3>
  </div>
</template>

<script>
import locationsConfig from '@/config/locations';

export default {
  name: 'SubLocation',

  props: {
    name: String,
    locationType: String,
  },

  computed: {
    iconName() {
      return locationsConfig?.[this.locationType.toLowerCase()];
    },
    iconSrc() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return this.iconName ? require(`@/assets/locations/${this.iconName}`) : this.emptySrc;
    },
    emptySrc() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require('@/assets/noimage.png');
    },
  },
};
</script>

<style lang="scss">
  .sub-location {
    background: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.18);
    border-radius: 12px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 15px;

    &__image {
      height: 50px;
      margin-right: 24px;;
    }

    &__name {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      font-family: 'Roboto', sans-serif;
      color: #000000;
      word-break: break-word;
    }

    &__empty {
      color: gray;
    }
  }
</style>
