class Money {
  /**
   * @var {number}
   */
  amount = 0;

  /**
   * @var {string}
   */
  currencyIso = 'gbp';

  /**
   * @var {string}
   */
  currencyLabel = '£';

  /**
   * @var {boolean}
   */
  currencyRtl = false;

  /**
   * Constructor.
   *
   * @param {number|string} amount
   * @param {object} currency
   */
  constructor(amount, currency) {
    if (typeof amount !== 'number') {
      amount = +amount;
    }

    this.amount = amount;
    this.currencyIso = currency.iso;
    this.currencyLabel = currency.label;
    this.currencyRtl = currency.rightPosition;
  }

  /**
   * Amount value is too long characters.
   *
   * @returns {boolean}
   */
  get amountLong() {
    return this.amount.toString().length > 4;
  }

  /**
   * Currency label is more than 1 character.
   *
   * @returns {boolean}
   */
  get currencyLong() {
    return this.currencyLabel?.length > 1;
  }

  /**
   * Amount is integer (without fraction).
   *
   * @returns {boolean}
   */
  get isInteger() {
    return this.amount % 1 === 0;
  }

  /**
   * Format to decimal string without currency.
   *
   * @param {object} [options]
   * @returns {string}
   */
  toDecimal(options) {
    options = {
      minFractionDigits: this.isInteger ? 0 : 2,
      maxFractionDigits: this.isInteger ? 0 : 2,
      ...options,
    };

    if (typeof options.fractionDigits === 'number') {
      options = {
        minFractionDigits: options.fractionDigits,
        maxFractionDigits: options.fractionDigits,
      };
    }

    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'decimal',
      minimumFractionDigits: options.minFractionDigits,
      maximumFractionDigits: options.maxFractionDigits,
    });

    return formatter.format(this.amount);
  }

  /**
   * Money to string.
   *
   * @param {object} [options]
   * @returns {string}
   */
  toString(options) {
    const {
      currencyLabel,
      currencyLong,
      currencyRtl,
    } = this;

    const amount = this.toDecimal(options);

    const result = [
      currencyLabel,
      currencyLong ? ' ' : '',
      amount,
    ];

    if (currencyRtl) {
      result.reverse();
    }

    return result.join('');
  }
}

export default Money;
