<template>
  <div class="ui-search">
    <ui-icon
      name="search"
      class="ui-search__icon"
    />
    <input
      v-bind="$attrs"
      type="text"
      class="ui-search__input"
      @input="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>
import UiIcon from './UiIcon.vue';

export default {

  components: {
    UiIcon,
  },
};
</script>

<style lang="scss">
.ui-search {
  display: flex;
  align-items: center;
  border: 2px solid $disabled;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 2;

  &__icon {
    flex: 0 0 auto;
    margin: 0 12px;
    width: 24px;
    height: 24px;
    color: $gray;
  }

  &__input {
    flex: 1 1 auto;
    font-size: 16px;
    color: $gray;
    padding: 9px 10px 9px 5px;
    outline: 0;
    border: 0;
    background: transparent;

    &::placeholder {
      color: $gray;
      opacity: 0.7;
    }
  }
}
</style>
