export default {
  manager: 'manager.svg',
  waiter: 'waiter.svg',
  house_keeping: 'house-keeping.svg',
  concierge: 'concierge.svg',
  valet: 'valet.svg',
  therapist: 'therapist.svg',
  reception: 'reception.svg',
  attendant: 'attendant.svg',
};
