<template>
  <button
    v-bind="$attrs"
    class="ui-tip"
    :class="{
      'ui-tip_active': active,
      'ui-tip_disabled': disabled,
    }"
    v-on="$listeners"
    aria-label="Tip button"
    :disabled="disabled"
  >
    <div class="ui-tip__title">
      <div
        v-if="caption"
        class="ui-tip__caption"
      >
        {{ caption }}
      </div>
      <template v-if="percentage">
        {{ value }}
      </template>
      <ui-money
        v-else
        :amount="value"
        :currency="currency"
      />
    </div>
  </button>
</template>

<script>
import UiMoney from './UiMoney.vue';

export default {

  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: Object,
      required: true,
    },
    caption: String,
    disabled: Boolean,
    active: Boolean,
    percentage: Boolean,
  },

  components: {
    UiMoney,
  },
};
</script>

<style lang="scss">
.ui-tip {
  aspect-ratio: 1 / 1;
  background: $surface;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.10);
  border-radius: 8px;

  &:focus {
    outline: 0;
  }

  &_active {
    background: $primary;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.10);
  }

  &_disabled {
    cursor: default;
  }

  &__title {
    background: none;
    text-shadow: none;
    opacity: 0.8;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    color: $text;
  }

  &__caption {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &_active > &__title {
    opacity: 1;
    color: $text-white;
  }

  @media screen and (max-width: 480px) {
    &__caption {
      font-size: 13px;
      line-height: 11px;
    }
  }

  @media screen and (max-width: 375px) {
    &__caption {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 360px) {
    &__title {
      font-size: 15px;
    }

    &__caption {
      font-size: 10px;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 340px) {
    &__title {
      font-size: 14px;
    }
  }
}
</style>
