import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';
import de from '@/locales/de.json';
import ae from '@/locales/ae.json';

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  availableLocales: ['en', 'de'],
  composition: true,
  allowComposition: true,
  formatFallbackMessages: true,
  messages: {
    en,
    de,
    ae,
  },
});
