import factory from '@/store/factory';

export const AlertType = {
  ALERT: 1,
  CONFIRM: 2,
  LOADING: 3,
  ERROR: 4,
};

const DEFAULT_CONFIG = {
  type: AlertType.ALERT,
  show: false,
  title: '',
  message: '',
  component: undefined,
  componentProps: {},
  resolve: () => {},
  status: undefined,
  center: false,
  small: false,
  okButton: '',
  okClick: undefined,
  cancelButton: '',
  cancelClick: undefined,
  icon: '',
  swipeable: false,
};

export default factory({

  namespaced: true,

  state: {
    config: { ...DEFAULT_CONFIG },
  },

  actions: {
    showAlert({ dispatch, commit }, conf) {
      return new Promise((resolve) => {
        const newConfig = {
          swipeable: true,
          type: AlertType.ALERT,
          show: true,
          resolve,
          ...conf,
        };

        commit('set', {
          config: newConfig,
        });
      });
    },
    showConfirm({ dispatch, commit }, conf) {
      return new Promise((resolve) => {
        const newConfig = {
          swipeable: true,
          type: AlertType.CONFIRM,
          show: true,
          resolve,
          ...conf,
        };

        commit('set', {
          config: newConfig,
        });
      });
    },
  },
});
