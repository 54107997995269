<template>
  <div class="staff-card">
    <user-avatar
      :payout-id="staff.payoutId"
      class="staff-card__avatar"
      size="medium"
      borderless
    />

    <div class="staff-card__section">
      <div class="staff-card__staff-data">
        <div class="staff-card__nickname">
          {{ computedNickName }}
        </div>

        <div class="staff-card__staff-type">
          {{ staffTypeLabel }}
        </div>
      </div>

      <ui-quote v-if="wish">
        {{ wish }}
      </ui-quote>
    </div>
  </div>
</template>

<script>
import getFio from '@/utils/getFio';
import UiQuote from '@/components/UiQuote.vue';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  components: {
    UserAvatar,
    UiQuote,
  },

  props: {
    locationPayoutId: { type: String },
    nickName: { type: String },
    wish: { type: String },
    staff: { type: Object },
  },

  computed: {
    staffTypeLabel() {
      return this.$getStaffTypeName(this.staff.staffType);
    },
    computedNickName() {
      return this.staff.nickName || getFio(this.staff);
    },
  },
};
</script>
