import { AMOUNT_CONFIRMED_STORAGE_KEY, UK_CONFIRM_AMOUNT } from '@/constants/uk-confirm-amount';
import { useStore } from 'vuex';

export function useOvertippingMixin() {
  const store = useStore();

  const resetAmountConfirm = () => {
    sessionStorage.setItem(AMOUNT_CONFIRMED_STORAGE_KEY, true);
  };

  const overtippingConfirmHandler = async ({ amountSum, submitMoney }) => {
    if (store.getters['user/isUk'] && amountSum >= UK_CONFIRM_AMOUNT) {
      const confirmShowed = sessionStorage.getItem(AMOUNT_CONFIRMED_STORAGE_KEY);

      if (!confirmShowed && !await store.dispatch('alert/showConfirm', {
        title: 'Are you sure your tip is correct?',
        message: `Please confirm you want to leave a tip of ${submitMoney}.`,
        okButton: `Confirm ${submitMoney}`,
        cancelButton: 'Enter a different amount',
        center: true,
        okClick: resetAmountConfirm,
        cancelClick: resetAmountConfirm,
      })) {
        await store.dispatch('focus/focusMoneyInput');

        return false;
      }
    }

    return true;
  };

  return {
    overtippingConfirmHandler,
  };
}
