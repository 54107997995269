<template>
  <ui-field
    v-bind="$attrs"
    :value="value"
    :focused="focused"
    :has-value="hasValue"
    @input="$emit('input', $event)"
    class="ui-input"
  >
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>

    <template
      #default="{
        modelValue,
        handleInput,
        handleChange,
        handleBlur,
        ...scope
      }"
    >
      <input
        ref="el"
        v-bind="scope"
        :value="modelValue"
        :size="inputWidth"
        class="ui-field-input"
        @input="handleUpdate($event, handleInput)"
        @change="handleChange"
        @blur="handleBlur"
      />
    </template>

    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
  </ui-field>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { useFocus } from '@vueuse/core';
import { capitalize } from 'lodash';
import { useAutoWidth } from '@/utils/auto-width';
import UiField from '@/components/UiField.vue';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  autowidth: {
    type: Boolean,
    default: false,
  },
  capitalized: {
    type: Boolean,
    default: false,
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
  inputWidth: Number,
});

const emit = defineEmits(['input']);

const el = ref();
const { focused } = useFocus(el);

// Has value.
const hasValue = computed(() => props.value.length > 0);

// Auto width.
const { autowidth } = toRefs(props);
useAutoWidth(el, autowidth);

function handleUpdate(e, input) {
  if (props.capitalized) {
    e.target.value = e.target.value.split(' ').map((word) => capitalize(word)).join(' ');
  }

  if (props.uppercase) {
    e.target.value = e.target.value.toUpperCase();
  }

  input(e);
}
</script>
