<template>
  <div class="document">
    <h1 class="title">EASYTIP TERMS AND CONDITIONS</h1>
    <span class="updated-date">
      <b>Last updated: 04.09.2024</b>
    </span>
    <p>
      Please read these terms of service (the “<b>Terms of Service</b>”) carefully as it constitutes a legally binding agreement between you and QR Tip Ltd, a limited liability company (Company Number 12646560) formed under the laws of England with its registered address at Harwood House, 43 Harwood Road, London, SW6 4QP United Kingdom (“<b>EasyTip</b>”). These Terms of Service govern access to and use of the EasyTip’s website located at <a href="https://www.easytip.net">www.easytip.net</a> and any sub-domains used in connection with or related to the same and any future versions (together the “<b>EasyTip Website</b>”) as well as EasyTip’s mobile applications (the “<b>EasyTip App</b>”) and any EasyTip proprietary software used in connection with the EasyTip Website or the EasyTip App (the "<b>EasyTip Software</b>"). Together, the EasyTip Website, the EasyTip App and the Easy Tip Software constitute the “<b>EasyTip Platform</b>”. These Terms of Service also apply to all services provided by EasyTip through the EasyTip Platform (see the definition of “<b>EasyTip Services</b>” in
      <a href="#section-5">Section [5]</a> below).
    </p>
    <p><u>NOTICE FOR NON-UK RESIDENTS:</u></p>
    <p>If you are not resident in the United Kingdom, you acknowledge and agree that:</p>
    <ul>
      <li>EasyTip operates on a cross-border basis;</li>
      <li>EasyTip is based in the UK and not your country of residence;</li>
      <li>You are contracting with a legal entity that is incorporated outside your country of residence;</li>
      <li>These Terms of Service are governed by the laws of England and Wales; and</li>
      <li>Any dispute under these Terms of Services will be resolved exclusively via arbitration in London, England.</li>
    </ul>
    <p>
      These Terms of Service apply to all Users of the EasyTip Platform, including Users who provide hospitality services to other Users, Users who make payments to Vendors and Merchants, Users who review Goods and Services through the EasyTip Platform, Users who download the EasyTip App and Users who simply view the content available through the EasyTip Website.
    </p>
    <p>
      If you are using the EasyTip Platform or creating an EasyTip Account (see <a href="#section-3">Section [3]</a> below), for or on behalf of another person or a corporate entity, you represent and warrant that you have the authority to bind such person or entity to these Terms of Service. The term “<b>User</b>”, “<b>you</b>” or “<b>your</b>” refers to the individual or legal entity, as applicable, accessing or otherwise using the EasyTip Platform or the EasyTip Services (as defined in
      <a href="#section-5">Section [5]</a> below). You acknowledge and agree that the EasyTip Platform and the EasyTip Services are made available to facilitate: (1) the payment of tips and service charges (“<b>Service Payments</b>”) in the hospitality sector; and (2) the submission of guest reviews and ratings of Vendors and Merchants (each as defined in
      <a href="#section-2">Section [2]</a> below). You understand that the EasyTip Platform and the EasyTip Services facilitate these activities and that EasyTip itself is not a Vendor or a Merchant.
    </p>
    <p>
      By accessing, browsing and using the EasyTip Platform, you confirm that you have read and accept these Terms of Service. If you do not accept these Terms of Service, then you may not access or use the EasyTip Platform or EasyTip Services.
    </p>
    <p>
      These Terms of Service are subject to change by EasyTip in its sole discretion at any time and to the fullest extent permitted by applicable law. When changes are made, EasyTip shall make an updated copy of these Terms of Service available on the EasyTip Platform. EasyTip shall also update the "Last Updated" date at the top of these Terms of Service. If we make any material changes, and you have registered with us to create an account we may also send an e-mail to you at the last e-mail address you provided to us pursuant to these Terms of Service to notify you of such changes and the date they will become effective for you. For Users not registered with us, any changes to these Terms of Service shall be effective immediately after posting notice of such changes on the EasyTip Platform. EasyTip may require you to provide your consent to the updated Terms of Service in a specified manner before further use of the EasyTip Platform and/or the EasyTip Services is permitted. If you do not agree to any change after receiving a notice of such change, you shall stop using the EasyTip Platform and/or the EasyTip Services. Otherwise, your continued use of the EasyTip Platform and/or EasyTip Services shall constitute your acceptance of such change. Please regularly check the EasyTip Platform to view the most current Terms of Service.
    </p>
    <p>
      You must read these Terms of Service in their entirety; however, this index is provided for ease of reference to enable you to navigate to specific sections of these Terms of Service.
    </p>
    <ol>
      <li><a href="#section-1">EasyTip Parties</a></li>
      <li><a href="#section-2">Users and Applicable Legal Terms</a></li>
      <li><a href="#section-3">EasyTip Accounts</a></li>
      <li><a href="#section-4">Licensed Access to the EasyTip Platform and Permitted Use</a></li>
      <li><a href="#section-5">Service Payments, EasyTip Services, EasyTip as Agent and Customer Support</a></li>
      <li><a href="#section-6">Tronc Schemes</a></li>
      <li><a href="#section-7">Tax Withholding</a></li>
      <li><a href="#section-8">Software and Merchandising</a></li>
      <li><a href="#section-9">No Unlawful or Prohibited Use</a></li>
      <li><a href="#section-10">Intellectual Property</a></li>
      <li><a href="#section-11">Advertising and Third-Party Websites</a></li>
      <li><a href="#section-12">Availability and Updates</a></li>
      <li><a href="#section-13">Disclaimers</a></li>
      <li><a href="#section-14">No Implied Warranties; Limitations of Liability</a></li>
      <li><a href="#section-15">Indemnification</a></li>
      <li><a href="#section-16">Governing Law and Dispute Resolution</a></li>
      <li><a href="#section-17">Miscellaneous</a></li>
    </ol>

    <h2 id="section-1">1. EasyTip Parties</h2>
    <p>
      These Terms of Service form an agreement between you and QR Tip Ltd, a limited liability company incorporated under the laws of England. The terms “<b>EasyTip</b>”, “<b>us</b>”, “<b>we</b>” or “<b>our</b>” means QR Tip Ltd.
    </p>
    <p>
      QR Tip Ltd and its affiliates, officers, directors and employees are referred to in these Terms of Service as the “<b>EasyTip Group</b>”. For the avoidance of doubt, the EasyTip Group does not include any third parties such as Merchants or Vendors (as such terms are defined in <a href="#section-2">Section 2</a> below).
    </p>

    <h2 id="section-2">2. Users and Applicable Legal Terms</h2>
    <p><b>Definition of Users</b>. The EasyTip Platform facilitates:</p>
    <ol>
      <li>the payment of Service Payments;</li>
      <li>
        the submission of guest reviews and ratings of personnel and locations by consumers who use the EasyTip Platform ("<b>Customers</b>") of food, drinks and other products and services (collectively, "<b>Goods and Services</b>") offered by restaurants, salons, hotels and other venues ("<b>Vendors</b>") and the services performed by service providers employed by these Vendors ("<b>Merchants</b>");
      </li>
      <li>
        User access to Customer reviews and ratings and other content on the EasyTip Platform;
      </li>
      <li>
        the implementation of schemes for the sharing or distribution of pooled Service Payments ("<b>Tronc Systems</b>") by individually appointed Merchants ("<b>Troncmasters</b>"); and
      </li>
      <li>
        access by accountants or other authorised personnel appointed by Vendors ("<b>Vendor Accountants</b>") to calculate amounts of tax to be withheld from Service Payments paid to Merchants and to arrange for the direct transfer of such amounts from a clearing account held with a Payment Processor to an account held by the Vendor.
      </li>
    </ol>

    <p>
      Collectively, Customers, Vendors, Merchants, Troncmasters, Vendor Accountants and viewers of the EasyTip Website constitute "<b>Users</b>" of the EasyTip Platform and these Terms of Service apply to all Users.
    </p>
    <p>
      <b>EasyTip Privacy Policy</b>. Please note that important information about EasyTip’s practices on the collection, use and disclosure of Users’ personal information submitted via the EasyTip Platform is governed by the EasyTip Privacy Policy, the terms of which are incorporated into this Terms of Service. To view our EasyTip Privacy Policy, please see:
      <a href="https://www.easytip.net/privacy-policy">www.easytip.net/privacypolicy</a> (the "<b>EasyTip Privacy Policy</b>").
    </p>
    <p>
      <b>Additional Terms for Vendors, Merchants, Troncmasters and Vendor Accountants</b>. In addition to these Terms of Service and the EasyTip Privacy Policy, Vendors, Merchants, Troncmasters and Vendor Accountants may be subject to additional terms agreed in separate agreements with EasyTip in respect of the Goods and Services offered by such Users or their use of the EasyTip Platform. In the event of any conflict between these Terms of Services and the separate agreement any such User has with EasyTip, the terms of the separate EasyTip agreement will prevail.
    </p>
    <p>
      <b>Additional Terms and Conditions for EasyTip App Users</b>. To the extent the other terms and conditions of these Terms of Service are less restrictive than, or otherwise conflict with, the terms and conditions of this Section, the more restrictive or conflicting terms and conditions in this Section apply, but solely with respect to the EasyTip App if downloaded from the Apple App Store or the Google Play Store.
    </p>
    <p>
      The following additional terms and conditions apply to you if you are using the EasyTip App and have downloaded it from the Apple App Store or the Google Play Store. You acknowledge and agree that:
    </p>
    <p>
      These Terms of Service are entered into between you and EasyTip. Neither Apple, Inc. ("<b>Apple</b>") nor Google, Inc. ("<b>Google</b>") (collectively, the "<b>Download Providers</b>") are parties to these Terms of Service nor shall they have obligations with respect to the EasyTip App. EasyTip, and not Apple or Google, is solely responsible for the EasyTip App, including any maintenance and support, and the content thereof as set forth hereunder. However, the Download Providers, along with their respective subsidiaries, are third-party beneficiaries of these Terms of Service. Upon your acceptance of these Terms of Service, the Download Provider used to obtain the EasyTip App shall have the right (and shall be deemed to have accepted the right) to enforce these Terms of Service against you as a third-party beneficiary. If EasyTip fails to conform to any applicable warranty, you may notify the Download Provider used to obtain the EasyTip App and they shall refund the purchase price (if any) of the EasyTip App in accordance with their policies, and, to the maximum extent permitted by applicable law, the Download Provider used to obtain the EasyTip App shall have no other warranty obligation whatsoever with respect to the application and, as between the Download Provider used to obtain the EasyTip App and EasyTip, any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to confirm to any warranty is EasyTip’s responsibility. You and us both acknowledge that EasyTip, not the Download Provider used to obtain the EasyTip App, is responsible for addressing any end-user or third-party claims related to the EasyTip App, including, but not limited to: (a) product liability claims, (b) any claim that the EasyTip App fails to conform to any applicable legal or regulatory requirement, and (c) claims arising under consumer protection or similar law.
    </p>
    <p>
      In the event of any third-party claim that the EasyTip App or your possession or use of the EasyTip App infringes on that third party’s intellectual property rights, EasyTip shall be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.
    </p>

    <h2 id="section-3">3. EasyTip Accounts</h2>
    <ul>
      <li>
        <b>Accurate Information</b>. You represent and warrant that all information supplied by you (or by anyone acting on your behalf) on the EasyTip Platform, including any information provided by you to create an account for use of the EasyTip Platform (an "<b>EasyTip Account</b>"), or in connection with your use of the EasyTip Services is true, accurate, current and complete.
      </li>
      <li>
        <b>Security of EasyTip Accounts</b>. You must keep your EasyTip Account secure and you agree that you shall not disclose your EasyTip Account credentials to anyone. No members of the EasyTip Group shall be liable for any loss or damage arising from your failure to safeguard your EasyTip Account, use a strong password or use a password distinctive to your EasyTip Account. You may browse some parts of the EasyTip Platform without creating an EasyTip Account.
      </li>
      <li>
        <b>Discretionary Access to EasyTip Accounts</b>. EasyTip reserves the right to deny your request for an EasyTip Account and to disable or terminate access to any EasyTip Account  issued to you at any time in EasyTip’s sole discretion. If EasyTip disables access to an EasyTip Account issued to you, you shall be prevented from accessing the EasyTip Platform and using the EasyTip Services and shall not have access to your EasyTip Account details and other information that is associated with your EasyTip Account.
      </li>
      <li>
        <b>Suspension, Term, Termination and Deletion of EasyTip Accounts</b>. EasyTip may suspend or terminate your ability to access the EasyTip Platform, or cease providing you with all or part of the EasyTip Services at any time for any or no reason, including, if we believe: (i) you may have violated these Terms of Service; (ii) you may create risk or possible legal exposure for us; (iii) prolonged inactivity; or (iv) our provision of the EasyTip Services to you is no longer commercially viable. Suspension or termination may also include removal of all or some of the materials uploaded by you. EasyTip may make reasonable efforts to notify you by the e-mail address associated with your EasyTip Account, through the EasyTip Platform or when you next attempt to access your EasyTip Account, depending on the circumstances. You acknowledge and agree that EasyTip may make suspensions and terminations in its sole discretion and that no member of the EasyTip Group shall be liable to you or any third-party for any suspension or termination of your access to, or for the removal of any of the materials uploaded by you to, the EasyTip Platform. Any suspension or termination of such access or removal of such materials shall be in addition to any and all other rights and remedies that EasyTip may have. In case of termination, we may disable your EasyTip Account but retain the disabled EasyTip Account and the information associated for fraud prevention or other lawful purposes. If your EasyTip Account is suspended, you will need to reactivate it to collect payments of tips. You may request reactivation by using the customer support function in the EasyTip Platform or by emailing <a href="mailto:support@easytip.net">support@easytip.net</a>.
      </li>
      <li>
        <b>Inactive Account Charges</b>. Your EasyTip Account will be deemed “inactive” if you have not, during a period of twelve (12) consecutive months: (1) received Service Payments; or (2) transferred funds from your EasyTip Account to a bank account or credit or debit card registered to your EasyTip Account. If your EasyTip Account becomes inactive, EasyTip may charge a non-refundable monthly inactivity fee equal to the lesser of: (a) £3 GBP; and (b) the balance of your EasyTip Account. If an EasyTip Account remains inactive and no longer has a positive balance, EasyTip may close the account (see “Suspension, Term, Termination and Deletion of EasyTip Accounts” above).
      </li>
      <li>
        <b>Account Deletion and Termination</b>. You may request the deletion of your EasyTip Account by: (1) requesting (by e-mail or through any then-available interface on the EasyTip Platform) that your EasyTip Account be deleted; or (2) ceasing to use the EasyTip Platform and the EasyTip Services and uninstalling and removing all local software components thereof, if any. If you request deletion of your EasyTip Account, we may disable your EasyTip Account but retain the disabled EasyTip Account for fraud prevention or other lawful purposes. You may request termination these Terms of Service at any time by requesting by e-mail (or any then-available interface on the EasyTip Platform) that your EasyTip Account be deleted. EasyTip may terminate these Terms of Service at any time by giving notice to you, at our discretion, by e-mail at your current email address on file with us or through the EasyTip Platform.
      </li>
    </ul>

    <h2 id="section-4">4. Licensed Access to the EasyTip Platform and Permitted Use</h2>
    <p>
      <b>License</b>. Subject to your compliance with these Terms of Service, EasyTip hereby grants you a personal i.e., only valid for you, revocable, non-exclusive and non-transferable license to use the EasyTip Platform in accordance with these Terms of Service in the following manner depending on the type of User that you are:
    </p>
    <ul>
      <li>
        <u>Customers</u>: you may use the EasyTip Platform to facilitate payments of Service Payments to Service Providers. You may also use the feedback features of the EasyTip Platform to post comments, ratings and reviews of Vendors and Merchants and the Goods and Services they provide;
      </li>
      <li>
        <u>Merchants</u>: Customers may use the EasyTip Platform to facilitate making Service Payments to you and you may track Service Payments made to you via the EasyTip Platform by accessing the EasyTip Account;
      </li>
      <li>
        <u>Vendors</u>: you may access the EasyTip Platform to: (1) create EasyTip Accounts for Merchants who will use the EasyTip Platform where such Merchants have consented for you to do so on their behalf; (2) request EasyTip Accounts for Troncmaster to implement a Tronc System and / or Vendor Accountants who may use the EasyTip Platform to calculate amounts of tax to be withheld from Service Charges paid to Merchants; (3) review ratings and reviews of Venues and Goods and Services posted by Customers; and (4) with EasyTip’s permission, copy and paste ratings and reviews of Venues and Goods and Services for public display, including posting on a Vendor’s website or social media accounts;
      </li>
      <li>
        <u>Troncmasters</u>: you may use the EasyTip Platform to implement and operate a Tronc System in accordance with the additional terms and conditions set out in
        <a href="#section-6">Section [6]</a> below;
      </li>
      <li>
        <u>Vendor Accountants</u>: you may use the EasyTip Platform to calculate amounts of tax to be withheld from Service Payments paid to Merchants and to arrange for the direct transfer of such amounts from a clearing account held with a Payment Processor to an account held by the Vendor (a "<b>Vendor Account</b>") for further transfer to HM Revenue & Customs.
      </li>
    </ul>
    <p>The activities described above constitute "<b>Permitted Use</b>" as applicable to a specific type of User.</p>
    <p>
      <b>Reservation of Rights</b>. EasyTip retains the right, at its sole discretion, to deny access to anyone to the EasyTip Platform or the EasyTip Services, at any time and for any reason (or no reason at all), including, but not limited to, for violation of these Terms of Service or use other than the Permitted Use. You shall cease and desist from any such access and use of the EasyTip Platform or EasyTip Services immediately upon request by EasyTip.
    </p>
    <p>
      <b>Geographic Application of the EasyTip Platform</b>. The EasyTip Platform and/or specific services may not be available at all or any times and in all or any countries. Furthermore, nothing on the EasyTip Platform constitutes an offer or solicitation to buy or sell any product or service.
    </p>
    <p>
      <b>Changes to the EasyTip Platform</b>. EasyTip reserves the right to change (or allow a third party to change) any information, material or content contained on or provided through the EasyTip Platform (the "<b>Content</b>") at any time, and from time to time, without notice.
    </p>

    <h2 id="section-5">5. Service Payments, EasyTip Services, EasyTip as Agent and Customer Support</h2>
    <p><b>EasyTip Services</b></p>
    <p>Through the EasyTip Platform, EasyTip provides Users with the following services (the “<b>EasyTip Services</b>”):</p>
    <ul>
      <li>
        The EasyTip Platform connects Customers, Vendors and Merchants with Payment Processors who may provide payment processing and gateway services for making and receiving Service Payments;
      </li>
      <li>
        EasyTip enables Vendors and Troncmasters to create EasyTip Accounts for Merchants;
      </li>
      <li>
        EasyTip also enables Vendors to create EasyTip Accounts for Venue Accountants so that they may provide for taxes to be withheld from Service Payments paid to individual Merchants;
      </li>
      <li>
        EasyTip permits Troncmasters to create schemes to allocate Service Payments amongst Merchants in accordance with the additional terms and conditions set out in <a href="#section-6">Section [6]</a> below;
      </li>
      <li>
        EasyTip may provide customer care support to Users; and
      </li>
      <li>
        EasyTip allows Users to access guest reviews and ratings of Merchants and Vendors submitted by Customers.
      </li>
    </ul>
    <p>
      EasyTip may provide products or services to Merchants or Vendors through a separate agreement executed with such parties.
    </p>
    <p>
      Any Vendor-related information on the EasyTip Platform is content provided by the Vendor and not EasyTip, and EasyTip is not liable for such content.
    </p>
    <p><b>EasyTip Account</b></p>
    <p>
      To use the EasyTip Platform and most features of the EasyTip Services, Merchants, Vendors, Troncmasters and Vendor Accountants are required to successfully create a user account (the "<b>EasyTip Account</b>") using the EasyTip Platform.
    </p>
    <p><b>Making Service Payments</b></p>
    <p>
      Using the EasyTip Platform, a Customer can scan the QR Code provided by a Vendor or Merchant to make Service Payments to Merchants via a Payment Processor. After scanning the QR Code, a Customer will be directed to a window requesting that he or she enter the amount of the desired Service Payment. Once a Customer has entered the amount, he or she will have the opportunity to review it and pay the card fees associated with the Service Payment. After a Customer reviews and confirms that the amount of the Service Payment is correct, the Customer may proceed to make the Service Payment. A Customer’s payment card will be charged when he or she makes a Service Payment.
    </p>
    <p>
      Once a Customer has made a Service Payment, the Customer shall not be entitled to change or cancel that Service Payment and shall not be entitled to a refund. If a Customer accidentally makes the same Service Payment twice, the Customer can obtain a refund for the second Service Payment by contacting EasyTip via chat, e-mail or the EasyTip support phone line provided on the EasyTip Platform.
    </p>
    <p><b>User Support</b></p>
    <p>
      EasyTip may offer support to Users in respect of issues relating to the EasyTip Services. Users with any problems, questions or suggestions with respect to the EasyTip Platform or the EasyTip Services should contact EasyTip’s customer support team (and not Merchants or Vendors) via chat (preferable), e-mail or phone at the contact information provided on the EasyTip Platform.
    </p>
    <p><b>Payment Processing Services</b></p>
    <p>
      Payment processing services for the EasyTip Platform are provided by such third-party payment processors or gateway service providers as EasyTip may choose from time to time (each such service provider, a "<b>Payment Processor</b>"). Depending on the applicable Payment Processor, you may be required to abide by any terms and conditions of such Payment Processors. You agree, understand and acknowledge that you agree to be bound by any such terms and conditions as communicated to you from time-to-time.
    </p>
    <p><b>Price and Payment</b></p>
    <p>
      Payment of Service Payments must be made by a Customer with an accepted credit or debit card issued in his or her own name through a Payment Processor (each such payment, a "<b>Card Payment</b>"). A Customer will be charged at the time of making a Service Payment. At the time of making a Service Payment, each Customer represents and warrants that it is using a credit or debit card issued in his or her own name. Each Customer consents to the collection and use of his or her information (including, if applicable, personal information) by such Payment Processors as is necessary to process Service Payments. EasyTip reserves the right to add, remove or replace any Payment Processor connected to the EasyTip Platform at any time in its sole discretion. Each Customer agrees that the Payment Processor charge his or her payment card for any Service Payment made. Each Customer is responsible for ensuring that all of his or her billing information is current, complete, and accurate.
    </p>
    <p><b>Processing Charges</b></p>
    <p>
      Each Service Payment will be subject to Platform Fees, as described below. When making a Service Payment, a Customer must choose either to:
    </p>
    <ul>
      <li>
        Pay the Platform Fees (such that no fees are deducted from the Service Payment before it is transferred to the Merchant); or
      </li>
      <li>
        Deduct the Platform Fees from the Service Payment (such that the Service Payment received by the Merchant is reduced by the amount of the fees).
      </li>
    </ul>
    <p><b>EasyTip and Payment Processor Fees</b></p>
    <p>
      The EasyTip “<b>Platform Fees</b>” are fees charged by EasyTip (“<b>EasyTip Fees</b>”) and fees charged by third party Payment Processors (“<b>Processor Fees</b>").
    </p>
    <p><b>EasyTip Fees</b></p>
    <p>
      EasyTip Fees are detailed in the “EasyTip Fees” section of the EasyTip Website available at <a href="https://www.easytip.net/">www.easytip.net</a>. EasyTip currently charges a fee of 3.67% excluding value added tax on transactions £10 or less and 3.67% plus 0.08 pence excluding value added tax on transactions £10.01 and higher.
    </p>
    <p><b>Processor Fees</b></p>
    <p>Processor Fees vary depending on a number of factors, including:</p>
    <ul>
      <li>
        the Payment Processor EasyTip uses for a given transaction; and
      </li>
      <li>
        the Customer’s card type, card issuer, issuing county, etc.
      </li>
    </ul>
    <p>
      Due to these variables, EasyTip charges Processor Fees based on an estimation of the fees that are likely to be charged by the relevant Payment Processor.  Exclusive of value added tax, these fees are currently:    </p>
    <ul>
      <li>
        a fixed fee of up to 0.10p per transaction; and
      </li>
      <li>
        a variable fee of up to 1.99% per transaction.
      </li>
    </ul>
    <p>
      EasyTip currently uses Stripe and Checkout as Payment Processors.  Their fee structures are available at:
    </p>
    <ul>
      <li><a href="https://stripe.com/gb/pricing">https://stripe.com/gb/pricing</a></li>
      <li><a href="https://www.checkout.com/pricing">https://www.checkout.com/pricing</a></li>
    </ul>
    <p>
      Each Customer agrees, understands and acknowledges that EasyTip engages Payment Processors to facilitate processing of payments, including Stripe and Checkout. Accordingly, a Customer may be required to follow any terms and conditions of such third-party payment processors/gateway service providers, as communicated to you, from time to time.
    </p>
    <p><b>Vendor Services Charges</b></p>
    <p>
      Certain Vendors may require that a certain amount or percentage (a "<b>Service Charge</b>") be deducted from Service Payments made to its Merchants. In such cases: (1) the Vendor will receive the Service Charge; and 2) the Merchant will receive the balance of the Service Payment.
    </p>
    <p><b>Currency</b></p>
    <p>
      Service Payments may be debited from a Customer’s bank/card account in a currency other than the one selected if the bank or Payment Processor requires payment in a specific currency or the currency selected by Customer is subject to a mandatory currency conversion. Any payment made in a currency other than the currency of the payment card used may result in additional fees being charged by the payment card provider or the Payment Processor.
    </p>
    <p><b>Payouts</b></p>
    <p>
      Service Payments made by a Customer will be transferred to an accepted bank account or credit or debit card registered to such Merchant’s EasyTip Account.  A bank account or payment card must normally be issued in the name of a Merchant; however, where a Merchant does not possess an accepted bank account or payment card, a Vendor may agree to accept Services Payment on such Vendor’s bank account or payment card on behalf of a Merchant
    </p>
    <p>
      Each Merchant when registering a bank account or a payment card represents and warrants that it is using a bank account or a credit or debit card issued in his or her own name or in the name of the Vendor for who such Merchant works.
    </p>
    <p>
      Where a Vendor agrees for its bank account or payment card to be used to accept Service Payments on behalf of a Merchant, the Vendor undertakes to provide the Service Payments received on such account or payment card to the relevant Merchant.
    </p>
    <p>
      A Merchant who registers a Vendor’s account or payment card to his or her EasyTip Account acknowledges and agrees that: (1) the Vendor alone is responsible for ensuring that the Merchant receives his or her Service Payments; and (2) EasyTip does not warrant or guarantee that a Vendor will transfer Service Payments to a Merchant on whose behalf they are collected.
    </p>
    <p>
      Depending on the Payment Processor EasyTip uses to process a Service Payment, Merchants or Vendors may be required to pay a fee when transferring Service Payments to their cards or bank accounts.  Further details on these fees are available at:
      <a href="https://www.easytip.net/">www.easytip.net</a>.
    </p>

    <h2 id="section-6">6. Tronc Schemes</h2>
    <p>
      The EasyTip Platform facilitates the establishment and operation of Tronc Scheme whereby Service Payments made to individual Merchants are pooled and subsequently be allocated amongst a Vendor's Merchants and other employees. In a restaurant, for example, a Tronc Scheme may be used to share Service Payments amongst Merchants and other staff, such as cooks, managers and hostesses.
    </p>
    <p>
      If operated in accordance with applicable legislation, a Tronc Scheme can allocate tips to employees without the employer being obligated to deduct certain categories of taxes and social contributions.
    </p>
    <p>
      Where the EasyTip Platform is used to manage a Tronc Scheme, Troncmasters and Vendors agree to the following additional terms and conditions:
    </p>
    <ul>
      <li>A Troncmaster may be appointed by Merchants or employees;</li>
      <li>
        No Vendor, nor any shareholder, director or officer thereof (each of the foregoing, a "<b>Vendor Representative</b>") shall act as a Troncmaster for a Tronc Scheme managed using the Easy Tip Platform;
      </li>
      <li>
        Each Troncmaster shall choose which Merchants and employees are to participate in the Tronc Scheme without any input, direction, approval or other influence from the Vendor or any other Vendor Representative;
      </li>
      <li>
        The Troncmaster shall allocate Service Payments within the Tronc Scheme without any input, direction, approval or other influence from the Vendor or any other Vendor Representative; and
      </li>
      <li>
        A Troncmaster may not permit the Vendor, any Vendor Representative or any other party to access its EasyTip Account or otherwise share details of any information or data held in such EasyTip Account with any such party.
      </li>
    </ul>
    <p>
      Upon any failure to manage a Tronc Scheme in accordance with the foregoing terms and conditions, EasyTip shall be authorised to suspend or terminate a User's access to the EasyTip Platform.
    </p>
    <p>
      Failure to manage a Tronc Scheme in accordance with the above terms and conditions may also result in a Vendor being liable for inappropriate deduction of certain taxes and social contributions from the relevant Service Payments. EasyTip shall not be responsible for any such actions, and each Vendor and Troncmaster indemnifies the EasyTip Group against any losses associated with a failure by a Vendor or Troncmaster to comply with the terms and conditions set out above.
    </p>

    <h2 id="section-7">7. Tax Withholding</h2>
    <p>
      A Vendor may create an EasyTip Account for use by a Vendor Accountant. Using such an account, a Vendor Accountant may determine the withholding taxes applicable to Service Payments. At the election of the Vendor, either: (1) the gross Service Payment is transferred to the Vendor, who deducts applicable taxes and forwards the balance of the Service Payment to the relevant Merchant; or (2) the net Service Payment is sent to the Merchant and taxes withheld are transferred to the Vendor. Any Vendor Accountant for whom an EasyTip Account is created expressly agrees to use such EasyTip Account only for the foregoing purposes.
    </p>
    <p>
      EasyTip grants Vendor Accountants access to the EasyTip Platform to facilitate the conduct of the activities described above. Where Vendor Accountants and Vendors use the EasyTip Platform for such activities, they alone are responsible for complying with all obligations to withhold taxes from Service Payments and no member of the EasyTip Group shall be responsible for any failure to comply with such obligations. Each Vendor using the EasyTip Platform to calculate withholding taxes as described above undertakes to remit all taxes withheld to the relevant tax authorities and agrees to indemnify the EasyTip Group against any losses associated with a failure to do so.
    </p>
    <p>
      For further information on tax matters, please see the disclaimers set out under the heading "Tax Obligations" in
      <a href="#section-13">Section 13</a> below.
    </p>

    <h2 id="section-8">8. Software and Merchandising</h2>
    <p>EasyTip implements the EasyTip Platform with Vendors in one of three ways:</p>
    <ul>
      <li>
        <u>(1) EasyTip Software Installation</u><br/>EasyTip may install EasyTip Software on a Vendor's server. Following such installation, the EasyTip Software may interact with Vendor’s POS systems in order to print on each invoice a QR-Code unique to such Vendor and its Merchants permitting Customers to make Service Payments as described above.  Each Vendor using POS Integration expressly agrees to the installation by EasyTip of EasyTip Software as described above and acknowledges and agrees that all intellectual property in such EasyTip Software belongs to EasyTip as described further in <a href="#section-10">Section 10</a> below.
      </li>
      <li>
        <u>(2) Freestanding Device</u><br/>EasyTip may provide to the Vendor a freestanding computer device for use on the Vendor's premises. Such a device uses EasyTip Software to send signals to the Vendor's computer system and Vendor’s POS that direct the system to include a Merchant's unique QR-code on invoices. Each Vendor who receives such a device acknowledges and agrees that: (a) such device is owned by EasyTip and provided to the Vendor only for use with the EasyTip Platform; and (b) all intellectual property in any EasyTip Software installed on such device belongs to EasyTip as described further in <a href="#section-10">Section 10</a> below.
      </li>
      <li>
        <u>(3) Merchandising Methods</u><br/>Where POS Integration or use of a freestanding computer device is not possible or practical, EasyTip may provide a Vendor with printed materials incorporating QR-codes and other information enabling use of the EasyTip Platform. Where such methods are used, each Vendor grants EasyTip a limited license to use its copyright and trademarks solely for the purposes of producing such merchandising materials.
      </li>
    </ul>

    <h2 id="section-9">9. No Unlawful or Prohibited Use</h2>
    <p>
      You may not, without EasyTip's prior written permission, use the EasyTip Platform, the EasyTip Services or any Content (including any other User’s User Content) for purposes other than the applicable Permitted Use. Without limiting the generality of the foregoing, you shall not, and shall not permit anyone else to, or attempt to use the EasyTip Platform and/or the Content to:
    </p>
    <ul>
      <li>
        "frame", "mirror" or otherwise incorporate the EasyTip Platform, the EasyTip Services or the Content or any part thereof on any commercial or non-commercial website;
      </li>
      <li>
        access, monitor or copy any part of the EasyTip Platform, the EasyTip Services or the Content using any robot, spider, scraper or other automated means or any manual process for any purpose without EasyTip’s express written permission;
      </li>
      <li>violate any laws;</li>
      <li>
        violate the restrictions in any robot exclusion headers on the Content or the EasyTip Platform or bypass or circumvent other measures employed to prevent or limit access to the EasyTip Services or the EasyTip Platform;
      </li>
      <li>
        remove (or permit anyone else to remove) any watermarks, labels or other legal or proprietary notices included in the EasyTip Platform or the Content;
      </li>
      <li>
        modify or attempt to modify the EasyTip Platform, or any Content or User Content, including any modification for the purpose of disguising or changing any indications of the ownership or that content originates from the EasyTip Platform;
      </li>
      <li>
        forge headers or otherwise manipulate identifiers in order to disguise the origin of any material transmitted to or through the EasyTip Platform or impersonate another person or organisation or misrepresent their affiliation with a person or entity;
      </li>
      <li>
        attempt to, assist, authorise or encourage others to circumvent, disable or defeat any of the security features or components, such as digital rights management software or encryption, that protect the EasyTip Platform or the EasyTip Services;
      </li>
      <li>
        copy, reproduce, modify, translate, distribute, transfer, sell, publish, broadcast, perform, transmit, license or circulate in any form any part of the EasyTip Platform or the Content;
      </li>
      <li>
        license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the EasyTip Platform;
      </li>
      <li>
        create derivative works based on the EasyTip Platform, the EasyTip Services or the Content, in whole or in part, or decompile, disassemble, reverse engineer or other exploit any part of the EasyTip Platform, the EasyTip Services or the Content;
      </li>
      <li>
        use the EasyTip Platform in a manner that violates the rights (including, but not limited to contractual, intellectual property or proprietary rights) of any third party; or
      </li>
      <li>
        upload to or transmit through the EasyTip Platform any information, images, text, data, media or other content that is offensive, harmful, tortuous, hateful, obscene, defamatory or violates any laws or is otherwise objectionable, in each case as determined by EasyTip in its sole discretion.
      </li>
    </ul>
    <p>
      You may not use the EasyTip Platform for any dealings, engagement, or services linked directly or indirectly with the following jurisdictions that EasyTip deems high risk: the countries of Cuba, Iran, North Korea, Syria, and the Crimea, Donetsk, and Luhansk regions.
    </p>
    <p>
      It is prohibited to use the EasyTip platform to collect any Service Payments or otherwise accept any payments relating to:
    </p>
    <ul>
      <li>
        illegal drugs, substances designed to mimic illegal drugs, or equipment designed for making or using illegal drugs;
      </li>
      <li>
        any product or service that is illegal in the jurisdiction where your business is located, where you are resident or where you sell products or services;
      </li>
      <li>
        pornography or other adult services such as prostitution, escort services and adult live chats;
      </li>
      <li>
        weapons and munitions;
      </li>
      <li>
        racist or anti-democratic media; or
      </li>
      <li>
        gambling, casinos and equivalent enterprises, including internet gambling, sweepstakes and contests, and fantasy sports leagues offering monetary or other material prizes.
      </li>
    </ul>

    <h2 id="section-10">10. Intellectual Property</h2>
    <p><b>Content and Intellectual Property</b></p>
    <ul>
      <li>
        <b>EasyTip Content and Intellectual Property Rights</b>. The EasyTip Platform and all content contained therein (excluding User Content as defined in paragraph (b) below) (the "<b>EasyTip Content</b>") are owned or licensed by EasyTip and protected by copyright, trademark and other intellectual property laws. EasyTip expressly reserves all rights in the EasyTip Platform and the EasyTip-Content and all materials provided by EasyTip in connection with these Terms of Service that are not specifically granted to you. You acknowledge that all right, title and interest in the EasyTip Platform, all materials provided by EasyTip in connection with these Terms of Service, including the EasyTip Content, and any update, adaptation, translation, customisation or work derivative thereof, and all intellectual property rights therein shall remain with EasyTip (or third party suppliers or licensors, if applicable), and that the EasyTip Platform and all materials provided by EasyTip hereunder, including the EasyTip Content, are licensed to you on a temporary and non-exclusive basis and not "sold" to you.
      </li>
      <li>
        <b>User Content</b>. All information, data, text, software, music, sound, photographs, graphics, video, messages or other materials, whether publicly posted or privately transmitted to the EasyTip Platform by Users ("<b>User Content</b>"), is the sole responsibility of such Users. This means that the User, and not members of the EasyTip Group, is entirely responsible for all such material uploaded, posted, emailed, transmitted or otherwise made available on the EasyTip Platform. EasyTip may monitor User Content but EasyTip does not guarantee, and does not warrant as to, the accuracy, integrity or quality of such content. Under no circumstances shall any members of the EasyTip Group be liable in any way for any User Content including for any loss or damage of any kind incurred as a result of the viewing or use of any materials posted, emailed, transmitted or otherwise made available via the EasyTip Platform. To the extent your User Content contains any personally identifiable information, that data is handled in accordance with the EasyTip Privacy Policy and all other User Content shall be considered non-confidential.
      </li>
      <li>
        <b>Cookies</b>. EasyTip uses “cookies” and similar technologies such as tags and pixels on the EasyTip Platform. Cookies and such similar technologies are small data files that websites place on your computer, laptop or mobile device.  Cookies are used by nearly all websites and do not harm your system. EasyTip’s Cookie Policy is available at
        <a href="https://www.easytip.net/privacy-policy">www.easytip.net/privacypolicy</a>. By using the EasyTip Platform, you agree to EasyTip’s use of cookies in accordance with the Cookie Policy.
      </li>
      <li>
        <b>User Content License to EasyTip</b>. By submitting, posting or displaying User Content on or through the EasyTip Platform or otherwise to EasyTip, you grant the EasyTip Group, a worldwide, non-exclusive, royalty-free, perpetual, transferable, and fully sub-licensable right to use, refrain from using, remove, reproduce, modify, edit, copy, adapt, publish, translate, create derivative works from, distribute, transmit, perform display and otherwise use User Content, in whole or in part. EasyTip may sublicense User Content You further grant EasyTip the right (although EasyTip does not have the obligation) to pursue at law any person or entity that violates your or EasyTip’s rights in the User Content by a breach of these Terms of Service. You represent and warrant that you have all the rights, power and authority necessary to grant the rights granted herein to any User Content that you submit. For the avoidance of doubt, EasyTip has no obligation to post or display any User Content on the EasyTip Platform.
      </li>
      <li>
        <b>Use of EasyTip Content and User Content by other Users</b>. You may only use EasyTip Content and other User Content (e.g., Content provided by Vendors or Customers) for the applicable Permitted Use. If you print extracts from the EasyTip Platform for your own personal, non-commercial use, you must not modify the digital or papers of such materials or use any graphics, pictures, photographs or videos separately from any accompanying text.
      </li>
    </ul>
    <p><b>Terms on Feedback/Feedback and Other User Content</b></p>
    <ul>
      <li>
        <b>Creating Feedback and Other User Content</b>. Through the capabilities of the EasyTip Platform, Customers may be able to provide ratings/reviews, suggestions or other feedback ("<b>Feedback</b>"). Feedback is a subset of User Content. In creating and posting any Feedback (or other User Content) you represent and warrant that you shall not post or transmit to or from the EasyTip Platform any material or content which does or may:
        <ul>
          <li>Breach any applicable law;</li>
          <li>Be unlawful or fraudulent;</li>
          <li>Amount to unauthorised advertising;</li>
          <li>Contain any defamatory, obscene or offensive material;</li>
          <li>Promote violence or discrimination;</li>
          <li>Infringe the intellectual property rights of another person;</li>
          <li>Breach any legal duty owed to a third party (such as a duty of confidence);</li>
          <li>Promote illegal activity or invade another’s privacy;</li>
          <li>Give the impression that they originate from us; or</li>
          <li>Be used to impersonate another person or to misrepresent your affiliation with another person.</li>
        </ul>
      </li>
    </ul>
    <p>
      You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of Feedback or other User Content to the EasyTip Platform.
    </p>
    <ul>
      <li>
        <b>Status of Feedback on the EasyTip Platform</b>. We reserve the right to remove or edit at any time any Feedback or other User Content posted, uploaded or transmitted to the EasyTip Platform that we determine breaches the restrictions in
        <a href="#section-7">Section 7</a> above or is otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for any reason. Any Feedback on the EasyTip Platform is for information purposes only and does not constitute advice from us or the opinions of anyone within the EasyTip Group. User Content may reflect the opinions of Customers who have used the EasyTip Platform and any statements, advice or opinions provided by such persons are theirs only. Accordingly, no members of the EasyTip Group assume any responsibility or liability to any person for any User Content, including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may encounter in any such materials.
      </li>
      <li>
        <b>Public Display of Feedback</b>. You acknowledge that EasyTip may share Feedback with Vendors and Merchants at EasyTip’s discretion. EasyTip may also permit Vendors and Merchants to publicly display Feedback, including by posting it on their websites or social media accounts. By providing ratings and reviews, you consent to our sharing of such Feedback with Vendors and Merchants and to Vendors and Merchants publicizing this Feedback. All ratings/reviews that we receive may be moderated before we share them. We may do this to make sure that ratings/reviews fit our content guidelines.
      </li>
      <li>
        <b>Promotional Materials</b>. Each Vendor acknowledges that EasyTip may publicise such Vendor's use of the EasyTip Platform, including on the EasyTip Websites and on EasyTip's account on social media platforms.
      </li>
      <li>
        <b>Note on Privacy</b>. If you access any Personal Information through or in connection with the EasyTip Platform you agree that you shall treat such Personal Information in accordance with the current EasyTip Privacy Policy and you shall not collect or harvest any Personal Information, including Personal Information of other users, except as permitted by these Terms of Service.
      </li>
    </ul>

    <h2 id="section-11">11. Advertising and Third-Party Websites</h2>
    <p>
      If you elect to have any business dealings with anyone whose products or services may be referenced or advertised on the EasyTip Platform, you acknowledge and agree that such dealings are solely between you and such advertiser and you further acknowledge and agree that no member of the EasyTip Group shall have any responsibility or liability for any losses or damages that you may incur as a result of any such dealings.
    </p>
    <p>
      The EasyTip Platform may provide links to third party websites and such links are provided solely for your convenience. If you use these links, you leave the EasyTip Platform. We have not reviewed and do not control any of these third-party websites (and are not responsible for these websites or their content or availability). EasyTip does not endorse these sites, their content, or the results of the use of such sites or content, and EasyTip is not liable for their content, functions, or any malfunctions caused by, or malicious code emanating from, these sites. If you decide to access any of the third-party websites linked to the EasyTip Platform, you do so entirely at your own risk.
    </p>
    <p>
      EasyTip reserves the right to prohibit or require you to remove any link to the EasyTip Platform, including, without limitation, any link which contains or makes available any content or information of the foregoing nature, at any time.
    </p>

    <h2 id="section-12">12. Availability and Updates</h2>
    <ul>
      <li>
        EasyTip may alter, suspend, or discontinue the EasyTip Platform or Service at any time and for any reason or no reason, without notice. The EasyTip Platform or Service may be unavailable from time to time due to maintenance or malfunction of computer or network equipment or other reasons. EasyTip may periodically add or update the EasyTip-Content and any other materials on this the EasyTip Platform without notice.
      </li>
      <li>
        While we try to ensure the EasyTip Platform is normally available twenty-four (24) hours a day, we do not undertake any obligation to do so, and no members of the EasyTip Group shall be liable to you if the EasyTip Platform is unavailable at any time or for any period.
      </li>
      <li>
        EasyTip may upgrade or alter the EasyTip Platform (including the EasyTip App) at any time.
      </li>
    </ul>

    <h2 id="section-13">13. Disclaimers</h2>
    <p>
      <b>Content</b>: While we try to ensure that information on the EasyTip Platform is correct, we do not promise it is accurate or complete. EasyTip may make changes to the EasyTip-Content and any other material on the EasyTip Platform, at any time without notice. The EasyTip-Content and any other material on the EasyTip Platform may be out of date, and we make no commitment to update that material.
    </p>
    <p>
      <b>Merchant actions and omissions</b>: The legal contract for the supply and purchase of Goods and Services is between the Customer and the Merchant. EasyTip has no control over the actions or omissions of any Merchant or Vendors and is not liable for any act or omission or otherwise for the performance of Merchants and Vendors.
    </p>
    <p>
      The foregoing disclaimers do not affect Customer’s statutory rights against any Merchant or Vendor.
    </p>
    <p><b>Service Disclaimers</b></p>
    <ul>
      <li>
        <b>Viruses</b>. The downloading and viewing of the EasyTip Platform or the EasyTip-Content and any other material is done at your own risk. EasyTip cannot and does not guarantee or warrant that the EasyTip Platform or the EasyTip Content and any other material, which can be viewed via the EasyTip Platform, are compatible with your computer system or your mobile device or any associated operating systems or software. You are responsible for implementing safeguards to protect the security and integrity of your computer system, and you are responsible for the entire cost of any service, repairs or connections of and to your computer system that may be necessary as a result of your use of the EasyTip Platform (including the EasyTip App and the EasyTip Content).
      </li>
      <li>
        <b>Communications Not Confidential</b>. EasyTip does not guarantee the confidentiality of any communications made by you through the EasyTip Platform. Although EasyTip generally adheres to the accepted industry practices in securing the transmission of data to, from and through the EasyTip Platform, you understand, agree and acknowledge that EasyTip cannot and does not guarantee the security of data transmitted over the Internet or public networks in connection with your use of the EasyTip Platform.
      </li>
      <li>
        <b>Tax Obligations</b>. EasyTip makes no representation as to the treatment for tax purposes of any Service Payment made or received via the EasyTip Platform. Each Merchant that is self-employed (i.e., not an employee of the Vendor) agrees that: (1) he or she alone is responsible for reporting to the relevant tax authorities any income he or she receives from Services Payments; and (2) where authorised to do so by a Vendor, EasyTip has the right to deduct withholding from such Merchant’s Service Payments and remit the amount deducted to the Vendor. Each Vendor undertakes that it alone is responsible for any required reporting and deductions in respect of tax in relation to all Merchants that it employs and undertakes to promptly remit to the relevant tax authority any amounts so deducted. EasyTip does not undertake to conduct any such reporting on behalf of any Vendors or Merchants; provided, however, that EasyTip may, when required by applicable law, report certain information regarding Service Payments made and received via the EasyTip Platform to tax authorities and other regulators and authorities. For tax purposes, applicable law may require EasyTip to keep basic information about our Users (including contact and identity details and financial and transaction data) for up to six (6) years after they stop being Users.
      </li>
    </ul>

    <h2 id="section-14">14. No Implied Warranties; Limitations of Liability.</h2>
    <ul>
      <li>
        <b>No Implied Warranties</b>. The EasyTip Services, the EasyTip Platform and the Content are provided "as is" without warranty or condition of any kind. EasyTip disclaims all warranties, representations and conditions of any kind with respect to the EasyTip Services, EasyTip Platform and the Content whether express, implied, statutory or collateral, including, without limitation, the implied warranties and conditions of merchantability, fitness for a particular purpose and non-infringement or that the EasyTip Services, the EasyTip Platform or the content are or shall be error-free or shall operate without interruption.
      </li>
      <li>
        <b>Exclusion of Indirect Damages</b>. In no event shall EasyTip or any member of the EasyTip Group be liable, whether based on warranty, contract, tort, negligence, strict liability or any other legal theory, for any damages of any kind (including, without limitation, indirect, incidental, consequential, special, exemplary or punitive damages, lost profits, loss of revenue, loss of use, loss of data, personal injury, fines, fees, penalties or other liabilities), whether or not EasyTip is advised of the possibility of such damages, resulting from or related to the use of, or the inability to make use of, the EasyTip Services, the EasyTip Platform or the EasyTip Content.
      </li>
      <li>
        <b>No Responsibility for Customers, Merchants and Vendors</b>. Customers, Merchants and Vendors using the EasyTip Platform are independent persons or organisations and not representatives, agents or employees of EasyTip. EasyTip is therefore not liable for the acts, errors, omissions, representations, warranties, contractual breaches or negligence of any Customer, Merchant or Vendor or for any personal injury, death, property damage, or other damages or expenses resulting therefrom and takes no responsibility whatsoever for the Goods and Services offered by Customers, Merchants or Vendors.
      </li>
      <li>
        <b>Limitation of Liability</b>. To the extent that the foregoing limitations do not apply, in no event shall the total aggregate liability of the EasyTip Group to any User in connection with or under these terms of service, including in connection with your use of, or inability to make use of, the EasyTip Services, the EasyTip Platform or the content exceed GBP 100 (one hundred pounds). For greater certainty, the existence of one or more claims under these terms of service shall not increase the maximum liability amount.
      </li>
      <li>
        <b>Additional Costs</b>: You assume full and sole responsibility for any additional and/or associated costs that you may incur in connection with or as a result of your use of the EasyTip Platform, including, without limitation, costs relating to the servicing, repair or adaptation of any equipment, software or data that you may own, lease, license or otherwise use.
      </li>
    </ul>
    <p>
      The limitations above reflect a fair allocation of risk but for which EasyTip would not make the EasyTip Platform and/or EasyTip Services available to users. The limitations specified in this section shall survive and apply even if any limited remedy specified in these terms is found to have failed of its essential purpose or is found invalid by any competent court.
    </p>

    <h2 id="section-15">15. Indemnification</h2>
    <p>
      You agree to defend, indemnify and hold harmless the EasyTip Group, its licensors and their directors, officers, agents, contractors, partners, representatives and employees from and against any threatened or actual claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal fees, incurred in connection with or as a result of your:
    </p>
    <ul>
      <li>breach of these Terms of Service or any documents referenced herein;</li>
      <li>violation of any law or the rights of a third party (including, without limitation, intellectual property rights); or</li>
      <li>
        breach of or failure to perform in respect of any transactions made by you or by any third party acting on your behalf or with your permission.
      </li>
    </ul>
    <p>
      EasyTip reserves the right, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you hereunder, and you shall cooperate as fully as reasonably required by EasyTip.
    </p>

    <h2 id="section-16">16. Governing Law and Dispute Resolution</h2>
    <ul>
      <li>
        <b>Governing Law</b>. These Terms of Service shall be governed by the laws of England and Wales. These laws apply to your access to or use of the, the EasyTip Platform, the EasyTip Services and the Content, notwithstanding your domicile, residency or physical location. The EasyTip Platform, the EasyTip Services and the Content are intended for use only in jurisdictions where they may lawfully be offered for use.
      </li>
      <li>
        <b>Dispute Resolution Process.</b>
        <ul>
          <li>
            Except to the extent restricted by applicable law, if there is any dispute or controversy between (1) you and (2) EasyTip or any members of the EasyTip Group, including any dispute or controversy arising out of or relating to these Terms of Service, the EasyTip Platform or EasyTip Services, any interactions or transactions between (1) you and (2) EasyTip or any members of the EasyTip Group, or in respect of any legal relationship associated with or derived from these Terms of Service, including the validity, existence, breach, termination, construction or application, or the rights, duties or obligations of you or us, (each, a "<b>Dispute</b>"), the disputing person shall serve notice on the other person and each you and us must use good faith efforts to resolve the Dispute informally, outside courts. You are free to take legal action at any time before the competent courts.
          </li>
          <li>
            If a Dispute is not resolved after twenty (20) business days of a person serving notice on the other party that there is a Dispute, you and we agree that the Dispute shall be finally resolved by confidential arbitration in accordance with the UNCITRAL Arbitration Rules as at present in force. There shall be one arbitrator and the appointing authority shall be LCIA (London Court of International Arbitration). The seat and place of arbitration shall be London, England and the English language shall be used throughout the arbitral proceedings. The parties hereby waive any rights under the Arbitration Act 1996 or otherwise to appeal any arbitration award to, or to seek determination of a preliminary point of law by, the courts of England. EasyTip shall pay the reasonable arbitration costs.
          </li>
          <li>
            You and we agree that you and we shall resolve any Dispute on an individual basis. Any claim you may have must be brought individually, in your individual capacity and not as a representative plaintiff or class member, and you shall not join such claim with claims of any other person or entity, or bring, join or participate in a class action lawsuit, collective or representative proceeding of any kind (existing or future) against any members of the EasyTip Group.
          </li>
          <li>
            Nothing in this Section shall prohibit us from seeking interim measures from a court, including preliminary or injunctive relief of breach of you of any intellectual property rights.
          </li>
        </ul>
      </li>
    </ul>

    <h2 id="section-17">17. Miscellaneous</h2>
    <ul>
      <li>
        <b>Interpretation</b>. Unless the context clearly requires otherwise: (1) references to the plural include the singular, the singular the plural, the part the whole; (2) references to any gender include all genders; (3) “including” has the inclusive meaning frequently identified with the phrase “but not limited to”; and (4) references to “hereunder” or “herein” relate to these Terms of Service. The section headings in these Terms of Service are for reference and convenience only and shall not be considered in the interpretation of these Terms of Service.
      </li>
      <li>
        <b>Entire Agreement</b>. These Terms of Service, the EasyTip Privacy Policy and any documents incorporated by reference herein, together with any additional written agreements entered into between you and EasyTip, constitute the entire agreement between EasyTip and you pertaining to the subject matter hereof and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and EasyTip with respect to the subject matter of these Terms of Service.
      </li>
      <li>
        <b>Notices</b>. EasyTip may, in its sole discretion, provide any notices to you in connection with these Terms of Service through the EasyTip Platform or by email at the then-current email address for you on file with EasyTip. EasyTip may be contacted in writing at:
      </li>
      <li>QR Tip Ltd <br/>Harwood House<br/>43 Harwood Road<br/>London<br>SW6 4QP<br/><br/></li>
      <li>Email address: <a href="mailto:info@easytip.net">info@easytip.net</a></li>
    </ul>
    <p>EasyTip may change its notice contact information from time to time by posting updated contact details on the EasyTip Platform.</p>
    <ul>
      <li>
        <b>No Waiver</b>. EasyTip's failure to insist upon or enforce strict performance of any provision of these Terms of Service shall not be construed as a waiver of any provisions or right.
      </li>
      <li>
        <b>Force Majeure</b>. EasyTip shall not be liable to you for any failure of or delay in the performance of its obligations under these Terms of Service for the period that such failure or delay is due to causes beyond EasyTip's reasonable control, including but not limited to acts of God, power outages, internet non-connectivity, war, strikes or labour disputes, embargoes, government orders, pandemics or any other force majeure event.
      </li>
      <li>
        <b>Severability</b>. If any of the provisions contained in these Terms of Service are determined to be void, invalid or otherwise unenforceable by a court of competent jurisdiction, the provision shall be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of these Terms of Service shall remain in effect.
      </li>
      <li>
        <b>Order of Precedence</b>. If any of the provisions contained in these Terms of Service conflict with the terms of another agreement between you and us, then these Terms of Service shall prevail; provided, however, that for Vendors registered on the EasyTip Platform, the terms applicable to any separate agreement entered into between EasyTip and any Vendor shall govern such Vendor’s relationship with EasyTip in connection with such Vendor’s use of the EasyTip Platform.
      </li>
      <li>
        <b>Assignment</b>. You may not transfer any of your rights or obligations under these Terms of Service without our prior written consent. We may transfer any of our rights or obligations under these Terms of Service without your prior written consent to any of our affiliates or any business that we enter into a joint venture with, purchase or are sold to.
      </li>
    </ul>
  </div>
</template>
