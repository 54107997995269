<template>
  <div
    class="ui-input-phone"
    :class="{ 'ui-input-phone--disabled': disabled }"
  >
    <label v-if="label" class="ui-input-phone__label">
      {{ label }}
      <span v-if="required" class="ui-input-phone__required">*</span>
    </label>

    <div class="ui-input-phone__body">
      <vue-tel-input
        :model-value="inputValue"
        :all-countries="phoneCountries"
        :auto-default-country="false"
        :default-country="phoneIso"
        :key="`phone-${phoneIso}`"
        :label="phoneLoading ? '' : label"
        :error="error"
        :required="required"
        :is-loading="phoneLoading"
        :is-filled="inputValue && !!inputValue.length"
        v-mask="phoneMask"
        autocomplete="off"
        enabled-country-code
        disabled-fetching-country
        :dropdown-options="{
          showDialCodeInSelection: true,
          showFlags: true,
        }"
        :inputOptions="{showDialCode: false}"
        mode="national"
        placeholder=""
        :disabled="disabled"
        :custom-validate="/.*/"
        @country-changed="countryChanged"
        @update:modelValue="input"
      />
    </div>

    <div v-if="error" class="ui-input-phone__error">
      {{ error }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import { COUNTRY_CONFIG } from '@/constants/countries';
import findCountry from '@/utils/findCountry';
import formatPhone from '@/utils/formatPhone';
import placeholders from '@/config/placeholders';
import calling from '@/config/calling';

const props = defineProps({
  value: String,
  error: {
    type: String,
    default: '',
  },
  label: String,
  placeholder: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: Boolean,
  countryCode: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['input']);

const inputValue = ref('');
const parsing = ref(true);
const phoneIso = ref('');

// Computed
const phoneCountries = COUNTRY_CONFIG.map(({ iso, ...config }) => {
  return {
    ...config,
    iso: iso.toUpperCase(),
  };
}).filter(({ iso }) => {
  return iso in placeholders;
}).map(({ iso, ...config }) => {
  return {
    ...config,
    iso2: iso,
    dialCode: findDialCode(iso),
  };
});

const phoneLoading = computed(() => props.isLoading || parsing.value);
const phonePlaceholders = computed(() => {
  const list = placeholders[phoneIso.value] || '';

  return Array.isArray(list) ? list : [list];
});
const phonePrefix = computed(() => phoneCountries.find(({ iso2 }) => iso2 === phoneIso.value)?.dialCode);
const phoneMask = computed(() => {
  const masks = phonePlaceholders.value.map((value) => {
    return value.replace(/\d/g, '0');
  });

  return masks[0];
});
const phoneFull = computed(() => {
  if (!phonePrefix.value || !inputValue.value) {
    return inputValue.value || '';
  }

  return `+${phonePrefix.value}${formatPhone(inputValue.value)}`;
});
const phoneInvalid = computed(() => {
  return phoneMask.value.length !== inputValue.value.length;
});
const phoneModel = computed(() => {
  return phoneInvalid.value ? '' : phoneFull.value;
});

/**
 * Find dial code from stripe by country iso code.
 *
 * @param  {string} iso
 * @return {string}
 */
function findDialCode(iso) {
  return Object.keys(calling).find((code) => calling[code].includes(iso));
}

/**
 * Find iso code by phone.
 *
 * @param  {string} phone
 * @return {string}
 */
function findIso(phone) {
  phone = formatPhone(phone);

  return phoneCountries.find(({ dialCode }) => {
    return dialCode && phone.indexOf(dialCode) === 0;
  })?.iso2;
}

function countryChanged(value) {
  phoneIso.value = value.iso2;
}

function input(value) {
  inputValue.value = value;
  emit('input', phoneModel.value);
}

watch(() => props.value, async (value) => {
  if (props.isLoading) {
    return;
  }

  if (value === phoneModel.value && inputValue.value.length) {
    return;
  }

  if (value) {
    let phoneIsoCode = findIso(value);

    if (!phoneIsoCode) {
      phoneIsoCode = await findCountry();
    }

    if (formatPhone(value) !== formatPhone(phoneFull.value)) {
      phoneIso.value = phoneIsoCode;
      inputValue.value = `${value}`.replace(new RegExp(`^\\+?${phonePrefix.value}`, 'g'), '');
    }
  } else {
    inputValue.value = '';

    if (!phoneIso.value) {
      phoneIso.value = props.countryCode ? props.countryCode : await findCountry();
    }
  }

  parsing.value = false;
}, { immediate: true });
</script>
