export default {
  amount: {
    default: [
      2,
      5,
      8,
      10,
    ],
  },
  percents: {
    default: [
      10,
      12.5,
      15,
      18,
    ],
    us: [
      15,
      20,
      25,
      30,
    ],
  },
};
