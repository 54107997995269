import Vue from 'vue';
import VueRouter, { createRouter, createWebHistory } from 'vue-router';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import routes from './routes';

Vue.use(VueRouter);

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  return next();
});

router.afterEach(async () => {
  // await router.app.$nextTick();

  NProgress.done();
});

export default router;
